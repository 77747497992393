import { OwcIcon, OwcIconButton, OwcInput, OwcTooltip } from "@one/react";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import {
  DEFAULT_MYLAB_FILTER,
  SELECT_PREDEFINED_FILTER_NAME
} from "../../constants";
import styles from "./EquipmentFilterBar.module.scss";
import { EquipmentsContext } from "./equipments-context/context";

import MyLabFilterDropDown from "./MyLabFilterDropDown";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import FilterOutlinedGrey from "../../icons/FilterOutlinedGrey";
import FilterOutlinedActive from "../../icons/FilterOutlinedActive";

export const EquipAddedStyle = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const CountHighlight = styled.div`
  font-size: 12px;
  font-style: normal;
  line-height: 23px;
  letter-spacing: 0px;
  background-color: var(--one-color-cobas-green-400);
  color: var(--one-color-cobas-white);
  border-radius: 50%;
  width: 22px;
  display: flex;
  justify-content: center;
  margin-left: 10px;
`;

const EquipmentFilterBar = ({
  searchText,
  setSearchText,
  isEditMode,
  gridRef,
  defaultColDef,
  setDefaultColDef,
  isFilterActive,
  setIsFilterActive
}) => {
  const [activeToolBar, setActiveToolBar] = useState(
    gridRef?.current?.api?.isSideBarVisible()
  );
  const [refresh, setRefresh] = useState(true);
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);

  const { myLabFiltersList, setAppliedFilter, appliedFilter, setEdit } =
    useContext(EquipmentsContext);
  let editList = myLabFiltersList.map((x) => x.filterName);
  let viewList = myLabFiltersList
    .filter((x) => x.filterName !== DEFAULT_MYLAB_FILTER.filterName)
    .map((x) => x.filterName);
  useEffect(() => {
    if (isEditMode) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [isEditMode, setEdit]);
  useEffect(() => {
    setRefresh(false);
    setTimeout(() => {
      setRefresh(true);
    });
  }, [appliedFilter]);
  return (
    <div className={styles.equipmentpageFilterButton}>
      <div className={styles.equipmentpageFilterSection}>
        <OwcInput
          autoFocus
          placeholder={"Search"}
          onInputChange={(ev) => setSearchText(ev.detail)}
          style={{ marginRight: 8 }}
          value={searchText}
        >
          <div slot="prefix">
            <OwcIcon name="search" />
          </div>
          <div
            slot="suffix"
            style={{
              color: "var(--one-color-interaction-default-neutral-3)"
            }}
          >
            {searchText && (
              <OwcIcon
                type="filled"
                name="circle_clear"
                onClick={() => setSearchText(null)}
                style={{
                  cursor: "pointer"
                }}
              />
            )}
          </div>
        </OwcInput>
        <OwcIconButton
          id="advance-filter"
          data-testid="advance-filter-button"
          icon=" parameter_set"
          type="filled"
          onClick={() => {
            setShowAdvanceFilter(!showAdvanceFilter);
            defaultColDef.floatingFilter = !defaultColDef.floatingFilter;
            setDefaultColDef({ ...defaultColDef });
          }}
          flat
        />
        {/* <OwcBadge
        style={{
          display: counter === 0 ? "none" : "",
          width: "24px",
          height: "24px"
        }}
        type={"active"}
      >
        {counter}
      </OwcBadge> */}
        <OwcTooltip anchor="advance-filter" placement="top">
          Show search and filters options
        </OwcTooltip>

        <div style={{ display: showAdvanceFilter ? "flex" : "none" }}>
          <div style={{ height: "50px", marginLeft: "10px" }}>
            {refresh && (
              <MyLabFilterDropDown
                labelValue={false}
                label={SELECT_PREDEFINED_FILTER_NAME}
                style={{ marginRight: 8, width: 280 }}
                list={isEditMode ? editList : viewList}
                disabled={
                  isEditMode
                    ? false
                    : !(Array.isArray(viewList) && viewList.length)
                    ? true
                    : false
                }
                selected={
                  isEditMode
                    ? appliedFilter?.filterName
                    : appliedFilter?.filterName !==
                      DEFAULT_MYLAB_FILTER.filterName
                    ? appliedFilter?.filterName
                    : null
                }
                onChange={(selectedValue) => {
                  const filterValue = isEditMode
                    ? editList.find((x, index) => index === selectedValue)
                    : viewList.find((x, index) => index === selectedValue);

                  const userSelectedFilter = myLabFiltersList.find(
                    (x) => x.filterName === filterValue
                  );
                  if (userSelectedFilter !== undefined) {
                    setAppliedFilter(userSelectedFilter);
                  } else {
                    let defaultFilter = { ...DEFAULT_MYLAB_FILTER };
                    defaultFilter.filterName = null;
                    setAppliedFilter(defaultFilter);
                  }
                }}
              />
            )}
          </div>
          <OwcTooltip anchor="filter-list" placement="top">
            Show filters setup panel
          </OwcTooltip>

          <IconButton
            id="filter-list"
            onClick={() => {
              if (!activeToolBar) {
                gridRef.current.api.setSideBarVisible(true);
                gridRef.current.api.openToolPanel("saveConfigutation");
                setIsFilterActive(() => true);
              } else {
                gridRef.current.api.setSideBarVisible(false);
                setIsFilterActive(() => !isFilterActive);
              }

              setActiveToolBar(!activeToolBar);
            }}
          >
            {!isFilterActive && (
              <>
                <FilterOutlinedGrey />
              </>
            )}
            {isFilterActive && (
              <>
                <FilterOutlinedActive />
              </>
            )}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default EquipmentFilterBar;
