import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { connect, useSelector } from "react-redux";
import { compose, withApollo } from "react-apollo";
import { CloudSearchConfig } from "../../components/shared/CloudSearchConfig";
import { LIST_IR_FILTER_LOOKUPS_MASTER } from "../../gql/landingapi/queries";
import { getAllData, getFilterModel } from "../../utils/helpers/fetching";
import {
  OwcButton,
  OwcCard,
  OwcDivider,
  OwcIcon,
  OwcIconButton,
  OwcTypography
} from "@one/react";
import {
  DEFAULT_MYLAB_FILTER,
  EMPTY_FILTER_LIST,
  entryType,
  equipmentStatusFilterList,
  listOfFiltersRequired,
  POSITION_IN_CLUSTER,
  SERIAL_NUMBER
} from "../../constants";
import EQUIP_DATA_MODEL_TABLE from "../../utils/constants/equipmentDataModelTable";
import DLabGrid from "../../components/DLabGrid/DLabGrid";
import EquipmentTitleBar from "./EquipmentTitle";
import EquipmentFilterBar from "./EquipmentFilterBar";
import { EquipmentsContext } from "./equipments-context/context";
import ConfirmDialog from "../../components/shared/ConfirmDialog";
import useDialog from "../../utils/hooks/useDialog";
import {
  CREATE_DIGITAL_LAB_LANDING_PAGE_USER_FILTER,
  DELETE_DIGITAL_LAB_LANDING_PAGE_USER_FILTER,
  UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_FILTER
} from "../../gql/landingapi/mutations";
import MyEquipmentMenu from "./MyEquipmentMenu";
import Snackbar from "../snackbar/Snackbar";
import {
  convertToCameCase,
  convertToSnakeCase,
  getUniqValuesWithCaseInsensitive,
  onlyUnique,
  uniqList
} from "../../utils/helpers/text";
import MyLabSideBarTool from "./MyLabSideBarTool";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../constants";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import EmptyMyLab from "../../icons/EmptyMyLab";
import { getMegaClusterIds } from "../../components/shared/helpers";
import { isEqual, uniq } from "lodash";
import EquipmentStatusRenderer from "./EquipmentStatusRenderer";

const EquipmentPage = ({ userID, client, subEquipmentInfoAction }) => {
  const {
    myLabEquipment,
    myLabFiltersList,
    appliedFilter,
    setMyLabEquipment,
    fetchingMyLabEquipment,
    setMyLabFiltersList,
    setAppliedFilter,
    myLabCountInitial
  } = useContext(EquipmentsContext);
  const icons = useMemo(() => {
    return {
      "setting-icon": '<owc-icon name="settings" type="legacy" />'
    };
  }, []);

  const activeSiteName = useSelector((state) => state.user.site);
  let filterCount = useRef(0);
  const [isSaveActive, setIsSaveActive] = useState(false);
  const [myLabCount, setMyLabCount] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const { openDialog, ...dialogProps } = useDialog();
  const [defaultColDef, setDefaultColDef] = useState({
    flex: 1,
    minWidth: 160,
    floatingFilter: true
  });
  const searchInColumn = EQUIP_DATA_MODEL_TABLE;
  const gridRef = useRef();
  const allMegaclusterIds = useRef([]);
  //Ag-Grid function
  const [gridApi, setGridApi] = useState(null);
  const [totalRows, setTotalRows] = useState("0");
  const [rowPerPage, setRowPerPage] = useState(10);
  const [showMenu, setShowMenu] = useState(false);
  const [currentParams, setCurrentParams] = useState(null);
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [sortObj, setSortObj] = useState({ colId: null, sort: null });
  useEffect(() => {
    setIsSaveDisabled(() =>
      isEqual(selectedEquipment.sort(), myLabEquipment.sort())
    );
  }, [selectedEquipment, myLabEquipment]);

  const agGridOptions = {
    showText: isEditMode,
    componentsAdded: selectedEquipment?.length
  };

  const actionRender = (params) => {
    return (
      params?.data?.entry_type[0] !== entryType.clusterSubequipment && (
        <OwcIconButton
          flat
          type="outlined"
          icon="more_vertical"
          onClick={() => {
            setCurrentParams(params);
            setShowMenu(true);
          }}
          id={params?.data?.id[0]}
        ></OwcIconButton>
      )
    );
  };

  const [masterDataFilters, setMasterDataFilters] = useState(EMPTY_FILTER_LIST);
  useEffect(() => {
    const getAllDropDownList = async () => {
      const REPO_config = {
        aws_appsync_graphqlEndpoint:
          process.env.REACT_APP_AWS_GRAPHQL_REPO_ENDPOINT
      };

      const belongingToGroupResponse = await getAllData({
        client,
        query: LIST_IR_FILTER_LOOKUPS_MASTER,
        dataPath: ["data", "listIRFilterLookups"],
        variables: {
          limit: 1000,
          field: DATA_MODEL_TABLE?.belongingToGroup?.key
        },
        drillData: true,
        awsAppSyncConfig: REPO_config
      });

      const belongingToGroup = uniqList(
        belongingToGroupResponse.items,
        "filterValue"
      );

      const equipmentModelResponse = await getAllData({
        client,
        query: LIST_IR_FILTER_LOOKUPS_MASTER,
        dataPath: ["data", "listIRFilterLookups"],
        variables: {
          limit: 1000,
          field: DATA_MODEL_TABLE?.equipmentModel?.key
        },
        drillData: true,
        awsAppSyncConfig: REPO_config
      });

      const equipmentModel = uniqList(
        equipmentModelResponse.items,
        "filterValue"
      );

      const equipmentNickNameResponse = await getAllData({
        client,
        query: LIST_IR_FILTER_LOOKUPS_MASTER,
        dataPath: ["data", "listIRFilterLookups"],
        variables: {
          limit: 1000,
          field: DATA_MODEL_TABLE?.equipmentNickName?.key
        },
        drillData: true,
        awsAppSyncConfig: REPO_config
      });

      const equipmentNickName = uniqList(
        equipmentNickNameResponse.items,
        "filterValue"
      );

      const locationResponse = await getAllData({
        client,
        query: LIST_IR_FILTER_LOOKUPS_MASTER,
        dataPath: ["data", "listIRFilterLookups"],
        variables: { limit: 1000, field: DATA_MODEL_TABLE?.location?.key },
        drillData: true,
        awsAppSyncConfig: REPO_config
      });

      const location = uniqList(locationResponse.items, "filterValue");
      const tempArray = {
        belongingToGroup: getUniqValuesWithCaseInsensitive(
          belongingToGroup?.map((item) => item.filterValue)
        ),
        equipmentNickName: getUniqValuesWithCaseInsensitive(
          equipmentNickName?.map((item) => item.filterValue)
        ),
        equipmentModel: getUniqValuesWithCaseInsensitive(
          equipmentModel?.map((item) => item.filterValue)
        ),
        location: getUniqValuesWithCaseInsensitive(
          location?.map((item) => item.filterValue)
        ),
        status: equipmentStatusFilterList
      };
      setMasterDataFilters(tempArray);

      // To get all megacluster ids
      const ids = await getMegaClusterIds();
      allMegaclusterIds.current = uniq(ids);
    };
    getAllDropDownList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedEquipment(() => {
      return [...myLabEquipment];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myLabEquipment]);

  useEffect(() => {
    if (gridApi) {
      onGridReady(gridApi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rowPerPage,
    searchText,
    myLabEquipment,
    isEditMode,
    appliedFilter,
    sortObj
  ]);
  useEffect(() => {
    if (appliedFilter) {
      let filterDefinition = appliedFilter.filterDefinition;
      if (typeof filterDefinition === "string") {
        filterDefinition = JSON.parse(filterDefinition);
      }
      let obj = {};
      for (const key in listOfFiltersRequired) {
        // convertToSnakeCase(filterDefinition[key].key)
        if (
          filterDefinition[listOfFiltersRequired[key]] !== undefined &&
          filterDefinition[listOfFiltersRequired[key]] !== "null" &&
          filterDefinition[listOfFiltersRequired[key]]
        ) {
          if (
            typeof filterDefinition[listOfFiltersRequired[key]] === "string"
          ) {
            obj[convertToSnakeCase(listOfFiltersRequired[key])] = {
              values: [filterDefinition[listOfFiltersRequired[key]]],
              filterType: "set"
            };
          } else {
            obj[convertToSnakeCase(listOfFiltersRequired[key])] = {
              values: filterDefinition[listOfFiltersRequired[key]],
              filterType: "set"
            };
          }
        }
      }
      if (gridApi) {
        gridRef?.current?.api.setFilterModel(obj);
      }
    }
    //saveLastFavFilter(appliedFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilter]);

  const dataSource = {
    async getRows(params) {
      const { startRow, sortModel, groupKeys } = params.request;
      let colId, sort;
      // if (Object.keys(sortModelValueGridApi).length !== 0) {
      //   colId = sortModelValueGridApi[0].colId;
      //   sort = sortModelValueGridApi[0].sort;
      // }
      if (Object.keys(sortModel).length > 0) {
        colId = sortModel[0].colId;
        sort = sortModel[0].sort;
      }

      let filterStr = "";
      let queryOptions = [];
      let myLabEquipmentString = "";

      let filterQueryString = ` (or site_name:'${activeSiteName}') (not status: 'Deleted') (or entry_type:'${entryType.cluster}' entry_type:'${entryType.standaloneEquipment}')  `;
      if (
        myLabEquipment.length > 0 &&
        isEditMode &&
        appliedFilter.filterName === DEFAULT_MYLAB_FILTER.filterName
      ) {
        myLabEquipment.forEach((x) => {
          myLabEquipmentString = `${myLabEquipmentString} _id: '${x}' `;
        });
        myLabEquipmentString = ` ( or ${myLabEquipmentString})`;
        filterQueryString += ` ${myLabEquipmentString}`;
      }

      if (myLabEquipment.length > 0 && !isEditMode) {
        myLabEquipment.forEach((x) => {
          myLabEquipmentString = `${myLabEquipmentString} _id: '${x}' `;
        });
        myLabEquipmentString = ` ( or ${myLabEquipmentString})`;
        filterQueryString += ` ${myLabEquipmentString}`;
      }
      let userFilters = params?.api?.getFilterModel();
      let clusterExpand = groupKeys.length > 0 ? true : false;
      //for fetching cluster sub equipment
      if (clusterExpand) {
        userFilters = {};
        filterQueryString = ` cluster_id:'${
          groupKeys[params.parentNode.level]
        }' site_name:'${activeSiteName}' (not status:'Deleted') `;
      } else {
        filterQueryString += ` (not entry_type:'${entryType.clusterSubequipment}')`;
      }

      if (searchText) {
        filterStr += `*${searchText}*`;
        if (searchInColumn) {
          Object.entries(searchInColumn).forEach((filter) => {
            const [key] = filter;
            queryOptions.push(convertToSnakeCase(key));
          });
        }
      }

      let obj = {};
      Object.keys(userFilters).forEach((key) => {
        let filterObj = userFilters[key];
        if (
          filterObj?.filterType === "set" &&
          Object.keys(masterDataFilters).includes(convertToCameCase(key))
        ) {
          if (
            filterObj?.values?.length !==
            masterDataFilters[convertToCameCase(key)]?.length
          ) {
            obj[key] = userFilters[key];
          }
        } else {
          obj[key] = userFilters[key];
        }
      });
      filterCount.current = Object?.keys(obj)?.length;
      if (Object?.keys(obj)?.length > 0) {
        filterQueryString += ` ${getFilterModel(obj)} `;
      }
      let myLabState =
        myLabEquipment.length !== 0 &&
        !isEditMode &&
        (appliedFilter.filterName === null ||
          appliedFilter.filterName === DEFAULT_MYLAB_FILTER.filterName) &&
        Object.keys(userFilters)?.length === 0 &&
        (searchText === null || searchText === "") &&
        groupKeys.length === 0;

      let optionList = {
        grid_id: "equipmentList",
        options: {
          start: startRow,
          size: myLabState ? myLabEquipment.length : rowPerPage,
          sort: clusterExpand ? "asc" : sort || "asc",
          sortBy: clusterExpand
            ? convertToSnakeCase(POSITION_IN_CLUSTER)
            : colId || convertToSnakeCase(SERIAL_NUMBER),
          searchValue: clusterExpand
            ? null
            : (filterStr && `(${filterStr})`) || "",
          filterQuery: ` (and ${filterQueryString}) `,
          queryOptions:
            filterStr &&
            queryOptions.length > 0 &&
            `{ "fields": ['${queryOptions.join("', '")}'] }`
        }
      };

      if (!clusterExpand) {
        optionList = {
          ...optionList,
          options: {
            ...optionList?.options,
            negatefield: "-cluster_id:*"
          }
        };
      }

      let result = null;
      if (myLabEquipment.length === 0 && !isEditMode) {
        result = { count: 0, data: [] };
        setMyLabCount(() => result?.data);
      } else if (
        myLabEquipment.length === 0 &&
        isEditMode &&
        appliedFilter.filterName === DEFAULT_MYLAB_FILTER.filterName
      ) {
        result = { count: 0, data: [] };
      } else {
        result = await CloudSearchConfig(optionList.options);
      }
      if (myLabState) {
        let selectedCount = result?.data;
        selectedCount = selectedCount.map((x) => x.id);
        selectedCount = selectedCount.reduce(function (a, b) {
          return a.concat(b);
        }, []);
        setMyLabCount(() => selectedCount);
      }
      if (groupKeys.length === 0) {
        setTotalRows(result?.count);
      }

      params.success({
        rowData: result?.data,
        rowCount: result?.count
      });

      let nodes = params?.api?.getRenderedNodes();
      if (nodes && nodes.length === 0 && groupKeys.length === 0) {
        gridRef?.current?.api.showNoRowsOverlay();
      } else {
        gridRef?.current?.api?.hideOverlay();
      }
    }
  };

  const onRowGroupOpened = useCallback((e) => {
    if (e.node.expanded) {
      gridRef.current.api.refreshServerSide({
        route: e.node.key,
        purge: false
      });
    }
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "",
      field: "id",
      minWidth: 130,
      pinned: "left",
      lockPinned: true,
      suppressSizeToFit: true,
      cellClass: "lock-pinned",
      cellStyle: (params) => {
        return params?.node?.level > 0 &&
          (params?.data?.entry_type[0] === entryType?.cluster ||
            params?.data?.entry_type[0] === entryType?.clusterSubequipment)
          ? {
              border: "none",
              backgroundColor: "#F5F5F2",
              backgroundImage:
                "conic-gradient(at 20% 90%, #FFFFFF 180deg, #F5F5F2 180deg)"
            }
          : {};
      },

      cellRendererParams: {
        suppressCount: true,
        suppressSizeToFit: true,
        checkbox: (params) => {
          if (!isEditMode) {
            return false;
          }
          const node = params.node;
          const isGroupRow = node.level === 0;
          if (isGroupRow) {
            params.node.selectable =
              params.data.entry_type[0] !== entryType?.clusterSubequipment;
          }

          return isGroupRow;
        },

        innerRenderer: (params) => {
          return "";
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  const onGridReady = (params) => {
    setGridApi(params);
    params?.api?.setServerSideDatasource(dataSource);
  };

  const dLabColumnDef = () => {
    let columnFields = [];
    let columnData = EQUIP_DATA_MODEL_TABLE;

    for (const key in columnData) {
      let defaultObj = {
        field: convertToSnakeCase(columnData[key].key),
        headerName: columnData[key].value,
        sortable: true,
        suppressMenu: true,
        valueFormatter: (params) =>
          params.value === "null" || !params.value ? "-" : params.value
      };

      if (key === "status") {
        defaultObj = {
          field: convertToSnakeCase(columnData[key].key),
          headerName: columnData[key].value,
          sortable: true,
          suppressMenu: true,
          cellRenderer: EquipmentStatusRenderer,
          valueFormatter: (params) =>
            params.value === "null" || !params.value ? "-" : params.value
        };
      }

      if (listOfFiltersRequired?.includes(columnData[key].key)) {
        defaultObj = {
          ...defaultObj,
          filter: "agSetColumnFilter",
          filterParams: {
            defaultToNothingSelected: true,
            buttons: ["reset"],
            values: (params) => {
              const values = masterDataFilters[columnData[key].key] || [];
              params.success(values);
            }
          }
        };
      } else {
        defaultObj = {
          ...defaultObj,
          filter: false
        };
      }

      columnFields.push(defaultObj);
    }
    if (!isEditMode) {
      columnFields = [
        ...columnFields,
        {
          field: "action",
          headerName: "Actions",
          maxWidth: 50,
          filter: false,
          pinned: "right",
          lockVisible: true,
          cellClass: "action-render",
          cellRenderer: actionRender
        }
      ];
    }

    return columnFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const isServerSideGroup = useCallback((dataItem) => {
    // TODO to disable expandable mode
    if (dataItem.entry_type?.includes(entryType?.cluster)) {
      return dataItem.id;
    }
    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getServerSideGroupKey = useCallback((dataItem) => {
    // specify which group key to use
    if (dataItem.entry_type?.includes(entryType?.cluster)) {
      return dataItem?.id;
    }
    return "";
  }, []);
  const sideBarDef = useMemo(() => {
    let pannels = [
      {
        minWidth: 200,
        width: 276,
        id: "saveConfiguration",
        labelDefault: "Save configuration",
        labelKey: "saveConfiguration",
        iconKey: "setting-icon",
        toolPanel: (params) => {
          return (
            <MyLabSideBarTool
              id={generateID.UUID(
                Module.PAGE.MYLAB.title,
                `my_lab`,
                "sidebarTool"
              )}
              params={params}
              createOrUpdateMyLabFilter={createOrUpdateMyLabFilter}
              deleteMyLabFilter={deleteMyLabFilter}
            />
          );
        }
      }
    ];

    const filtersDef = {
      minWidth: 200,
      width: 276,
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel"
    };
    pannels = [filtersDef, ...pannels];
    return {
      toolPanels: pannels,
      defaultToolPanel: gridRef?.current?.api?.isSideBarVisible()
        ? "filters"
        : "",
      hiddenByDefault: !isFilterActive
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterActive]);
  const getFormattedFilter = (filters) => {
    if (Object.keys(filters).length > 0) {
      let tempFilter = {};
      for (const key in DEFAULT_MYLAB_FILTER.filterDefinition) {
        if (key !== "inventoryIds") {
          const convertedKey = convertToSnakeCase(key);
          if (filters[convertedKey]?.values !== undefined) {
            tempFilter[key] = filters[convertedKey].values;
          } else {
            tempFilter[key] = null;
          }
        } else if (key === "inventoryIds") {
          tempFilter[key] = [];
        }
      }
      return tempFilter;
    }
  };
  const onSortChanged = useCallback(() => {
    const value = gridApi.columnApi
      .getColumnState()
      .find((s) => s.sort != null);
    if (value) {
      setSortObj({ colId: value?.colId, sort: value?.sort });
    }
  }, [gridApi]);

  let attr = {
    height: 480,
    rowModelType: "serverSide",
    rowQuickSearch: true,
    treeData: true,
    suppressPaginationPanel: true,
    paginationPageSize: rowPerPage,
    columnDefs: dLabColumnDef(),
    pagination: true,
    animateRows: true,
    rowSelection: "multiple",
    rowMultiSelectWithClick: true,
    rowExport: false,
    masterDetail: false,
    suppressContextMenu: true,
    isGroupOpenByDefault: false,
    defaultToolPanel: "filters",
    hiddenByDefault: true,
    cacheBlockSize: rowPerPage,
    serverSideInfiniteScroll: false,
    isServerSideGroup,
    getServerSideGroupKey,
    autoGroupColumnDef,
    onGridReady,
    setRowPerPage,
    rowPerPage,
    totalRows,
    entryType,
    sideBar: sideBarDef
  };

  const onFirstDataRendered = useCallback(() => {
    defaultColDef.floatingFilter = !defaultColDef.floatingFilter;
    defaultColDef.cellStyle = (params) => {
      const secondColumn =
        params.columnApi.getAllDisplayedColumns()[1].colDef.headerName;
      const headerName = params.colDef.headerName;
      const isSecondColumn = secondColumn === headerName ? true : false;
      if (isSecondColumn && params?.node?.level) {
        return {
          paddingLeft: `${10 + params?.node?.level * 20}px`
        };
      }
    };
    setDefaultColDef({ ...defaultColDef });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myLabEquipment, isEditMode]);

  const onRowSelected = useCallback(
    (params) => {
      if (
        isEditMode &&
        (params?.source === "checkboxSelected" ||
          params?.source === "rowClicked")
      ) {
        const id = params?.data?.id[0];
        setIsSaveActive(true);
        if (!selectedEquipment.includes(id)) {
          //selected scenario
          setSelectedEquipment((prevState) => {
            return onlyUnique([...prevState, id]);
          });
        } else {
          // un-selected
          let uniqueList = onlyUnique(selectedEquipment);
          const index = uniqueList.indexOf(id);
          if (index > -1) {
            let temp = uniqueList;
            temp.splice(index, 1);
            setSelectedEquipment(() => {
              return temp;
            });
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditMode, selectedEquipment]
  );

  const updateMyLabEquipment = async () => {
    //const userSelectedRows = getSelectedRowData();
    //const equipmentIds = userSelectedRows.map((x) => x.id[0]);
    const myLabfilters = myLabFiltersList.find(
      (x) => x.filterName === DEFAULT_MYLAB_FILTER.filterName
    );
    if (typeof myLabfilters?.filterDefinition === "string") {
      myLabfilters.filterDefinition = JSON.parse(
        myLabfilters?.filterDefinition
      );
    }
    //separate default equipment from initial Userfilter
    let filteredArray = [];
    if (myLabCountInitial !== undefined) {
      filteredArray = myLabCountInitial.filter((x) => !myLabCount.includes(x));
    }
    //user selected equipments
    let tempFilterDefinition = myLabfilters
      ? myLabfilters?.filterDefinition
      : DEFAULT_MYLAB_FILTER?.filterDefinition;
    tempFilterDefinition.inventoryIds = onlyUnique(selectedEquipment);
    //push default to user selected equipments
    let filtertested = tempFilterDefinition?.inventoryIds || [];
    filtertested = filtertested.concat(filteredArray);
    tempFilterDefinition.inventoryIds = onlyUnique(filtertested);
    //DEFAULT_FILTER
    try {
      const saveResult = await client.mutate({
        mutation:
          myLabfilters.isDefaultAdded === undefined
            ? UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_FILTER
            : CREATE_DIGITAL_LAB_LANDING_PAGE_USER_FILTER,
        variables: {
          userID,
          filterName: myLabfilters?.filterName,
          filterDefinition: JSON.stringify(tempFilterDefinition)
        }
      });

      if (
        saveResult?.data?.updateUserFilter ||
        saveResult?.data?.createUserFilter
      ) {
        const userFilters = await fetchingMyLabEquipment();
        if (
          myLabfilters.isDefaultAdded !== undefined &&
          myLabfilters.isDefaultAdded === true
        ) {
          setMyLabFiltersList(userFilters);
        }
        setIsSaveActive(false);
        setMyLabEquipment(tempFilterDefinition.inventoryIds);
        setIsEditMode(false);
        setAppliedFilter(DEFAULT_MYLAB_FILTER);
        setSearchText(null);
        Snackbar({
          type: "success",
          icon: "circle_confirm",
          appName: null,
          text: `My lab configuration successfully saved`
        });
      } else {
        Snackbar({
          type: "warning",
          icon: "caution",
          appName: null,
          text: `My lab configuration failed to save`
        });
      }
    } catch (error) {
      Snackbar({
        type: "warning",
        icon: "caution",
        appName: null,
        text: `My lab configuration failed to save`
      });
    }
  };
  const removeMyLabEquipment = async (id) => {
    if (id) {
      const myLabfilters = myLabFiltersList.find(
        (x) => x.filterName === DEFAULT_MYLAB_FILTER.filterName
      );
      let tempFilterDefinition = myLabfilters?.filterDefinition;
      const inventoryIds = tempFilterDefinition.inventoryIds.filter(
        (x) => x !== id
      );

      let filteredArray = [];
      if (myLabCountInitial !== undefined) {
        filteredArray = myLabCountInitial.filter((x) => !id.includes(x));
        tempFilterDefinition.inventoryIds = onlyUnique(
          inventoryIds.concat(filteredArray)
        );
      }

      //DEFAULT_FILTER
      await client.mutate({
        mutation:
          myLabfilters.isNotDefault === undefined
            ? UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_FILTER
            : CREATE_DIGITAL_LAB_LANDING_PAGE_USER_FILTER,
        variables: {
          userID,
          filterName: myLabfilters?.filterName,
          filterDefinition: JSON.stringify(tempFilterDefinition)
        }
      });
      Snackbar({
        type: "success",
        icon: "circle_confirm",
        appName: null,
        text: `My lab configuration successfully saved`
      });
      await fetchingMyLabEquipment();
      if (inventoryIds.length > 0) {
        setMyLabEquipment([...inventoryIds]);
      } else {
        setMyLabEquipment([]);
      }
      if (myLabCount?.length !== 0) {
        let myLabId = [];
        myLabId = myLabCount.filter((x) => x !== id);
        setMyLabCount(() => myLabId);
      }
    }
  };
  const createOrUpdateMyLabFilter = async (
    filterName = null,
    filterObj = null,
    isUpdateOperation = false
  ) => {
    if (filterName && Object.keys(filterObj).length > 0) {
      const filterDefinition = JSON.stringify(
        getFormattedFilter({ ...filterObj })
      );

      try {
        await client.mutate({
          mutation: isUpdateOperation
            ? UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_FILTER
            : CREATE_DIGITAL_LAB_LANDING_PAGE_USER_FILTER,
          variables: {
            userID,
            filterName,
            filterDefinition
          }
        });
        const userFilters = await fetchingMyLabEquipment();
        const myLabfilters = userFilters.find(
          (x) => x.filterName === filterName
        );
        setMyLabFiltersList(userFilters);
        setAppliedFilter(myLabfilters);
        Snackbar({
          type: "success",
          icon: "circle_confirm",
          appName: null,
          text: `My lab configuration successfully saved`
        });
      } catch (err) {
        Snackbar({
          type: "warning",
          icon: "no",
          appName: null,
          text: `Failed to save my lab configuration`
        });
      }
    } else {
      Snackbar({
        type: "success",
        icon: "circle_confirm",
        appName: null,
        text: `Detail is missing`
      });
    }
  };
  const deleteMyLabFilter = async (filterName = null) => {
    if (filterName && filterName !== DEFAULT_MYLAB_FILTER?.filterName) {
      try {
        await client.mutate({
          mutation: DELETE_DIGITAL_LAB_LANDING_PAGE_USER_FILTER,
          variables: {
            userID,
            filterName
          }
        });

        const userFilters = await fetchingMyLabEquipment();
        setMyLabFiltersList(userFilters);
        if (filterName === appliedFilter.filterName) {
          setAppliedFilter([DEFAULT_MYLAB_FILTER]);
        }

        Snackbar({
          type: "success",
          icon: "circle_confirm",
          appName: null,
          text: `My lab configuration successfully saved`
        });
      } catch (err) {
        Snackbar({
          type: "success",
          icon: "circle_confirm",
          appName: null,
          text: `Failed to save my lab configuration`
        });
      }
    } else {
      Snackbar({
        type: "success",
        icon: "circle_confirm",
        appName: null,
        text: `Filter name is missing`
      });
    }
  };

  const CustomNoRowsOverlayWithNormalMode = (props) => {
    return (
      <div
        className="myLabNoDataOverLay"
        id={generateID.UUID(Module.PAGE.MYLAB.title, `customOverlay`, "div")}
        onClick={() => {
          let defaultFilter = { ...DEFAULT_MYLAB_FILTER };
          defaultFilter.filterName = null;
          setIsEditMode(true);
          setAppliedFilter(defaultFilter);
          // setMyLabEquipment(myLabCount);
          setSearchText(null);
        }}
      >
        <OwcIcon className="emptyIconMyLab">
          {" "}
          <EmptyMyLab />{" "}
        </OwcIcon>
        <OwcTypography
          variant="title6"
          id={generateID.UUID(
            Module.PAGE.MYLAB.title,
            `customOverlay`,
            "type_title"
          )}
        >
          Select components
          <OwcTypography
            variant="body2"
            id={generateID.UUID(
              Module.PAGE.MYLAB.title,
              `customOverlay`,
              "type_title"
            )}
          >
            No components in my lab.
          </OwcTypography>
        </OwcTypography>
      </div>
    );
  };
  const CustomNoRowsOverlayWithEditMode = (props) => {
    return (
      <>
        <div className="myLabNoDataOverLaywithoutEditMode">
          <OwcTypography variant="title6">
            There are no items available based on your selected filters criteria
          </OwcTypography>
        </div>
        <OwcDivider />
      </>
    );
  };
  const noRowsOverlayComponent = useMemo(() => {
    return isEditMode
      ? CustomNoRowsOverlayWithEditMode
      : filterCount.current === 0 && (searchText === null || searchText === "")
      ? CustomNoRowsOverlayWithNormalMode
      : CustomNoRowsOverlayWithEditMode;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, searchText, filterCount.current]);

  const onModelUpdated = () => {
    let nodes = gridApi?.api?.getRenderedNodes();
    if (gridApi && gridApi?.api && isEditMode) {
      if (nodes && nodes?.length > 0 && selectedEquipment?.length > 0) {
        gridApi?.api?.forEachNode((node) => {
          return node.setSelected(
            node?.data && selectedEquipment.includes(node?.data.id[0])
          );
        });
      } else if (nodes && nodes?.length > 0) {
        gridApi?.api?.forEachNode((node) => {
          return node?.setSelected(false);
        });
      }
    } else if (nodes && nodes?.length > 0) {
      gridApi?.api?.forEachNode((node) => {
        return node?.setSelected(false);
      });
    }
  };

  const onViewportChanged = () => {
    let nodes = gridApi?.api?.getRenderedNodes();
    if (gridApi && gridApi?.api && isEditMode) {
      if (nodes && nodes?.length > 0 && selectedEquipment?.length > 0) {
        gridApi?.api?.forEachNode((node) => {
          return node?.setSelected(
            node?.data && selectedEquipment?.includes(node?.data?.id[0])
          );
        });
      } else if (nodes && nodes?.length > 0) {
        gridApi?.api?.forEachNode((node) => {
          return node?.setSelected(false);
        });
      }
    } else if (nodes && nodes?.length > 0) {
      gridApi?.api?.forEachNode((node) => {
        return node?.setSelected(false);
      });
    }
  };

  const getRowStyle = (params) => {
    if (params?.data?.status && params?.data?.status[0] === "Delete") {
      return { opacity: 0.5 };
    }
    if (params?.node?.level > 0) {
      if (params?.data?.entry_type?.includes(entryType?.cluster)) {
        return { borderBottom: "1px solid #F5F5F2" };
      }
    }
    if (
      params?.node?.level === 0 &&
      params?.data?.entry_type?.includes(entryType?.cluster)
    ) {
      return { borderBottom: "1px solid #706B69" };
    }
    if (
      params?.node?.level > 0 &&
      params?.data?.entry_type?.includes(entryType?.clusterSubequipment)
    ) {
      return { borderBottom: "1px solid #F5F5F2" };
    }
  };

  return (
    <div>
      <OwcCard
        id={generateID.UUID(Module.PAGE.MYLAB.title, `equipmentpage`, "card")}
        style={{ padding: "16px 0" }}
        flat
      >
        <div style={{ marginLeft: "16px" }} slot="header">
          <EquipmentTitleBar
            id={generateID.UUID(
              Module.PAGE.MYLAB.title,
              `equipmentpage`,
              "titlebar"
            )}
          />
          <EquipmentFilterBar
            searchText={searchText}
            setSearchText={setSearchText}
            gridRef={gridRef}
            isEditMode={isEditMode}
            defaultColDef={defaultColDef}
            setDefaultColDef={setDefaultColDef}
            setIsFilterActive={setIsFilterActive}
            isFilterActive={isFilterActive}
            id={generateID.UUID(
              Module.PAGE.MYLAB.title,
              `equipmentpage`,
              "filterbar"
            )}
          />
        </div>

        <div
          style={{ width: "100%" }}
          id={generateID.UUID(
            Module.PAGE.MYLAB.title,
            `equipmentpage`,
            "dlab_grid_div"
          )}
        >
          <DLabGrid
            {...attr}
            gridRef={gridRef}
            defaultColDef={defaultColDef}
            getRowStyle={getRowStyle}
            onFirstDataRendered={onFirstDataRendered}
            noRowsOverlayComponent={noRowsOverlayComponent}
            onRowSelected={onRowSelected}
            icons={icons}
            hidePagination={!(isEditMode || myLabCount?.length > 0)}
            onModelUpdated={onModelUpdated}
            onViewportChanged={onViewportChanged}
            onRowGroupOpened={onRowGroupOpened}
            options={agGridOptions}
            onSortChanged={onSortChanged}
          />
        </div>

        <div
          slot="footer"
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center"
          }}
        >
          {isEditMode && (
            <OwcButton
              onClick={() => {
                openDialog();
              }}
              variant="secondary"
              style={{ margin: "0px 10px 0px 10px" }}
            >
              Cancel
            </OwcButton>
          )}

          {isEditMode && (
            <OwcButton
              onClick={() => {
                if (selectedEquipment?.length === 0) {
                  setMyLabCount(() => []);
                  setMyLabEquipment([]);
                }
                if (isSaveActive) {
                  updateMyLabEquipment();
                }
              }}
              disabled={isSaveDisabled}
              style={{ margin: "0px 10px 0px 10px" }}
            >
              Save My lab
            </OwcButton>
          )}
          {((!isEditMode && myLabCount?.length > 0) ||
            (!isEditMode && filterCount.current > 0)) && (
            <OwcButton
              variant="secondary"
              style={{ margin: "0px 10px 0px 10px" }}
              onClick={() => {
                let defaultFilter = { ...DEFAULT_MYLAB_FILTER };
                defaultFilter.filterName = null;
                setIsEditMode(true);
                setAppliedFilter(defaultFilter);
                setMyLabEquipment(myLabCount);
                setSearchText(null);
              }}
            >
              Edit My lab
            </OwcButton>
          )}
        </div>
      </OwcCard>
      <ConfirmDialog
        {...dialogProps}
        onApprove={() => {
          setSelectedEquipment(myLabEquipment);
          setIsSaveActive(false);
          setAppliedFilter(DEFAULT_MYLAB_FILTER);
          setIsEditMode(false);
          setSearchText(() => "");
        }}
        approveText="Discard"
        approveColor="error"
        approveVariant="contained"
        cancelText="Cancel"
        cancelVariant="outlined"
        cancelColor="primary"
        title="Equipment list"
        content={`Do you really want to discard changes`}
      />
      {showMenu && (
        <MyEquipmentMenu
          setShowMenu={setShowMenu}
          allMegaclusterIds={allMegaclusterIds}
          params={currentParams}
          removeMyLabEquipment={removeMyLabEquipment}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  userID: store?.user?.id,
  email: store?.user?.email,
  lastFilter: store?.user?.lastFilter
});

export default compose(connect(mapStateToProps), withApollo)(EquipmentPage);
