import React, { useRef } from "react";
import { OwcListItem, OwcSelectDropdown } from "@one/react";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../constants";

export const runModes = [
  {
    id: "external",
    name: "direct"
  },
  {
    id: "internal",
    name: "SSO wrapped"
  }
];

const RunModeInput = ({
  // classes,
  application,
  updateApplication,
  setDirtyInputs,
  dirtyInputs
}) => {
  const selectDropdownElement = useRef(null);
  return (
    <>
      {/* <FormControl
        fullWidth
        variant="filled"
        data-testid="application-edit-form-formcontrol-run-mode"
        id={generateID.UUID(Module.PAGE.ADMIN.runModeInput, `runMode`, "form_control")}
      >
        <InputLabel
          id={generateID.UUID(Module.PAGE.ADMIN.runModeInput, `runMode`, "input_label")}
          data-testid="application-edit-form-inputLabel-run-mode"
          // classes={{
          //   shrink: classes.multiSelectLabelShrink,
          //   filled: classes.multiSelectLabelFilled
          // }}
        >
          Run mode
        </InputLabel> */}
      {/* <Select
          data-testid="application-edit-form-select-run-mode"
          // classes={{
          //   root: classes.multiSelectSelect
          // }}
          value={application.type}
          onChange={(event) => {
            updateApplication({
              value: event.target.value,
              key: "type"
            });
          }}
          onBlur={() =>
            setDirtyInputs({
              ...dirtyInputs,
              type: true
            })
          }
        >
          {runModes.map((runMode) => (
            <MenuItem
              id={generateID.UUID(Module.PAGE.ADMIN.runModeInput, `runMode`, "menu_item")}
              data-testid={
                "admin-panel-modal-select-run-mode-menu-item-" + runMode.id
              }
              key={runMode.id}
              value={runMode.id}
            >
              {runMode.name}
            </MenuItem>
          ))}
        </Select> */}
      <OwcSelectDropdown
        ref={selectDropdownElement}
        label="Run mode"
        id={generateID.UUID(
          Module.PAGE.ADMIN.runModeInput,
          `runMode`,
          "select_drop"
        )}
        data-testid="application-edit-form-select-run-mode"
        name="Run mode"
        onSelectChange={(e) => {
          const runModeSelected = runModes.find(
            (x, index) => index === e.detail[0]
          );
          updateApplication({
            value: runModeSelected.name,
            key: "type"
          });
        }}
      >
        {runModes.map((runMode) => (
          <OwcListItem
            selected={application?.type === runMode?.name}
            data-testid={
              "admin-panel-modal-select-run-mode-menu-item-" + runMode.id
            }
            // key={runMode.id}
            // value={runMode.id}
          >
            {runMode.name}
          </OwcListItem>
        ))}
      </OwcSelectDropdown>
      {/* </FormControl> */}
    </>
  );
};
export default RunModeInput;
