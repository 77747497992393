import { OwcChip } from "@one/react";
import React from "react";
import { status } from "../../constants";

const EquipmentStatusRenderer = (params) => {
  const Status = status[params.value];
  return (
    <>
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        {params.value !== null ? (
          <OwcChip
            outlined={true}
            style={{
              minHeight: "22px",
              color:
                Status === "Active"
                  ? "var(--one-color-cobas-green-600)"
                  : "var(--one-color-yellow-a-300)"
            }}
            text={Status}
          >
            <strong>{Status}</strong>
          </OwcChip>
        ) : (
          "-"
        )}
      </div>
    </>
  );
};

export default EquipmentStatusRenderer;
