import React, { useCallback, useContext, useRef } from "react";
import { throttledNotify } from "./helpers";
import { usePermissions } from "./notificationPermissions";
export const NotificationContext = React.createContext();

export const SEVERITY_MAP = Object.freeze({
  LOW: {
    value: "LOW",
    num: 10
  },
  MEDIUM: {
    value: "MEDIUM",
    num: 20
  },
  HIGH: {
    value: "HIGH",
    num: 30
  }
});

export const SystemNotificationsWrapper = ({
  children,
  systemNotify = throttledNotify
}) => {
  usePermissions();
  const lastSeverity = useRef(SEVERITY_MAP.LOW.num);
  const lastId = useRef("");
  /* eslint-disable react-hooks/exhaustive-deps */
  const notifyCallback = useCallback(({ type, payload }) => {
    const currentType =
      SEVERITY_MAP[type?.trim()?.toUpperCase()]?.num || SEVERITY_MAP.LOW.num;
    if (currentType >= lastSeverity.current && lastId.current !== payload?.id) {
      systemNotify({
        type: type?.trim()?.toUpperCase(),
        counter: payload?.counter,
        message: payload?.message,
        source: payload?.source
      });
      if (currentType > lastSeverity.current) {
        systemNotify.flush();
      }
      lastId.current = payload?.id;
    }
    lastSeverity.current = currentType;
  }, []);
  return (
    <NotificationContext.Provider value={notifyCallback}>
      {children}
    </NotificationContext.Provider>
  );
};

export const withSystemNotification = (Component) => (props) => {
  const notifyAction = useContext(NotificationContext);
  return <Component {...props} notify={notifyAction} />;
};
