const MyLabIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1225_25305)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.77298 5.728L3.42166 9.78838C3.54071 9.70887 3.68379 9.66251 3.8377 9.66251C4.25191 9.66251 4.5877 9.99829 4.5877 10.4125L4.5877 19.5625H19.4002L19.4002 10.4125C19.4002 9.99829 19.736 9.66251 20.1502 9.66251C20.3085 9.66251 20.4554 9.71157 20.5765 9.79533L18.2249 5.7236C18.0226 5.37147 17.6596 5.16251 17.2502 5.16251L6.7502 5.16251C6.3491 5.16251 5.97971 5.37709 5.77298 5.728ZM20.9002 11.0126L20.9002 20.1247C20.9381 20.6523 20.5004 21.0625 20.0127 21.0625L3.9752 21.0625C3.35125 21.0625 3.0877 20.5179 3.0877 20.1625L3.0877 11.0218C2.96456 11.1103 2.81347 11.1625 2.6502 11.1625C2.08598 11.1625 1.6377 10.7142 1.6377 10.15C1.6377 9.94054 1.70075 9.78434 1.75437 9.6771C1.76122 9.6634 1.76849 9.64991 1.77617 9.63666L4.47837 4.97035C4.94683 4.17224 5.80192 3.66251 6.7502 3.66251L17.2502 3.66251C18.1906 3.66251 19.0524 4.15329 19.5252 4.97578C19.525 4.97556 19.5253 4.97599 19.5252 4.97578L22.2224 9.64604C22.5282 10.1644 22.2982 10.7683 21.8611 11.0306C21.8141 11.0588 21.7643 11.0817 21.7124 11.099C21.7104 11.0997 21.7082 11.1004 21.7058 11.1012C21.6601 11.1167 21.5246 11.1625 21.3502 11.1625C21.1814 11.1625 21.0255 11.1067 20.9002 11.0126Z"
        fill="#706B69"
      />
      <path d="M1.6001 20.325L22.4001 20.325L1.6001 20.325Z" fill="#544F4F" />
      <path
        d="M1.6001 20.325L22.4001 20.325"
        stroke="#706B69"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9994 12.05V8H10.7993V12.375L13.1995 16.75H7.19889L9.59914 12.375V8H8.39902V12.0438L6.16079 16.125C6.05551 16.3149 6.00005 16.5304 6 16.7497C5.99995 16.969 6.05529 17.1845 6.16048 17.3744C6.26567 17.5644 6.41699 17.7222 6.59926 17.8321C6.78153 17.9419 6.98833 17.9998 7.19889 18H13.1995C13.4101 17.9998 13.6169 17.9419 13.7991 17.8321C13.9814 17.7222 14.1327 17.5644 14.2379 17.3744C14.3431 17.1845 14.3985 16.969 14.3984 16.7497C14.3983 16.5304 14.3429 16.3149 14.2376 16.125L11.9994 12.05Z"
        fill="#706B69"
      />
      <path
        d="M13.7996 8V12.6938C13.7996 12.9424 13.8944 13.1808 14.0632 13.3567C14.232 13.5325 14.4609 13.6313 14.6997 13.6313C14.9384 13.6313 15.1673 13.5325 15.3361 13.3567C15.5049 13.1808 15.5998 12.9424 15.5998 12.6938V8H13.7996ZM14.9997 9.25V12.6938C14.9997 12.7766 14.9681 12.8561 14.9118 12.9147C14.8555 12.9733 14.7792 13.0063 14.6997 13.0063C14.6201 13.0063 14.5438 12.9733 14.4875 12.9147C14.4312 12.8561 14.3996 12.7766 14.3996 12.6938V9.25H14.9997Z"
        fill="#706B69"
      />
      <path
        d="M16.1998 8V12.6938C16.1998 12.9424 16.2946 13.1808 16.4634 13.3567C16.6322 13.5325 16.8612 13.6313 17.0999 13.6313C17.3386 13.6313 17.5676 13.5325 17.7364 13.3567C17.9052 13.1808 18 12.9424 18 12.6938V8L16.1998 8ZM17.3999 12.6938C17.3999 12.7766 17.3683 12.8561 17.3121 12.9147C17.2558 12.9733 17.1795 13.0063 17.0999 13.0063C17.0203 13.0063 16.944 12.9733 16.8878 12.9147C16.8315 12.8561 16.7999 12.7766 16.7999 12.6938V9.25H17.3999V12.6938Z"
        fill="#706B69"
      />
    </g>
    <defs>
      <clipPath id="clip0_1225_25305">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default MyLabIcon;
