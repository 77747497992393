import React, { useContext } from "react";
import { AddOrEditContext, DeleteContext } from "./contexts";
import { OwcIconButton, OwcListItem, OwcMenu } from "@one/react";
import ReactDOM from "react-dom";

const Actions = ({ item }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { open: openEditDialog } = useContext(AddOrEditContext);
  const { open: openDeleteDialog } = useContext(DeleteContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnEdit = () => {
    openEditDialog(item);
    setAnchorEl(null);
  };

  const handleOnDelete = () => {
    openDeleteDialog(item);
    setAnchorEl(null);
  };

  const moreIconItems = [
    {
      name: "Edit"
    },
    {
      name: "Delete"
    }
  ];

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const itemList = moreIconItems.map((item) => {
    return (
      <OwcListItem
        onClick={item.name === "Edit" ? handleOnEdit : handleOnDelete}
      >
        {item.name}
      </OwcListItem>
    );
  });

  return (
    <>
      <OwcIconButton
        flat
        type="outlined"
        icon="more_vertical"
        onClick={handleClick}
        id={item.name}
      />
      {ReactDOM.createPortal(
        <OwcMenu
          data-testid="simple-menu"
          anchor={item.name}
          visible={anchorEl}
          onClickOutside={handleClose}
        >
          {itemList}
        </OwcMenu>,
        document.body
      )}
    </>
  );
};

export default Actions;
