import { OwcIcon, OwcListItem, OwcMenu } from "@one/react";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmDialog from "../../../components/shared/ConfirmDialog";
import useDialog from "../../../utils/hooks/useDialog";
import { AllWidgetsContext } from "../../adminPanel/widgets/context/context";
import { updateDashboardWidgets } from "../../landingPageUser/redux/actions";
import PhotoSizeSelectSmallIcon from "@mui/icons-material/PhotoSizeSelectSmall";
import ReactDOM from "react-dom";

const deleteWidgetFromDashboard = (widget, widgets, dispatch) => {
  const newWidgetsList = widgets.filter(
    (theWidget) => theWidget.widgetId !== widget.widgetId
  );
  dispatch(updateDashboardWidgets(newWidgetsList));
};

const setSize = (widget, widgets, dispatch, newSize) => {
  const newWidgetsList = widgets.map((theWidget) => {
    if (theWidget.widgetId === widget.widgetId) {
      return {
        ...widget,
        size: {
          ...newSize
        }
      };
    }
    return theWidget;
  });
  dispatch(updateDashboardWidgets(newWidgetsList));
};

const WidgetItemMenu = ({ anchorEl, setAnchorEl, widget, widgets }) => {
  const [showSizes, setShowSizes] = useState(false);
  const { widgets: allWidgets } = useContext(AllWidgetsContext);
  const dispatch = useDispatch();
  const { openDialog, ...dialogProps } = useDialog();
  const supportedSizes =
    allWidgets.find((w) => w.id === widget.widgetId)?.supportedSizes ?? [];
  return (
    <>
      {ReactDOM.createPortal(
        <OwcMenu
          onClickOutside={(event) => setAnchorEl(null)}
          anchor={`widget_${widget.widgetId}`}
          visible={anchorEl}
          alignment="right"
        >
          {!showSizes && (
            <>
              <OwcListItem
                data-testid="widget-item-edit-menu-item-change-size"
                onClick={() => setShowSizes(true)}
                noBorder
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <PhotoSizeSelectSmallIcon
                    data-testid="widget-item-edit-menu-item-change-size-icon"
                    style={{ marginRight: 15 }}
                  />
                  Change size
                </div>
              </OwcListItem>
              <OwcListItem
                data-testid="widget-item-edit-menu-item-remove"
                onClick={() => openDialog()}
                noBorder
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <OwcIcon
                    name="circle_remove"
                    type="outlined"
                    style={{ color: "red", marginRight: 15 }}
                  />
                  Remove
                </div>
              </OwcListItem>
            </>
          )}
          {showSizes && (
            <>
              <OwcListItem
                data-testid="widget-item-edit-menu-item-sizes-header"
                onClick={() => setShowSizes(false)}
                icon="arrow_leftwards"
                iconType="outlined"
                noBorder
              >
                Sizes
              </OwcListItem>
              {supportedSizes?.map((size) => (
                <OwcListItem
                  data-testid={`widget-item-edit-menu-item-sizes-item-for-${size.width}x${size.height}`}
                  onClick={() => {
                    setSize(widget, widgets, dispatch, size);
                    setAnchorEl(null);
                    setShowSizes(false);
                  }}
                  noBorder
                >
                  {size.width}x{size.height}
                </OwcListItem>
              ))}
            </>
          )}
        </OwcMenu>,
        document.body
      )}
      <ConfirmDialog
        {...dialogProps}
        onApprove={() => {
          deleteWidgetFromDashboard(widget, widgets, dispatch);
          setAnchorEl(null);
        }}
        onCancel={() => setAnchorEl(null)}
        approveText="Delete widget"
        approveColor="error"
        approveVariant="contained"
        cancelText="Cancel"
        cancelVariant="outlined"
        cancelColor="primary"
        title="Delete widget"
        content={`Are you sure you want to delete the widget from dashboard?`}
      />
    </>
  );
};

export default WidgetItemMenu;
