import {
  OwcButton,
  OwcDivider,
  OwcIcon,
  OwcIconButton,
  OwcTypography
} from "@one/react";
import React, { useContext, useState } from "react";
import { OwcInput } from "@one/react";
import MyLabFilterChips from "./MyLabFilterChips";
import MyLabFilterDropDown from "./MyLabFilterDropDown";
import { EquipmentsContext } from "./equipments-context/context";
import {
  DEFAULT_MYLAB_FILTER,
  SELECT_EXISTING_CONFIGURATION,
  NEW_CONFIGURATION_NAME
} from "../../constants";

export const getIndex = (arr, key, value) => {
  return arr.findIndex((obj) => obj[key] === value);
};

const MyLabSideBarTool = ({
  params,
  createOrUpdateMyLabFilter,
  deleteMyLabFilter
}) => {
  const [filterConfigurationName, setFilterConfigurationName] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isEditCase, setIsEditCase] = useState(false);
  const [selectExistingFilter, setSelectExistingFilter] = useState(null);
  const { myLabFiltersList } = useContext(EquipmentsContext);
  const viewList = myLabFiltersList
    .filter((x) => x.filterName !== DEFAULT_MYLAB_FILTER.filterName)
    .map((x) => x.filterName);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 0 0 10px"
        }}
      >
        <OwcTypography variant="subtitle1">Saved configurations</OwcTypography>
        <OwcIconButton
          icon="circle_help"
          type="filled"
          flat
          style={{ color: "var(--one-color-background-brand-1)" }}
        />
      </div>
      <OwcDivider />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: "10px"
        }}
      >
        <MyLabFilterChips deleteMyLabFilter={deleteMyLabFilter} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 0 0 10px"
        }}
      >
        <OwcTypography variant="subtitle1">
          Save current configuration
        </OwcTypography>
        <OwcIconButton
          icon="circle_help"
          type="filled"
          flat
          style={{ color: "var(--one-color-background-brand-1)" }}
        />
      </div>
      <OwcDivider />
      <div
        style={{ padding: "0 10px", display: "flex", flexDirection: "column" }}
      >
        <MyLabFilterDropDown
          style={{ width: "100%" }}
          list={viewList}
          selected={selectExistingFilter}
          labelValue={true}
          onChange={(selectedValue) => {
            const tempFilters = params?.api?.getFilterModel();
            let filterValue = viewList.find(
              (x, index) => index === selectedValue
            );
            if (filterValue === undefined) {
              filterValue = null;
            }
            setSelectExistingFilter(filterValue);
            setIsSaveDisabled(
              filterValue === null || Object.keys(tempFilters).length === 0
            );
            setIsEditCase(filterValue !== null);
          }}
          disabled={filterConfigurationName !== null}
          label={SELECT_EXISTING_CONFIGURATION}
        />

        <OwcInput
          label={NEW_CONFIGURATION_NAME}
          placeholder={NEW_CONFIGURATION_NAME}
          value={filterConfigurationName}
          style={{ marginTop: 13, width: "100%" }}
          onInputChange={(event) => {
            const tempFilters = params?.api?.getFilterModel();
            setIsSaveDisabled(Object.keys(tempFilters).length === 0);
            setIsEditCase(false);
            setFilterConfigurationName(event.target.value);
          }}
          disabled={selectExistingFilter !== null}
        >
          <div
            slot="suffix"
            style={{
              color: "var(--one-color-interaction-default-neutral-3)"
            }}
          >
            {true && (
              <OwcIcon
                name="clear"
                style={{ cursor: "pointer" }}
                onClick={() => setFilterConfigurationName(null)}
              />
            )}
          </div>
        </OwcInput>
      </div>

      <div style={{ margin: "13px 16px" }}>
        <OwcButton
          variant="primary"
          data-testid="try-again-button"
          style={{ marginLeft: "auto" }}
          disabled={
            isSaveDisabled || !(selectExistingFilter || filterConfigurationName)
          }
          onClick={() => {
            const tempFilters = params?.api?.getFilterModel();
            createOrUpdateMyLabFilter(
              isEditCase ? selectExistingFilter : filterConfigurationName,
              tempFilters,
              isEditCase
            );
            setTimeout(() => {
              setFilterConfigurationName(null);
              setSelectExistingFilter(null);
            });
          }}
        >
          Save
        </OwcButton>
      </div>
    </div>
  );
};

export default MyLabSideBarTool;
