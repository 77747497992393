import { OwcButton } from "@one/react";
import { useContext, useState } from "react";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { Module } from "../../../constants";
import ChangeWidgetWorkflow from "./widgetsForWidgets/ChangeWidgetWorkflow";
import { emptyWidget } from "./widgetsForWidgets/empty-widget";
import { generateID } from "@digitallab/grid-common-components";

const {adminWidget} = Module.PAGE.ADMIN;

const AddWidgetButton = ({ showTextOnly = false }) => {
  const [widget, setWidget] = useState(null);
  const { dispatchAction } = useContext(PaginationContext);
  const refresh = () => dispatchAction({ type: "refresh" });

  return (
    <>
      {showTextOnly ? (
        <div
          variant="contained"
          color="primary"
          aria-label={"Add"}
          // startIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            setWidget({ ...emptyWidget });
          }}
          data-testid="add-widgets-button"
          id={generateID.UUID(adminWidget, `addwidget`, "div")}
        >
          Add widget
        </div>
      ) : (
        <OwcButton
          aria-label={"Add"}
          onClick={() => {
            setWidget({ ...emptyWidget });
          }}
          data-testid="add-widgets-button"
          id={generateID.UUID(adminWidget, `addwidget`, "button")}
        >
          Add widget
        </OwcButton>
      )}
      {widget && (
        <ChangeWidgetWorkflow
          widget={widget}
          onCancel={() => setWidget(null)}
          onApprove={refresh}
        />
      )}
    </>
  );
};

export default AddWidgetButton;
