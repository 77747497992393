import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Module } from "../../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const { adminWidget } = Module.PAGE.ADMIN;

const MetadataUrlForm = ({
  metadataUrl,
  onMetadataUrlChange,
  onMetadataUrlBlur,
  dataTestId,
  error
}) => {
  return (
    <TextField
      fullWidth
      onBlur={onMetadataUrlBlur}
      data-testid={`${dataTestId}-metadata-url-input`}
      id="standard-secondary"
      label="Metadata URL"
      error={Boolean(error)}
      color="primary"
      variant="filled"
      required={true}
      InputProps={
        metadataUrl?.length > 0 && {
          endAdornment: (
            <InputAdornment id={generateID.UUID(adminWidget, `metadataurl`, "input_adornment")} position="end">
              <IconButton
                data-testid="metadata-url-input-clear-button"
                edge="end"
                onClick={() => onMetadataUrlChange("")}
                id={generateID.UUID(adminWidget, `metadataurl`, "icon_button")}
              >
                <i className="one-icons-outlined" id={generateID.UUID(adminWidget, `metadataurl`, "i")}>clear</i>
              </IconButton>
            </InputAdornment>
          )
        }
      }
      size="small"
      value={metadataUrl}
      onChange={(event) => onMetadataUrlChange(event.target.value)}
      FormHelperTextProps={{
        "data-testid": "metadata-url-input-helper-text"
      }}
      helperText={error}
    />
  );
};

export default MetadataUrlForm;
