import React, { useRef, useEffect, useState } from "react";
import { signOut } from "../utils/signout";
import NotificationCounter from "../features/userNotifications/NotificationCounter";
import SwitchSite from "../features/siteChange/SwitchSite";
import AppSwitcher from "./AppSwitch";
import GRIDIcon from "../icons/GRIDIcon.svg";
import { OwcIcon } from "@one/react/Components/OwcIcon";
import { OwcDoubleGlobalArea } from "@one/react/Components/OwcDoubleGlobalArea";
import { OwcIconButton } from "@one/react/Components/OwcIconButton";
import { OwcTypography } from "@one/react/Components/OwcTypography";
import styles from "./AppBarDesktop.module.scss";
import { Module, TOP_BAR_MENU, updateEmailURL } from "../constants";
import { useHistory } from "react-router";
import { generateID } from "@digitallab/grid-common-components";

import {
  OwcButton,
  OwcList,
  OwcListItem,
  OwcPopover,
  OwcTab,
  OwcTabs
} from "@one/react";
import { checkRoleIsLaningPageAdmin } from "../utils/helpers/checkRoleIsLaningPageAdmin";

const AppBarDesktop = ({
  displayName,
  logoLetter,
  email,
  client,
  setSideMenu,
  activeMenuText,
  setActiveMenuText
}) => {
  const history = useHistory();
  const [isAdminPermission, setIsAdminPermission] = useState(false);
  const [userPopup, setUserPopup] = useState(false);
  const popoverElement = useRef(null);
  const infoInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const handleClose = () => {
    setUserPopup((prevState) => !prevState);
  };

  const handleChange = (e) => {
    let url = null;

    const tempSideMenu = TOP_BAR_MENU.find((x) => x.text === e.detail);
    if (tempSideMenu !== undefined && tempSideMenu.isDisabled === false) {
      setActiveMenuText(e.detail);
      const pathName = window.location.pathname;
      const updatedSideBarMenu = tempSideMenu.children.map((item) => {
        if (item.children.length > 0) {
          item.children.map((subMenuItem) => {
            if (
              url === null &&
              !subMenuItem.isDisabled &&
              pathName !== updateEmailURL
            ) {
              url = subMenuItem.url;
              subMenuItem.isActive = true;
            }
            return subMenuItem;
          });
        } else {
          if (url === null && !item.isDisabled && item.isActive) {
            url = item.url;
            item.isActive = true;
          }
        }
        return item;
      });
      setSideMenu(updatedSideBarMenu);
      if (!url && updatedSideBarMenu?.length > 0) {
        url = updatedSideBarMenu[0]?.url;
      }
      history.replace(url);
    }
  };
  const handleMenuChange = (e) => {
    history.push("/notificationsApp");
  };
  useEffect(() => {
    updatePermissionState();
    const pathName = window.location.pathname;
    updateMenuList(pathName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    history.listen((location) => {
      updateMenuList(location.pathname);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  const updateMenuList = (path) => {
    const pathName = path;
    const pathNameArray = pathName.split("/");
    const topMenuRow = getSelectedTopMenu(pathNameArray[1]);

    if (topMenuRow && topMenuRow.isDisabled === false) {
      setActiveMenuText(topMenuRow.text);
      const updatedSideBarMenu = topMenuRow.children.map((item) => {
        if (item.children.length > 0) {
          item.children.map((subMenuItem) => {
            if (pathName === updateEmailURL) {
              subMenuItem.isActive = false;
            } else {
              subMenuItem.isActive =
                subMenuItem.url === pathName && !subMenuItem.isDisabled;
            }
            return subMenuItem;
          });
        } else {
          item.isActive = item.url === pathName && !item.isDisabled;
        }
        return item;
      });
      setSideMenu(updatedSideBarMenu);
    } else {
      setActiveMenuText(null);
      setSideMenu([]);
    }
  };
  const getSelectedTopMenu = (urlSegment) => {
    const topMenuRow = TOP_BAR_MENU.find((x) => x.url === urlSegment);
    return topMenuRow !== undefined ? { ...topMenuRow } : null;
  };
  const lastMenuIndex = Object.keys(TOP_BAR_MENU).length - 1;
  const updatePermissionState = async () => {
    const isAvailable = await checkRoleIsLaningPageAdmin();
    setIsAdminPermission(isAvailable);
  };
  return (
    <>
      <OwcDoubleGlobalArea
        id={generateID.UUID(Module.HEADERS.name, "header", "double_area")}
        data-testid="app-bar-not-auth-main-page"
        style={{
          position: "relative",
          zIndex: 1000,
          filter: "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.4))"
        }}
      >
        <div
          slot="primary"
          style={{
            justifyContent: "space-between",
            borderBottom: "none",
            filter: "none"
          }}
          id={generateID.UUID(Module.HEADERS.name, "header", "div")}
        >
          <div
            style={{
              paddingLeft: "16px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <img
              src={GRIDIcon}
              alt="grid-logo"
              style={{ width: 24, height: 24, marginRight: 15 }}
              id={generateID.UUID(Module.HEADERS.gridIcon, "header", "img")}
            />
            <OwcTypography
              variant="subtitle1"
              id={generateID.UUID(Module.HEADERS.gridIconText, "header", "div")}
            >
              GRID
            </OwcTypography>
            <div
              className={styles.VerticalLine}
              id={generateID.UUID(Module.HEADERS.verticalLine, "header", "div")}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              className={styles.AppBarInfoUserInfo}
              data-testid="app-bar-user-info-user"
              button="true"
              hasFocus={userPopup}
              style={{ marginRight: 12 }}
              id={generateID.UUID(Module.HEADERS.userInfo, "header", "div")}
            >
              <OwcTypography
                variant="caption"
                style={{ marginRight: 10 }}
                id={generateID.UUID(
                  Module.HEADERS.displayName,
                  "header",
                  "div"
                )}
              >
                {displayName}
              </OwcTypography>
              <OwcIcon
                variant="default"
                name="user"
                id="popover-anchor-for-top-bar"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleClose();
                }}
              />
            </div>

            <AppSwitcher />

            <NotificationCounter />
            <OwcIconButton
              data-testid="App-bar-user-info-info"
              flat
              onClick={() => infoInNewTab(process.env.REACT_APP_INFO_URL)}
              icon="circle_info"
              style={{ marginRight: "12px" }}
              id={generateID.UUID(
                Module.HEADERS.circleInfo,
                "header",
                "button"
              )}
            />
            <OwcIcon
              type="legacy"
              style={{
                margin: "0 8px",
                color: "var(--one-color-interaction-default-brand-1)",
                width: "48px"
              }}
              name="roche_logo"
              id={generateID.UUID(Module.HEADERS.rocheLogo, "header", "img")}
            />
          </div>
        </div>

        <div
          slot="secondary"
          style={{ filter: "none" }}
          id={generateID.UUID(Module.HEADERS.name, "submenu", "div")}
        >
          <OwcTabs
            value={activeMenuText}
            onTabChange={handleChange}
            style={{
              width: "100%",
              display: "flex"
            }}
            key={generateID.UUID(Module.SUBMENU.name, "submenu", "tab")}
            id={generateID.UUID(Module.SUBMENU.name, "submenu", "tab")}
          >
            {TOP_BAR_MENU.map((item, index) => {
              let html = null;
              if (index === lastMenuIndex) {
                if (isAdminPermission) {
                  html = (
                    <OwcTab
                      value={item.text}
                      className={`${styles.AdminPanelRightAlignMenu} ${
                        item.isDisabled === true ? styles.disabledTab : ""
                      }`}
                      key={generateID.UUID(Module.SUBMENU.name, index, "tab")}
                      id={generateID.UUID(Module.SUBMENU.name, index, "tab")}
                    >
                      {typeof item.icon === "string" ? (
                        <OwcIcon
                          name={item.icon}
                          id={generateID.UUID(
                            Module.SUBMENU.name,
                            index,
                            "tab_img"
                          )}
                        />
                      ) : (
                        item.icon
                      )}

                      <span
                        id={generateID.UUID(
                          Module.SUBMENU.name,
                          index,
                          "tab_text"
                        )}
                      >
                        {item.text}
                      </span>
                    </OwcTab>
                  );
                }
              } else {
                html = (
                  <OwcTab
                    value={item.text}
                    className={`${
                      item.isDisabled === true ? styles.disabledTab : ""
                    }`}
                    key={generateID.UUID(Module.SUBMENU.name, index, "tab")}
                    id={generateID.UUID(Module.SUBMENU.name, index, "tab")}
                  >
                    {typeof item.icon === "string" ? (
                      <OwcIcon
                        name={item.icon}
                        id={generateID.UUID(
                          Module.SUBMENU.name,
                          index,
                          "tab_img"
                        )}
                      />
                    ) : (
                      item.icon
                    )}

                    <span
                      id={generateID.UUID(
                        Module.SUBMENU.name,
                        index,
                        "tab_text"
                      )}
                    >
                      {item.text}
                    </span>
                  </OwcTab>
                );
              }
              return html;
            })}
          </OwcTabs>
        </div>
      </OwcDoubleGlobalArea>

      <OwcPopover
        ref={popoverElement}
        anchor="popover-anchor-for-top-bar"
        id="popover-anchor-for-top-bar-popover"
        anchor-position="bottom-start"
        visible={userPopup}
        disable-portal={true}
        onClickOutside={() => {
          handleClose();
        }}
        style={{ width: 340 }}
      >
        <div
          className={styles.UserInfoSection}
          id={generateID.UUID(
            Module.SUBMENU.name,
            "app_desktop",
            "popover_div"
          )}
        >
          <span
            className={styles.LogoLetter}
            id={generateID.UUID(
              Module.SUBMENU.name,
              "app_desktop",
              "popover_logoletter"
            )}
          >
            {logoLetter}
          </span>
          <OwcTypography
            variant="subtitle1"
            id={generateID.UUID(
              Module.SUBMENU.name,
              "app_desktop",
              "popover_subtitle"
            )}
          >
            {displayName}
          </OwcTypography>
          <OwcTypography
            variant="body2"
            id={generateID.UUID(
              Module.SUBMENU.name,
              "app_desktop",
              "popover_body"
            )}
          >
            {email}
          </OwcTypography>
        </div>

        <OwcList
          data-testid="popover-user-menu-footer-section"
          onSelectChange={handleMenuChange}
          style={{
            borderTop:
              "thin solid var(--one-color-interaction-default-neutral-2)"
          }}
          id={generateID.UUID(
            Module.SUBMENU.name,
            "app_desktop",
            "popover_list"
          )}
        >
          <OwcListItem
            icon="notification"
            key="notification_key"
            iconType="outlined"
            id={generateID.UUID(
              Module.SUBMENU.name,
              "app_desktop",
              "popover_item"
            )}
          >
            <OwcTypography
              variant="subtitle1"
              id={generateID.UUID(
                Module.SUBMENU.name,
                "app_desktop",
                "popover_text"
              )}
            >
              Notification settings
            </OwcTypography>
          </OwcListItem>
        </OwcList>
        <SwitchSite />
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            margin: "16px"
          }}
          id={generateID.UUID(
            Module.SUBMENU.name,
            "app_desktop",
            "popover_switch"
          )}
        >
          <OwcButton
            data-testid="popover-user-menu-footer-section-logout"
            onClick={() => {
              handleClose();
              signOut(client);
            }}
            variant="secondary"
            id={generateID.UUID(
              Module.SUBMENU.name,
              "app_desktop",
              "popover_logout"
            )}
          >
            Logout
          </OwcButton>
        </div>
      </OwcPopover>
    </>
  );
};

export default AppBarDesktop;
