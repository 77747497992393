import React from "react";
import { connect } from "react-redux";
import { ApplicationAllGroup } from "../applications/ApplicationGroup";
import { filterAppsByQuerySelector, getSearchQuery } from "../../selectors";
import styled from "styled-components";
import ApplicationSlimCard from "../applications/ApplicationSlimCard";

const SearchViewStyled = styled.div`
  & h6 {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const LoadingStyled = styled.div`
  margin-right: 15px;
  display: flex;
  align-items: center;
`;

export const SearchApplicationView = ({
  selectedApps,
  applicationSets,
  query
}) => {
  return (
    <SearchViewStyled>
      <ApplicationAllGroup
        name={`Search results for ${query}`}
        Component={ApplicationSlimCard}
        applications={selectedApps}
        applicationSets={applicationSets}
        searchFlag={true}
      />
    </SearchViewStyled>
  );
};

const mapStateToProps = (store) => ({
  selectedApps: filterAppsByQuerySelector(store),
  applicationSets: store.user.applicationSets,
  query: getSearchQuery(store)
});

export default connect(mapStateToProps)(SearchApplicationView);
