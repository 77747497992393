import React, { useContext, useEffect, useRef, useState } from "react";
import { isEqual } from "lodash";
import { SiteContext } from "./context";
import { UNKNOWN } from "./SiteWrapper";
import { OwcListItem, OwcSelectDropdown, OwcTypography } from "@one/react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE_SITE } from "../../gql/landingapi/mutations";

const SiteControl = ({ client, sites, onChange, id, email }) => {
  const selectDropdownElement = useRef(null);
  const { selected, currentSite, dispatchAction } = useContext(SiteContext);
  const [selectText, setSelectText] = useState("");
  const [isSaved, setIsSaved] = useState(false);

  const handleChange = async (evt) => {
    const index = evt.detail[0];
    const _selected =
      sites[
        selected.siteName !== UNKNOWN ? index : index === 0 ? 0 : index - 1
      ];
    if (!_selected) {
      return;
    }
    if (selected.siteName !== UNKNOWN) {
      await client.mutate({
        mutation: UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE_SITE,
        variables: { id, email, site: _selected.siteName }
      });
    }
    await onChange({ site: _selected.siteName });
    dispatchAction({ type: "select", payload: _selected });
  };

  useEffect(() => {
    setIsSaved(() => {
      return currentSite?.siteName === UNKNOWN
        ? true
        : isEqual(currentSite, selected);
    });
    setSelectText(() => {
      return currentSite?.siteName === UNKNOWN
        ? true
        : isEqual(currentSite, selected)
        ? ""
        : "Please logout to apply site change!";
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  return (
    <>
      <OwcSelectDropdown
        ref={selectDropdownElement}
        id="switch-site-select"
        data-testid="switch-site-select-input"
        value={[selected.siteName]}
        onSelectChange={handleChange}
        label="Site"
        style={{ width: "100%" }}
        error={!isSaved}
      >
        {selected.siteName === UNKNOWN && (
          <OwcListItem
            key={UNKNOWN}
            value={UNKNOWN}
            data-testid="switch-site-option-unknown"
          >
            Unknown site
          </OwcListItem>
        )}
        {sites?.map((site) => (
          <OwcListItem
            key={site.siteName}
            value={site.siteName}
            // selected={selected.siteName === }
          >
            {site.siteName} ({site.timezone})
          </OwcListItem>
        ))}
      </OwcSelectDropdown>
      <OwcTypography
        variant="body2"
        style={{
          display: "flex",
          justifyContent: "right",
          paddingRight: 15,
          paddingTop: 5,
          color: "var(--one-color-accent-secondary-3)"
        }}
      >
        {selectText}
      </OwcTypography>
    </>
  );
};

const mapStateToProps = (store) => ({
  id: store?.user?.id,
  email: store?.user?.email
});

export default compose(connect(mapStateToProps, {}), withApollo)(SiteControl);
