import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { selectGroupUserInfo as selectGroupUserInfoActions } from "../features/landingPageUser/redux/actions";
import { GridAutocomplete } from "@digitallab/grid-common-components";
import styled from "styled-components";
import { GridAutoCompleteStyles } from "../components/shared/GridAutoCompleteStyles";
import { OwcTypography } from "@one/react";
import { useContext } from "react";
import { SearchToolContext } from "../features/searchTools/context";

export const ApplicationStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const AllApplicationMainViewMenu = ({
  applicationGroups = [],
  selectGroupUserInfo,
  typeSelected,
  selected = "",
  onClick,
  type = "groups",
  name,
  setActiveFilter,
  searchQuery = "",
  displayName = ""
}) => {
  const acceptedGroup = ["allApplications", "groups"];
  const { setSearchAppQuery } = useContext(SearchToolContext);
  const default_group = {
    id: "ALL_TOOLS",
    name: "All"
  };
  const [isGroupItem, setGroupItem] = useState([]);
  let allItemList = ["All"];

  useEffect(() => {
    const groupItemList = applicationGroups.map((group) => {
      return group?.name;
    });
    const groupItemListSorted = groupItemList?.sort(function (a, b) {
      return a?.toLowerCase().localeCompare(b?.toLowerCase());
    });
    setGroupItem(groupItemListSorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  allItemList = allItemList.concat(isGroupItem);

  return (
    <>
      <ApplicationStyled>
        {acceptedGroup.includes(typeSelected) && (
          <>
            {displayName && (
              <OwcTypography variant="button" data-testid="section-name">
                {displayName}
              </OwcTypography>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              <GridAutocomplete
                size="small"
                sx={GridAutoCompleteStyles?.gridAutoCompleteLableShrink}
                optionsList={allItemList}
                label="Category"
                key={name}
                disableClearable
                value={
                  name === "Applications" || name === "All tools" ? "All" : name
                }
                onAutoCompleteChange={(selectedValue) => {
                  const group = applicationGroups.find(
                    (item) => item.name === selectedValue
                  );
                  setSearchAppQuery("");
                  if (selectedValue === "All") {
                    setActiveFilter("All");
                    selectGroupUserInfo({ ...default_group, type });
                  } else {
                    setActiveFilter(selectedValue);
                    selectGroupUserInfo({ ...group, type });
                  }
                }}
                style={{ width: 282 }}
              />
            </div>
          </>
        )}
      </ApplicationStyled>
    </>
  );
};
const selectGroups = (groups) =>
  groups?.map((group) => ({
    id: group.id,
    name: group.name,
    icon: group.icon,
    iconPath: group.iconPath
  }));
const mapStateToProps = (store) => ({
  applicationGroups: selectGroups(store.user.allGroups),
  selected: store.user.selected?.id,
  typeSelected: store.user.selected?.type,
  name: store?.user?.selected?.name,
  searchQuery: store.searchTools.searchQuery
});
export default connect(mapStateToProps, {
  selectGroupUserInfo: selectGroupUserInfoActions
})(AllApplicationMainViewMenu);
