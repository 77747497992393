import React, { useState } from "react";
import { OwcEditableDropdown, OwcListItem } from "@one/react";
import { PREDEFINED_FILTER_NAME, Module } from "../../constants";
import { generateID } from "@digitallab/grid-common-components";

const { preDefinedFilter } = Module.PAGE.MYLAB;

const MyLabFilterDropDown = ({
  labelValue,
  style,
  list,
  selected = null,
  disabled,
  label,
  onChange
}) => {
  const [isFilterLabel, setFilterLabel] = useState(true);
  const [isPlaceValue, setPlaceValue] = useState(false);

  const panelOpenHandler = () => {
    setFilterLabel(false);
    setPlaceValue(false);
  };
  const panelCloseHandler = () => {
    selected !== null ? setFilterLabel(false) : setFilterLabel(true);
    setPlaceValue(true);
  };

  return (
    <div>
      <OwcEditableDropdown
        style={style}
        label={
          labelValue || disabled
            ? label
            : selected !== null || !isFilterLabel
            ? PREDEFINED_FILTER_NAME
            : label
        }
        placeholder={
          labelValue || disabled
            ? ""
            : isPlaceValue
            ? PREDEFINED_FILTER_NAME
            : label
        }
        onPanelOpen={panelOpenHandler}
        onPanelClose={panelCloseHandler}
        value={disabled ? null : selected}
        onSelectChange={(e) => {
          onChange(e.detail);
        }}
        disabled={disabled}
      >
        {list?.map((item, index) => {
          const option = item;
          return (
            <>
              <OwcListItem
                value={option}
                key={option}
                data-testid={`${label}-${option}`}
                id={generateID.UUID(
                  preDefinedFilter,
                  `MyLabFilterDropDown_${label}-${option}`,
                  "item"
                )}
              >
                {option}
              </OwcListItem>
            </>
          );
        })}
      </OwcEditableDropdown>
    </div>
  );
};

export default MyLabFilterDropDown;
