const DefaultFolder = (props) => (
  <svg
    width="40"
    height="33"
    viewBox="0 0 40 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.885 1.21875H3C1.89543 1.21875 1 2.11418 1 3.21875V29.2187C1 30.3233 1.89543 31.2188 3 31.2188H37C38.1046 31.2188 39 30.3233 39 29.2188V6.96875C39 5.86418 38.1046 4.96875 37 4.96875H19.2539C18.7182 4.96875 18.205 4.75392 17.8291 4.37237L15.3098 1.81513C14.9339 1.43358 14.4206 1.21875 13.885 1.21875Z"
      stroke="#0B41CD"
      stroke-width="2"
    />
    <path
      d="M15.4473 13.8789C15.4473 11.447 17.5054 9.47461 20.043 9.47461C22.5807 9.47461 24.6388 11.447 24.6388 13.8789C24.6388 15.7938 22.5547 17.4616 21.4417 17.9365C20.7024 18.2946 20.043 19.028 20.043 19.9529V21.7299"
      stroke="#1482FA"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="20.0419"
      cy="26.3251"
      r="0.510638"
      fill="#0B41CD"
      stroke="#1482FA"
      stroke-width="1.2"
    />
  </svg>
);
export default DefaultFolder;
