import React, { useRef } from "react";
import styled from "styled-components";
import get from "lodash/get";
import ApplicationFavouriteButton from "./ApplicationFavouriteButton";
import { connect } from "react-redux";
import { compose } from "redux";
import { loadUserInfo as loadUserInfoActions } from "../landingPageUser/redux/actions";
import { withApollo } from "react-apollo";
import { v4 } from "uuid";
import { UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE_RECENT_APP_USAGE } from "../../gql/landingapi/mutations";
import { resolveSSOLink } from "../../utils/ssoLinkStrategyResolver";
import { useState } from "react";
import DefaultFolder from "../../icons/DefaultFolder";
import { Tooltip } from "@mui/material";
import styles from "./ApplicationSlimCard.module.scss";
import {
  OwcCard,
  OwcIcon,
  OwcListItem,
  OwcMenu,
  OwcTypography
} from "@one/react";
import ReactDOM from "react-dom";
import { ASSETS_BASE_URL } from "../../constants";
import { snakeCase } from "lodash";

const IconPreviewStyled = styled.img`
  box-sizing: border-box;
  object-fit: cover;
  display: block;
  width: 40px;
  height: 40px;
`;

const onOpenAppFactory = ({
  entryPointSelected,
  recentApplicationUsage,
  loadUserInfo
}) => {
  return async (client, launchUrl, application, id, email) => {
    if (launchUrl === "#" || !Array.isArray(application.entryPoints)) {
      return;
    }
    const entryPoints = application?.entryPoints;
    const appEntryPointID =
      get(
        entryPoints.find((x) => x.id === entryPointSelected),
        "id"
      ) || entryPoints[0]?.id;
    const newRecentApplicationUsage = [
      {
        id: v4(),
        lastUsed: new Date().toISOString(),
        applicationRef: {
          applicationID: application.id,
          applicationEntryPointID: appEntryPointID
        }
      },
      ...recentApplicationUsage
        .filter(
          (app) =>
            app.applicationRef.applicationID !== application.id ||
            app.applicationRef.applicationEntryPointID !== appEntryPointID
        )
        .slice(0, 9)
        .map((appUsage) => ({
          id: appUsage.id,
          lastUsed: appUsage.lastUsed,
          applicationRef: {
            applicationID: appUsage.applicationRef.applicationID,
            applicationEntryPointID:
              appUsage.applicationRef.applicationEntryPointID
          }
        }))
    ];

    loadUserInfo({ recentApplicationUsage: newRecentApplicationUsage });
    const updatedDigitalLabLandingPageUserProfile = await client.mutate({
      mutation: UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE_RECENT_APP_USAGE,
      variables: {
        id,
        email,
        recentApplicationUsage: newRecentApplicationUsage
      }
    });
    const _recentApplicationUsage = get(
      updatedDigitalLabLandingPageUserProfile,
      "data.updateDigitalLabLandingPageUserProfile.recentApplicationUsage"
    );
    loadUserInfo({ recentApplicationUsage: _recentApplicationUsage });
    window.open(launchUrl);
  };
};

const ApplicationSlimCard = ({
  application,
  client,
  recentApplicationUsage,
  id,
  email,
  loadUserInfo
}) => {
  const entryPoints = Array.isArray(application?.entryPoints)
    ? application?.entryPoints
    : [];
  const [menuVisibility, setMenuVisibility] = useState(false);
  const isAdditionalEntry =
    (Array.isArray(entryPoints) ? entryPoints : []).length > 1;
  const entryPointSelected =
    application?.entryPointSelected || application?.entryPoints[0]?.id;
  const onOpenApp = onOpenAppFactory({
    entryPointSelected,
    recentApplicationUsage,
    loadUserInfo
  });
  const cardElement = useRef(null);

  return (
    <Tooltip
      title={
        <div style={{ maxWidth: 180, maxHeight: 130 }}>
          <OwcTypography
            style={{
              fontSize: 11,
              color: " var(--one-color-cobas-white)"
            }}
            variant="body1"
            className={styles.applicationName}
          >
            {application?.name}
          </OwcTypography>
          <OwcTypography
            variant="body1"
            style={{
              fontSize: 11,
              color: " var(--one-color-cobas-white)"
            }}
          >
            {application?.description ? application?.description : ""}
          </OwcTypography>
        </div>
      }
      placement="bottom-end"
    >
      <OwcCard
        ref={cardElement}
        className={styles.cardApplicationQuickAccess}
        isAdditionalEntry={isAdditionalEntry}
        data-testid="slim-card"
      >
        <div className={styles.headerVertAndFavIcon} slot="header">
          <ApplicationFavouriteButton
            entryPointSelected={entryPointSelected}
            application={application}
          />
          {isAdditionalEntry && (
            <>
              <OwcIcon
                className="one-icons-outlined"
                onClick={(e) => {
                  setMenuVisibility(!menuVisibility);
                }}
                type="one-icons-legacy"
                name="more_vertical"
                style={{
                  fontSize: 20
                }}
                id={application?.name}
              />
              {ReactDOM.createPortal(
                <OwcMenu
                  anchor={application?.name}
                  visible={menuVisibility}
                  onClickOutside={(e) => {
                    setMenuVisibility(false);
                  }}
                  disablePortal
                >
                  {application?.entryPoints.map((x, i) => {
                    if (i === 0) {
                      return "";
                    }
                    return (
                      <OwcListItem
                        noBorder
                        key={`${snakeCase(x.name)}_${i}`}
                        onClick={() =>
                          onOpenApp(
                            client,
                            resolveSSOLink(application?.type, x?.url || "#"),
                            application,
                            id,
                            email
                          )
                        }
                      >
                        {x.name}
                      </OwcListItem>
                    );
                  })}
                </OwcMenu>,
                document.body
              )}
            </>
          )}
        </div>
        <div
          className={styles.contentIcon}
          onClick={() => {
            onOpenApp(
              client,
              resolveSSOLink(application?.type, entryPoints[0]?.url || "#"),
              application,
              id,
              email
            );
          }}
        >
          {application?.iconPath ? (
            <IconPreviewStyled
              src={`${ASSETS_BASE_URL}${application?.iconPath}`}
            />
          ) : (
            <DefaultFolder />
          )}
        </div>
        <div
          className={styles.footerApplicationName}
          slot="footer"
          onClick={() => {
            onOpenApp(
              client,
              resolveSSOLink(application?.type, entryPoints[0]?.url || "#"),
              application,
              id,
              email
            );
          }}
        >
          <OwcTypography>{application?.name}</OwcTypography>
        </div>
      </OwcCard>

      {/* <ApplicationSlimCardStyled
        isAdditionalEntry={isAdditionalEntry}
        data-testid="slim-card"
      >
        <div
          className="start"
          onClick={() => {
            onOpenApp(
              client,
              resolveSSOLink(application?.type, entryPoints[0]?.url || "#"),
              application,
              id,
              email
            );
          }}
        >
          {application?.iconPath ? (
            <IconPreviewStyled
              src={`${CLOUD_FRONT_PATH}${
                application?.iconPath
              }?dummy=${date.getTime()}`}
            />
          ) : (
            <DefaultFolder />
          )}
        </div>
        <div
          className="middle"
          onClick={() => {
            onOpenApp(
              client,
              resolveSSOLink(application?.type, entryPoints[0]?.url || "#"),
              application,
              id,
              email
            );
          }}
        >
          <AppTtitle>{application?.name}</AppTtitle>
        </div>
        <div className="end">
          <ApplicationFavouriteButton
            entryPointSelected={entryPointSelected}
            application={application}
          />
          {isAdditionalEntry && (
            <div>
              <OwcIcon
                className="one-icons-outlined"
                onClick={(e) => {
                  setMenuVisibility(!menuVisibility);
                }}
                type="one-icons-legacy"
                name="more_vertical"
                style={{
                  fontSize: 20
                }}
                id={application?.name}
              />
              {ReactDOM.createPortal(
                <OwcMenu
                  anchor={application?.name}
                  visible={menuVisibility}
                  onClickOutside={(e) => {
                    setMenuVisibility(false);
                  }}
                  disablePortal
                >
                  {application?.entryPoints.map((x, i) => {
                    if (i === 0) {
                      return "";
                    }
                    return (
                      <OwcListItem
                        noBorder
                        onClick={() =>
                          onOpenApp(
                            client,
                            resolveSSOLink(application?.type, x?.url || "#"),
                            application,
                            id,
                            email
                          )
                        }
                      >
                        {x.name}
                      </OwcListItem>
                    );
                  })}
                </OwcMenu>,
                document.body
              )}
            </div>
          )}
        </div>
      </ApplicationSlimCardStyled> */}
    </Tooltip>
  );
};

const mapStateToProps = (state) => ({
  recentApplicationUsage: state.user.recentApplicationUsage || [],
  id: state.user.id,
  email: state.user.email
});

export default compose(
  connect(mapStateToProps, { loadUserInfo: loadUserInfoActions }),
  withApollo
)(ApplicationSlimCard);
