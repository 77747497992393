import { useState } from "react";
import { AllWidgetsContext } from "./context";

const AllWidgetsWrapper = ({ children }) => {
  const [widgets, setWidgets] = useState(null);
  return (
    <AllWidgetsContext.Provider value={{ widgets, setWidgets }}>
      {children}
    </AllWidgetsContext.Provider>
  );
};

export default AllWidgetsWrapper;
