import { DLabTopBanner } from "@digitallab/grid-common-components";
import { BANNER_COLORS, NOTIFICATIONS_BANNER } from "../../constants";
import { compose, withApollo } from "react-apollo";
import { connect } from "react-redux";
import {
  updateNotificationBanner,
  updateTopBannerFlag
} from "../landingPageUser/redux/actions";
const TopBannerSection = ({
  UpdateNotificationBanner,
  UpdateTopBannerFlag,
  NotificationBanner,
  TopBannerFlag
}) => {
  const TopBannerDatas = NotificationBanner;
  let mainBGColor = "";
  let headingBGColor = "";
  let iconText = "";
  let iconType = "";

  if (TopBannerDatas?.enabled === true) {
    const findColor = Object.keys(NOTIFICATIONS_BANNER).filter(
      (key) => NOTIFICATIONS_BANNER[key] === TopBannerDatas?.color
    );
    headingBGColor = BANNER_COLORS[findColor]?.Heading;
    mainBGColor = BANNER_COLORS[findColor]?.TextToDisplay;
    iconText = BANNER_COLORS[findColor]?.Icon;
    iconType = BANNER_COLORS[findColor]?.IconType;
  }
  const closeCallback = (enabledFlag) => {
    let updatedValue = TopBannerDatas;
    updatedValue.enabled = !updatedValue.enabled;
    UpdateNotificationBanner({ ...updatedValue });
    setTimeout(() => {
      UpdateTopBannerFlag(false);
      updatedValue.enabled = !updatedValue.enabled;
      UpdateNotificationBanner({ ...updatedValue });
    });
  };
  return (
    <DLabTopBanner
      heading={TopBannerDatas?.heading.toUpperCase()}
      displayText={TopBannerDatas?.displayText}
      mainBGColor={mainBGColor}
      headingBGColor={headingBGColor}
      enabled={TopBannerFlag ? TopBannerDatas?.enabled : false}
      infoURl={TopBannerDatas?.infoURl}
      closeCallback={closeCallback}
      icon={iconText}
      iconType={iconType}
    />
  );
};

const mapStateToProps = (state) => ({
  NotificationBanner: state.user.NotificationBanner,
  TopBannerFlag: state.user.topBannerFlag
});
export default compose(
  connect(mapStateToProps, {
    UpdateNotificationBanner: updateNotificationBanner,
    UpdateTopBannerFlag: updateTopBannerFlag
  }),
  withApollo
)(TopBannerSection);
