import { useReducer } from "react";

export const initialState = {
  loading: true,
  error: null,
  retry: 0
};

export function reducer(
  state = initialState,
  action = { type: "", payload: "" }
) {
  switch (action.type) {
    case "loading":
      return { ...state, error: null, loading: action?.payload };
    case "error":
      return { ...state, error: action?.payload || {}, loading: false };
    case "retry":
      return { ...initialState, retry: state?.retry + 1 };
    default:
      return state;
  }
}

export const useLoadState = () => {
  const [state, dispatchAction] = useReducer(reducer, initialState);
  return {
    ...state,
    dispatchAction
  };
};
