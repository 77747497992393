import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useContext } from "react";
import { Module } from "../../../constants";
import { FiltersContext } from "./FiltersContext/context";
import { generateID } from "@digitallab/grid-common-components";

const  {notificationName} = Module.PAGE.NOTIFICATION;

const GrouBySeverityCheckbox = () => {
  const { groupBySeverity, dispatchFiltersAction } = useContext(FiltersContext);
  return (
    <FormGroup 
    id={generateID.UUID(notificationName, `groupServerity`, "form_group")}
    data-testid="group-by-severity">
      <FormControlLabel
       id={generateID.UUID(notificationName, `groupServerity`, "form_control_label")}
        data-testid="group-by-severity-button"
        control={
          <Checkbox
          id={generateID.UUID(notificationName, `groupServerity`, "checkbox")}
            inputProps={{
              "data-testid": `group-by-severity-button-checkbox-input`
            }}
            data-testid="group-by-severity-button-checkbox"
            color="primary"
            checked={groupBySeverity}
            onChange={() => {
              dispatchFiltersAction({
                type: "groupBySeverity"
              });
            }}
            name="groupBySeverity"
          />
        }
        label={
          <>
            Group by <br /> severity
          </>
        }
      />
    </FormGroup>
  );
};
export default GrouBySeverityCheckbox;
