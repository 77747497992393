import { useContext } from "react";
import styled from "styled-components";
import ActionOfNotify from "../ActionOfNotify";
import DateOfNotify from "../DateOfNotify";
import SeverityNotify from "../fullViewOfNotification/SeverityNotify";
import NotificationBackButton from "../NotificationBackButton";
import ItemOfShortViewNotify from "../shortViewOfNotificaton/ItemOfShortViewNotify";
import { FiltersContext } from "../fullViewOfNotification/FiltersContext/context";
import {
  Headerbox,
  PageTitle
} from "../fullViewOfNotification/NotificationsHeader";
import { CircularProgress } from "@mui/material";
import { OwcList } from "@one/react";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";

const {notificationName} = Module.PAGE.NOTIFICATION;

const MobileViewBox = styled.div`
  padding: 0 20px;
`;

const NotificationsBox = styled.div`
  background: var(--one-color-cobas-white);
`;
const NotificationRow = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 16px;

  & > :first-child {
    flex-grow: 1;
  }
`;

const NotificationTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--one-color-gray-800);
  flex-grow: 1;
`;

const NotificationDescription = styled.p`
  margin: 0;
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;

  & + & {
    margin-top: 8px;
  }
`;
export const NoData = styled.p`
  margin-top: 20px;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
`;

const NotificationLoadingStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;
`;

const MobileViewOfNotificationsPage = ({ id, notifications }) => {
  const selectedNotification = notifications.find(
    (notification) => notification.id === id
  );
  const { fetching } = useContext(FiltersContext);

  let content = (
    <>
      {notifications?.length > 0 && (
        <NotificationsBox 
        id={generateID.UUID(notificationName, `mobile`, "box")}
        data-testid="mobile-notification-box" >
          <OwcList 
          id={generateID.UUID(notificationName, `mobile`, "list")}
          data-testid="mobile-notification-box-list">
            {notifications.map((notification, index) => (
              <ItemOfShortViewNotify
                notification={notification}
                mobileView={true}
                lastElement={false}
                id={generateID.UUID(notificationName, `mobile_${index}`, "item")}
              />
            ))}
          </OwcList>
        </NotificationsBox>
      )}
      {fetching && (
        <NotificationLoadingStyled 
        id={generateID.UUID(notificationName, `mobile`, "style")}
        data-testid="mobile-notification-fetchmore">
          <CircularProgress size={30} id={generateID.UUID(notificationName, `mobile`, "circle")}/>
        </NotificationLoadingStyled>
      )}
      {notifications?.length === 0 && (
        <NoData 
        id={generateID.UUID(notificationName, `mobile`, "no_data")}
        data-testid="no-data-mobile-view">No data</NoData>
      )}
    </>
  );

  let goTo = "";
  let nameOfTitle = "Notifications";

  if (selectedNotification) {
    content = (
      <MobileViewBox 
      id={generateID.UUID(notificationName, `mobile`, "view_box")}
      data-testid="mobile-notification-box-more-details">
        <NotificationRow 
        id={generateID.UUID(notificationName, `mobile`, "row")}
        data-testid="mobile-notification-more-details-header-row">
          <NotificationTitle 
          id={generateID.UUID(notificationName, `mobile`, "title")}
          data-testid="mobile-notification-more-details-title">
            {selectedNotification.source}
          </NotificationTitle>
          <ActionOfNotify
            link={selectedNotification.link}
            short
            id={selectedNotification.id}
          />
        </NotificationRow>
        <NotificationRow 
        id={generateID.UUID(notificationName, `mobile`, "row_body")}
        data-testid="mobile-notification-more-details-body-row">
          <div>
            <SeverityNotify 
            id={generateID.UUID(notificationName, `mobile`, "serverity")}
            severity={selectedNotification.severity} />
          </div>
          <br />
          <DateOfNotify
          id={generateID.UUID(notificationName, `mobile`, "Date")}
          date={selectedNotification.time} />
        </NotificationRow>
        <NotificationDescription 
        id={generateID.UUID(notificationName, `mobile`, "description")}
        data-testid="mobile-notification-more-details-full-desription-title">
          Description
        </NotificationDescription>
        <NotificationDescription 
        id={generateID.UUID(notificationName, `mobile`, "content")}
        data-testid="mobile-notification-more-details-full-desription-content">
          {selectedNotification.details}
        </NotificationDescription>
      </MobileViewBox>
    );
    goTo = "all";
    nameOfTitle = "Notification";
  }

  return (
    <>
      <MobileViewBox
      id={generateID.UUID(notificationName, `mobile`, "main_box")}
      data-testid="mobile-view-box">
        <Headerbox 
        id={generateID.UUID(notificationName, `mobile`, "header_box")}
        data-testid="mobile-header-box">
          <PageTitle 
          id={generateID.UUID(notificationName, `mobile`, "box_title")}
          data-testid="mobile-page-title">
            <NotificationBackButton 
            id={generateID.UUID(notificationName, `mobile`, "button_goto")}
            goTo={goTo} />
            {nameOfTitle}
          </PageTitle>
        </Headerbox>
      </MobileViewBox>
      {content}
    </>
  );
};

export default MobileViewOfNotificationsPage;
