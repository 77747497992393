import { createSelector } from "reselect";
import { ALL_TOOLS } from "../constants";

export const getAllApps = (state) => state.user.allApps;
export const getSearchQuery = (state) => state.searchTools.searchQuery;
export const getSearchQueryReset = (state) => state.searchTools.reset;

export const selectFavourites = (allApps, applicationSets) => {
  const setFavs = Array.isArray(applicationSets)
    ? applicationSets.filter((app) => app.name === "favourites")
    : [];
  if (setFavs.length === 0) {
    return [];
  }
  const favs = Array.isArray(setFavs[0]?.applicationReferences)
    ? setFavs[0]?.applicationReferences
    : [];
  return favs
    .map((f) => ({
      ...allApps.find((a) => a.id === f.applicationID),
      fav: { ...f },
      entryPointSelected: f?.applicationEntryPointID
    }))
    .filter((v) => v.id);
};

export const selectCustomGroup = (allApps, applicationSets, id) => {
  const setCustGroup = Array.isArray(applicationSets)
    ? applicationSets.filter((app) => app.id === id)
    : [];
  if (setCustGroup.length === 0) {
    return [];
  }
  const custGroup = Array.isArray(setCustGroup[0]?.applicationReferences)
    ? setCustGroup[0]?.applicationReferences
    : [];
  return custGroup
    .map((f) => ({
      ...allApps.find((a) => a.id === f.applicationID),
      entryPointSelected: f?.applicationEntryPointID
    }))
    .filter((v) => v.id);
};

export const selectRecentlyUsed = (allApps, recentlyUsed, applicationSets) => {
  if (recentlyUsed.length === 0) {
    return [];
  }
  const favApps = selectFavourites(allApps, applicationSets);
  return recentlyUsed
    .map((recentUse) => ({
      ...allApps.find(
        (app) => app.id === recentUse.applicationRef.applicationID
      ),
      recentUsageRef: recentUse.applicationRef
    }))
    .filter((v) => v.id)
    .map((app) => ({
      ...app,
      fav: favApps.find((f) => f.id === app.id)?.fav,
      entryPointSelected: app.recentUsageRef.applicationEntryPointID
    }));
};
export const selectAppsInGroup = (apps, groupId) => {
  const sortedApps = apps.sort((a, b) => {
    if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
      return 1;
    } else {
      if (b.name?.toLowerCase() > a.name?.toLowerCase()) {
        return -1;
      } else {
        return 0;
      }
    }
  });

  return groupId && groupId?.trim() !== ALL_TOOLS
    ? sortedApps.filter((app) => app.applicationGroupIDs.includes(groupId))
    : sortedApps;
};

export const selectAllApplicationSets = (allApps, applicationSets = []) => {
  if (applicationSets.length === 0) {
    return [];
  }
  const allCustomGroupSets = applicationSets.filter(
    (set) => set.name !== "favourites"
  );

  return allCustomGroupSets.map((set) => {
    const customGroup = {
      id: set.id,
      name: set.name,
      appsWithEntryPoints: []
    };

    set.applicationReferences.forEach((ref) => {
      const application = allApps.find((app) => app.id === ref.applicationID);
      if (application) {
        const appWithEntryPoints = customGroup.appsWithEntryPoints.find(
          (theAppWithEntryPoints) =>
            theAppWithEntryPoints.application === application
        );
        if (appWithEntryPoints) {
          appWithEntryPoints.entryPoints.push(ref.applicationEntryPointID);
        } else {
          customGroup.appsWithEntryPoints.push({
            application,
            entryPoints: [ref.applicationEntryPointID]
          });
        }
      }
    });

    return customGroup;
  });
};

export const filterAppsByQuery = (allApps, searchQuery) => {
  if (!searchQuery) {
    return [];
  }
  const _searchQuery = searchQuery?.toLowerCase() || "";
  return allApps.filter(
    (app) =>
      app.name?.toLowerCase().includes(_searchQuery) ||
      app.description?.toLowerCase().includes(_searchQuery)
  );
};

export const filterAppsByQuerySelector = createSelector(
  [getAllApps, getSearchQuery],
  filterAppsByQuery
);
export const selectAllowedRolesFromApps = (apps) => {
  const allowedRoles = [...apps?.map((app) => app.allowedRoles)].flat();
  const roles = [];
  const discintRoles = [];
  const l = allowedRoles.length;
  let i;

  for (i = 0; i < l; i++) {
    if (roles[allowedRoles[i]]) {
      continue;
    }
    roles[allowedRoles[i]] = true;
    discintRoles.push(allowedRoles[i]);
  }
  return discintRoles;
};
