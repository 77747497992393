import { Auth as SimulatedAuth } from "@aws-amplify/auth";
import AuthenticatedUser from "./mocks/authenticatedUser.json";

export const getSimulatedAuth = () => {
  SimulatedAuth.currentAuthenticatedUser = async () => AuthenticatedUser;

  SimulatedAuth.currentSession = async () => ({
    getAccessToken: () => ({
      getJwtToken: () =>
        AuthenticatedUser.signInUserSession.accessToken.jwtToken
    })
  });
  return SimulatedAuth;
};
