import { OwcIcon } from "@one/react";
import React from "react";
import {
  AppRow,
  InputCustomGroupName,
  ModalInput
} from "./customGroupModalDialogContentStyleds";

const getHelperText = (dirty, isNameUnique, isNameValid, customGroupForm) => {
  if (dirty) {
    if (!isNameUnique) {
      return `Custom group named '${customGroupForm.name}' already exists`;
    } else if (!isNameValid) {
      return `Custom group name must have at least 1 character and can only contain alphanumeric characters, space, and _ - :`;
    } else {
      return undefined;
    }
  }
};

const CustomGroupModalDialogContentInputsRow = ({
  isMobile,
  customGroupForm,
  dirty,
  hasError,
  updateCustomGroupNameAndSetDirty,
  isNameUnique,
  isNameValid,
  searchText,
  searchApps
}) => {
  return (
    <AppRow>
      <InputCustomGroupName>
        <ModalInput
          maxLength="30"
          data-testid="custom-group-modal-name-input"
          isMobile={isMobile}
          id="standard-secondary"
          label="Enter group name"
          color="primary"
          variant="filled"
          size="medium"
          value={customGroupForm.name}
          onInputKeyDown={updateCustomGroupNameAndSetDirty}
          error={dirty && hasError}
          style={{ width: "100%" }}
          FormHelperTextProps={{
            "data-testid": `custom-group-modal-input-name-helper-text`
          }}
        >
          <span slot="error-text">
            {getHelperText(dirty, isNameUnique, isNameValid, customGroupForm)}
          </span>
        </ModalInput>
      </InputCustomGroupName>
      <InputCustomGroupName>
        <ModalInput
          data-testid="custom-group-modal-search-input"
          id="standard-secondary"
          label="Search"
          variant="filled"
          size="medium"
          value={searchText}
          style={{ width: "100%" }}
          onInputKeyDown={(event) => searchApps(event.target.value)}
        >
          <OwcIcon type="outlined" slot="prefix" name="search" />
          {searchText && (
            <OwcIcon
              type="filled"
              slot="suffix"
              name="circle_clear"
              data-testid="clear-search-button"
              onClick={() => searchApps("")}
              className="pointer"
            />
          )}
        </ModalInput>
      </InputCustomGroupName>
    </AppRow>
  );
};
export default CustomGroupModalDialogContentInputsRow;
