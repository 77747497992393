import gql from "graphql-tag";

export const CREATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE = gql`
  mutation createDigitalLabLandingPageUserProfile(
    $email: String!
    $site: String
    $familyName: String
    $givenName: String
    $name: String
    $phone: String
    $applicationSets: [DigitalLabLandingPageUserApplicationSetInput]
  ) {
    createDigitalLabLandingPageUserProfile(
      input: {
        email: $email
        applicationSets: $applicationSets
        site: $site
        familyName: $familyName
        givenName: $givenName
        name: $name
        phone: $phone
      }
    ) {
      id
      email
      familyName
      givenName
      name
      phone
      applicationSets {
        id
        name
        icon
        applicationReferences {
          applicationID
          applicationEntryPointID
        }
      }
      recentApplicationUsage {
        id
        lastUsed
        applicationRef {
          applicationID
          applicationEntryPointID
        }
      }
      lastFilter
      createdAt
      updatedAt
      user
      site
    }
  }
`;

export const DELETE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE = gql`
  mutation deleteDigitalLabLandingPageUserProfile($id: ID!, $email: String!) {
    deleteDigitalLabLandingPageUserProfile(input: { id: $id, email: $email }) {
      id
      email
      createdAt
      updatedAt
      user
    }
  }
`;

export const UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE = gql`
  mutation updateDigitalLabLandingPageUserProfile(
    $id: ID!
    $email: String!
    $applicationSets: [DigitalLabLandingPageUserApplicationSetInput]
  ) {
    updateDigitalLabLandingPageUserProfile(
      input: { id: $id, email: $email, applicationSets: $applicationSets }
    ) {
      id
      email
      familyName
      givenName
      name
      applicationSets {
        id
        name
        icon
        applicationReferences {
          applicationID
          applicationEntryPointID
        }
      }
      lastFilter
      createdAt
      updatedAt
      user
      site
    }
  }
`;

export const UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE_FILTER = gql`
  mutation updateDigitalLabLandingPageUserProfile(
    $id: ID!
    $email: String!
    $lastFilter: String!
  ) {
    updateDigitalLabLandingPageUserProfile(
      input: { id: $id, email: $email, lastFilter: $lastFilter }
    ) {
      id
      email
      lastFilter
    }
  }
`;

export const UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE_SITE = gql`
  mutation updateDigitalLabLandingPageUserProfile(
    $id: ID!
    $email: String!
    $site: String
  ) {
    updateDigitalLabLandingPageUserProfile(
      input: { id: $id, email: $email, site: $site }
    ) {
      id
      email
      site
    }
  }
`;

export const UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE_RECENT_APP_USAGE = gql`
  mutation updateDigitalLabLandingPageUserProfile(
    $id: ID!
    $email: String!
    $recentApplicationUsage: [DigitalLabLandingPageApplicationUsageInput]
  ) {
    updateDigitalLabLandingPageUserProfile(
      input: {
        id: $id
        email: $email
        recentApplicationUsage: $recentApplicationUsage
      }
    ) {
      id
      email
      recentApplicationUsage {
        id
        lastUsed
        applicationRef {
          applicationID
          applicationEntryPointID
        }
      }
    }
  }
`;

export const CREATE_DIGITAL_LAB_LANDING_PAGE_APPLICATION_GROUP = gql`
  mutation createDigitalLabLandingPageApplicationGroup(
    $name: String!
    $icon: String
  ) {
    createDigitalLabLandingPageApplicationGroup(
      input: { name: $name, icon: $icon }
    ) {
      id
      parentID
      name
      icon
      updatedAt
      iconPath
    }
  }
`;

export const DELETE_DIGITAL_LAB_LANDING_PAGE_APPLICATION_GROUP = gql`
  mutation deleteDigitalLabLandingPageApplicationGroup($id: ID!) {
    deleteDigitalLabLandingPageApplicationGroup(input: { id: $id }) {
      id
      parentID
      name
      icon
      updatedAt
    }
  }
`;

export const UPDATE_DIGITAL_LAB_LANDING_PAGE_APPLICATION_GROUP = gql`
  mutation updateDigitalLabLandingPageApplicationGroup(
    $id: ID!
    $name: String
  ) {
    updateDigitalLabLandingPageApplicationGroup(
      input: { id: $id, name: $name }
    ) {
      id
      parentID
      name
      icon
      updatedAt
      iconPath
    }
  }
`;
export const UPDATE_DIGITAL_LAB_LANDING_PAGE_APPLICATION = gql`
  mutation updateDigitalLabLandingPageApplication(
    $application: UpdateDigitalLabLandingPageApplicationInput!
  ) {
    application: updateDigitalLabLandingPageApplication(input: $application) {
      id
      name
      description
      icon
      type
      applicationGroupIDs
      entryPoints {
        id
        name
        url
      }
      allowedRoles
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_DIGITAL_LAB_LANDING_PAGE_APPLICATION = gql`
  mutation createDigitalLabLandingPageApplication(
    $application: CreateDigitalLabLandingPageApplicationInput!
  ) {
    application: createDigitalLabLandingPageApplication(input: $application) {
      id
      name
      description
      icon
      type
      applicationGroupIDs
      entryPoints {
        id
        name
        url
      }
      allowedRoles
      createdAt
      updatedAt
    }
  }
`;
export const DELETE_DIGITAL_LAB_LANDING_PAGE_APPLICATION = gql`
  mutation deleteDigitalLabLandingPageApplication($id: ID!) {
    deleteDigitalLabLandingPageApplication(input: { id: $id }) {
      id
    }
  }
`;

export const ICON_UPLOAD_URL = gql`
  query iconUploadUrl(
    $applicationId: String
    $contentType: String
    $groupId: String
  ) {
    iconUploadUrl(
      applicationId: $applicationId
      contentType: $contentType
      groupId: $groupId
    )
  }
`;

export const DELETE_APPLICATION_CATEGORY = gql`
  mutation deleteApplicationCategory($categoryId: String) {
    deleteApplicationCategory(categoryId: $categoryId)
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation deleteDigitalLabLandingPageNotification($id: ID!) {
    deleteDigitalLabLandingPageNotification(input: { id: $id }) {
      id
      type
      severity
      time
      source
      message
      details
      payload
      link
      expiresAt
      dueDate
      updatedAt
      user
      createdAt
      sender
    }
  }
`;

export const DELETE_NOTIFICATION_ALL = gql`
  mutation deleteDigitalLabLandingPageNotification($id: ID!) {
    deleteDigitalLabLandingPageNotification(input: { id: $id }) {
      id
    }
  }
`;

export const UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE_FIELDS = gql`
  mutation updateDigitalLabLandingPageUserProfile(
    $id: ID!
    $email: String!
    $site: String
    $familyName: String
    $givenName: String
    $name: String
    $phone: String
  ) {
    updateDigitalLabLandingPageUserProfile(
      input: {
        id: $id
        email: $email
        site: $site
        familyName: $familyName
        givenName: $givenName
        name: $name
        phone: $phone
      }
    ) {
      id
      email
      familyName
      givenName
      name
      phone
      site
      applicationSets {
        id
        name
        icon
        applicationReferences {
          applicationID
          applicationEntryPointID
        }
      }
      recentApplicationUsage {
        id
        lastUsed
        applicationRef {
          applicationID
          applicationEntryPointID
        }
      }
      lastFilter
      createdAt
      updatedAt
      user
    }
  }
`;

export const CREATE_WIDGET = gql`
  mutation createWidget(
    $id: ID!
    $widgetURL: String!
    $metadataURL: String!
    $version: String!
    $name: String!
    $description: String!
    $fromAppName: String
    $fromAppSource: String!
    $supportedSizes: [SupportedWidgetSizeInput]
    $releaseDate: AWSDateTime
  ) {
    createWidget(
      input: {
        id: $id
        widgetURL: $widgetURL
        metadataURL: $metadataURL
        version: $version
        name: $name
        description: $description
        fromAppName: $fromAppName
        fromAppSource: $fromAppSource
        supportedSizes: $supportedSizes
        releaseDate: $releaseDate
      }
    ) {
      description
      fromAppName
      fromAppSource
      id
      metadataURL
      name
      releaseDate
      updatedAt
      widgetURL
      supportedSizes {
        height
        mockupURL
        width
      }
    }
  }
`;

export const UPDATE_WIDGET = gql`
  mutation updateWidget(
    $id: ID!
    $widgetURL: String
    $metadataURL: String
    $version: String
    $name: String
    $description: String
    $fromAppName: String
    $fromAppSource: String
    $supportedSizes: [SupportedWidgetSizeInput]
    $releaseDate: AWSDateTime
  ) {
    updateWidget(
      input: {
        id: $id
        widgetURL: $widgetURL
        metadataURL: $metadataURL
        version: $version
        name: $name
        description: $description
        fromAppName: $fromAppName
        fromAppSource: $fromAppSource
        supportedSizes: $supportedSizes
        releaseDate: $releaseDate
      }
    ) {
      description
      fromAppName
      fromAppSource
      id
      metadataURL
      name
      releaseDate
      updatedAt
      widgetURL
      supportedSizes {
        height
        mockupURL
        width
      }
    }
  }
`;
export const DELETE_WIDGET = gql`
  mutation deleteWidget($id: ID!) {
    deleteWidget(input: { id: $id }) {
      id
    }
  }
`;

export const CREATE_DASHBOARD = gql`
  mutation createDashboard($id: ID!, $widgets: [WidgetConfigurationInput]) {
    createDashboard(input: { id: $id, widgets: $widgets }) {
      id
      widgets {
        locationX
        locationY
        widgetId
        size {
          height
          mockupURL
          width
        }
      }
    }
  }
`;

export const UPDATE_DASHBOARD = gql`
  mutation updateDashboard($id: ID!, $widgets: [WidgetConfigurationInput]) {
    updateDashboard(input: { id: $id, widgets: $widgets }) {
      id
      widgets {
        locationX
        locationY
        widgetId
        size {
          height
          mockupURL
          width
        }
      }
    }
  }
`;
export const DELETE_DASHBOARD = gql`
  mutation deleteDashboard($id: ID!) {
    deleteDashboard(input: { id: $id }) {
      id
    }
  }
`;

export const SEND_GROUP_NOTIFICATION = gql`
  mutation createLandingPageNotificationCommand(
    $severity: String!
    $short: String!
    $details: String!
    $link: String!
    $id: ID
  ) {
    createLandingPageNotificationCommand(
      input: {
        severity: $severity
        short: $short
        details: $details
        link: $link
        id: $id
      }
    ) {
      id
      status
      user
      severity
      short
      details
      link
    }
  }
`;

export const CREATE_DIGITAL_LAB_LANDING_PAGE_FAV_EQUIPMENT = gql`
  mutation createDigitalLabLandingPageFavEquipment(
    $userID: ID!
    $inventoryIDs: [String]
  ) {
    createDigitalLabLandingPageFavEquipment(
      input: { userID: $userID, inventoryIDs: $inventoryIDs }
    )
  }
`;

export const DELETE_DIGITAL_LAB_LANDING_PAGE_FAV_EQUIPMENT = gql`
  mutation deleteDigitalLabLandingPageUserEquipment($userID: ID!) {
    deleteDigitalLabLandingPageUserEquipment(input: { id: $userID }) {
      userID
      id
      inventoryID
    }
  }
`;

export const CREATE_DIGITAL_LAB_LANDING_PAGE_USER_FILTER = gql`
  mutation createUserFilter(
    $userID: ID!
    $filterName: String!
    $filterDefinition: String
  ) {
    createUserFilter(
      input: {
        userID: $userID
        filterName: $filterName
        filterDefinition: $filterDefinition
      }
    ) {
      filterName
      id
      filterDefinition
      userID
    }
  }
`;

export const UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_FILTER = gql`
  mutation updateUserFilter(
    $userID: ID!
    $filterName: String!
    $filterDefinition: String
  ) {
    updateUserFilter(
      input: {
        userID: $userID
        filterName: $filterName
        filterDefinition: $filterDefinition
      }
    ) {
      filterName
      id
      filterDefinition
      userID
    }
  }
`;

export const DELETE_DIGITAL_LAB_LANDING_PAGE_USER_FILTER = gql`
  mutation deleteUserFilter($userID: ID!, $filterName: String!) {
    deleteUserFilter(input: { userID: $userID, filterName: $filterName }) {
      filterName
      id
      filterDefinition
      userID
    }
  }
`;

export const CREATE_NOTIFICATION_BANNER = gql`
  mutation createNotificationBanner(
    $color: String!
    $displayText: String!
    $heading: String!
    $infoURl: String
    $enabled: Boolean!
  ) {
    createNotificationBanner(
      input: {
        color: $color
        displayText: $displayText
        heading: $heading
        infoURl: $infoURl
        enabled: $enabled
      }
    ) {
      id
      color
      displayText
      enabled
      heading
      infoURl
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_NOTIFICATION_BANNER = gql`
  mutation updateNotificationBanner(
    $id: ID!
    $color: String!
    $displayText: String!
    $heading: String!
    $infoURl: String
    $enabled: Boolean!
  ) {
    updateNotificationBanner(
      input: {
        id: $id
        color: $color
        displayText: $displayText
        heading: $heading
        infoURl: $infoURl
        enabled: $enabled
      }
    ) {
      id
      color
      displayText
      enabled
      heading
      infoURl
    }
  }
`;

export const UPDATE_USER_EMAIL = gql`
  mutation updateUserEmail($email: String!, $userId: String!) {
    updateUserEmail(email: $email, userId: $userId)
  }
`;
