import React from "react";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import styled, { css } from "styled-components";
import useModal from "../../utils/hooks/useModal";
import CustomGroupModal, { EmptyCustomGroup } from "./CustomGroupModal";
import {
  loadUserInfo as loadUserInfoActions,
  selectGroupUserInfo as selectGroupUserInfoActions
} from "../landingPageUser/redux/actions";
import { v4 as uuidv4 } from "uuid";
import { pickKeys } from "./ApplicationFavouriteButton";
import get from "lodash/get";
import { UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE } from "../../gql/landingapi/mutations";
import { NewCustomGroupedAppsCard } from "./CustomGroupedAppsCard";
// import { NewCustomGroupedAppsCardnew } from "./CustomGroupedAppsCard";
import ConfirmDialog from "../../components/shared/ConfirmDialog";
import useDialog from "../../utils/hooks/useDialog";
import CustomGroupedApps from "./CustomGroupedApps";
import { useMobile } from "../../utils/hooks";

const listCardChildrenMobile = css`
  flex: 0 1 159px;
  margin-right: 6px;
`;
const ListCardStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
  & > * {
    margin-bottom: 5px;
    margin-right: 20px;
    max-width: 160px;
    ${(props) => props.isMobile && listCardChildrenMobile}
  }
  @media only screen and (max-width: 415px) {
    justify-content: space-between;
  }
`;
export const FolderIconNew = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: 400;
  min-height: 6px;
`;
const iconMenuDelete = css``;
export const IconMenu = styled.i`
  margin-right: 4px;
  color: var(--one-color-gray-600);
  ${(props) => props.deleteIcon && iconMenuDelete}
`;

const appsWithEntryPointsToAppRefs = (appsWithEntryPoints) =>
  appsWithEntryPoints
    .map((appWithEntryPoints) =>
      appWithEntryPoints.entryPoints.map((entryPointId) => ({
        applicationID: appWithEntryPoints.application.id,
        applicationEntryPointID: entryPointId
      }))
    )
    .flat();

const saveGroupFactory = ({
  applicationSets,
  updateApplicationSets,
  handleClose
}) => {
  return async (customGroup) => {
    let newApplicationSets;
    if (customGroup.id) {
      newApplicationSets = applicationSets.map(pickKeys);

      const appSetToEdit = newApplicationSets.find(
        (set) => set.id === customGroup.id
      );
      if (appSetToEdit) {
        appSetToEdit.name = customGroup.name;
        appSetToEdit.applicationReferences = appsWithEntryPointsToAppRefs(
          customGroup.appsWithEntryPoints
        );
      }
    } else {
      newApplicationSets = [
        ...applicationSets.map(pickKeys),
        {
          id: uuidv4(),
          name: customGroup.name,
          applicationReferences: appsWithEntryPointsToAppRefs(
            customGroup.appsWithEntryPoints
          )
        }
      ];
    }
    await updateApplicationSets(newApplicationSets);
    handleClose();
  };
};

export const GroupEdit = React.createContext(123);
const CustomGroupedAppsList = ({
  customGroups = [],
  Component,
  applications = [],
  id,
  email,
  applicationSets,
  loadUserInfo,
  selectGroupUserInfo,
  client,
  name,
  groupedType,
  selected
}) => {
  const isMobile = useMobile();
  const { openDialog, ...dialogProps } = useDialog();
  const [title, setTitle] = React.useState("Edit group");
  const [open, handleOpen, handleClose] = useModal(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuFor, setMenuFor] = React.useState(null);
  const [customGroupToEdit, setCustomGroupToEdit] = React.useState({
    ...EmptyCustomGroup
  });
  const [customGroupDelete, setCustomGroupDelete] = React.useState(null);
  const handleClickMenu = (event, customGroup) => {
    setAnchorEl(event.target);
    setMenuFor(customGroup);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuFor(null);
  };
  const updateApplicationSets = async (newApplicationSets) => {
    loadUserInfo({ applicationSets: newApplicationSets });
    const updatedDigitalLabLandingPageUserProfile = await client.mutate({
      mutation: UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE,
      variables: { id, email, applicationSets: newApplicationSets }
    });

    const _applicationSets = get(
      updatedDigitalLabLandingPageUserProfile,
      "data.updateDigitalLabLandingPageUserProfile.applicationSets"
    );
    loadUserInfo({ applicationSets: _applicationSets });
  };
  const saveGroup = saveGroupFactory({
    applicationSets,
    updateApplicationSets,
    handleClose
  });
  const deleteGroup = async (customGroup) => {
    handleCloseMenu();
    const newApplicationSets = applicationSets
      .map(pickKeys)
      .filter((set) => set.id !== customGroup.id);
    await updateApplicationSets(newApplicationSets);
  };
  return (
    <>
      <ListCardStyled
        data-testid="custom-groups-list"
        isMobile={isMobile}
        style={{ paddingRight: 0, paddingBottom: "20px" }}
      >
        <CustomGroupModal
          customGroup={customGroupToEdit}
          open={open}
          cancel={handleClose}
          save={saveGroup}
          applications={applications}
          title={title}
          customGroups={customGroups}
        />
        {customGroups.map((customGroup, theId) => (
          <CustomGroupedApps
            customGroup={customGroup}
            theId={theId}
            Component={Component}
            handleClickMenu={handleClickMenu}
            selectGroupUserInfo={selectGroupUserInfo}
            anchorEl={anchorEl}
            menuFor={menuFor}
            handleCloseMenu={handleCloseMenu}
            setTitle={setTitle}
            setCustomGroupToEdit={setCustomGroupToEdit}
            handleOpen={handleOpen}
            setCustomGroupDelete={setCustomGroupDelete}
            openDialog={openDialog}
            selected={selected}
            groupedType={groupedType}
            key={theId}
          />
        ))}
        {name === "Categories" ? (
          ""
        ) : (
          <NewCustomGroupedAppsCard
            data-testid="new-add-new-custom-group-card"
            onClick={() => {
              setCustomGroupToEdit({ ...EmptyCustomGroup });
              handleOpen();
              setTitle("Create new group");
            }}
          />
        )}
        <ConfirmDialog
          {...dialogProps}
          approveText="Delete group"
          approveColor="error"
          approveVariant="contained"
          cancelText="Cancel"
          cancelVariant="outlined"
          cancelColor="primary"
          onApprove={() => deleteGroup(customGroupDelete)}
          title="Delete group"
          content={`Are you sure you want to delete the group '${customGroupDelete?.name}'?`}
        />
      </ListCardStyled>
    </>
  );
};

export const mapStateToPropsAllApps = (store) => ({
  applications: store.user.allApps,
  id: store.user.id,
  email: store.user.email,
  applicationSets: store.user.applicationSets,
  selected: store.user.selected
});
export default compose(
  connect(mapStateToPropsAllApps, {
    loadUserInfo: loadUserInfoActions,
    selectGroupUserInfo: selectGroupUserInfoActions
  }),
  withApollo
)(CustomGroupedAppsList);
