import React, { useContext } from "react";
import Search from "../adminPanel/appAndCategories/Search";
import { QueryContext } from "./contexts";
import styles from "../adminPanel/appAndCategories/TopbarForApplication.module.scss";

const TopbarForCategory = () => {
  const { filterQuery } = useContext(QueryContext);
  return (
    <div className={styles.topbarforapplicationContainer}>
      <Search
        setQuery={filterQuery}
        testid="search-category"
        placeholder="Search for category"
        compact
      />
    </div>
  );
};

export default TopbarForCategory;
