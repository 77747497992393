import { API } from "aws-amplify";
import { get, omit } from "underscore";
import { configureAwsAppSync } from "./configureAwsAppSync";

const createOrFilter = (arrayFilterKeys, arrayFilters) => {
  return arrayFilterKeys
    ?.reduce(
      (acc, key) =>
        arrayFilters[key].length
          ? [
              ...acc,
              arrayFilters[key]?.reduce(
                (acc_i, val) =>
                  typeof val === "string"
                    ? [...acc_i, getGQLFilterObj({ [key]: val })]
                    : acc_i,
                []
              )
            ]
          : acc,
      []
    )
    .filter((arr) => arr.length);
};

export const createGQLFilterObj = (obj) => {
  if (typeof obj !== "object" || obj === null || Array.isArray(obj)) {
    return null;
  }
  const objKeys = Object.keys(obj);
  if (!objKeys.length) {
    return null;
  }
  const arrayFilters = objKeys.reduce(
    (acc, curr) =>
      Array.isArray(obj[curr]) ? { ...acc, [curr]: obj[curr] } : acc,
    {}
  );
  const arrayFilterKeys = Object.keys(arrayFilters);
  if (arrayFilterKeys.length) {
    const OrFilter = createOrFilter(arrayFilterKeys, arrayFilters);
    if (!OrFilter.length) {
      return getGQLFilterObj(omit(obj, arrayFilterKeys));
    }
    const simpleFilter = getGQLFilterObj(omit(obj, arrayFilterKeys));
    if (!simpleFilter) {
      return {
        and: [...OrFilter?.map((arr) => ({ or: arr }))]
      };
    }
    return {
      and: [...OrFilter?.map((arr) => ({ or: arr })), simpleFilter]
    };
  }
  return getGQLFilterObj(obj);
};

export const getGQLFilterObj = (obj) => {
  if (typeof obj !== "object" || obj === null || Array.isArray(obj)) {
    return null;
  }
  const result = Object.keys(obj).reduce((acc, cur) => {
    if (obj?.[cur] === null || obj?.[cur] === undefined) {
      return acc;
    }
    if (
      typeof obj?.[cur] === "string" &&
      (obj?.[cur]?.trim() === "" || obj?.[cur]?.trim() === "all")
    ) {
      return acc;
    }
    return { ...acc, [cur]: { eq: obj[cur] } };
  }, {});
  return Object.keys(result).length === 0 ? null : result;
};

const fetchOne = async ({
  client,
  query,
  fetchPolicy,
  variables,
  nextToken,
  dataPath,
  items,
  drillData,
  limit,
  awsAppSyncConfig
}) => {
  let result = [];
  if (awsAppSyncConfig) {
    configureAwsAppSync(awsAppSyncConfig);
    result = await API.graphql({
      client,
      query,
      variables: {
        ...variables,
        nextToken
      }
    });
  } else {
    result = await client.query({
      query,
      fetchPolicy,
      variables: {
        ...variables,
        nextToken
      }
    });
  }
  const page = get(result, [...dataPath, "items"]) ?? [];
  items = Array.isArray(page) ? [...items, ...page] : items;
  const _nextToken = get(result, [...dataPath, "nextToken"]);
  nextToken = typeof _nextToken === "string" && _nextToken ? _nextToken : null;
  const shouldKeepFetching = drillData ? drillData : items.length < limit;
  return { nextToken, items, shouldKeepFetching };
};

export const getAllData = async ({
  client,
  query,
  fetchPolicy = "network-only",
  variables = {},
  dataPath,
  drillData = false,
  awsAppSyncConfig = null
}) => {
  const emptyResponse = {
    items: [],
    nextToken: null,
    error: null
  };
  if (!client || !query || !variables) {
    return emptyResponse;
  }
  if (!Array.isArray(dataPath)) {
    return emptyResponse;
  }
  if (dataPath.length === 0) {
    return emptyResponse;
  }
  try {
    let items = [];
    let nextToken = variables?.nextToken ?? null;
    let shouldKeepFetching = true;
    const { limit = 10 } = variables;
    do {
      ({ nextToken, items, shouldKeepFetching } = await fetchOne({
        client,
        query,
        fetchPolicy,
        variables,
        nextToken,
        dataPath,
        items,
        shouldKeepFetching,
        drillData,
        limit,
        awsAppSyncConfig
      }));
    } while (nextToken !== null && shouldKeepFetching);
    return {
      items,
      nextToken,
      error: null
    };
  } catch (err) {
    return { ...emptyResponse, error: err };
  }
};

const TIMEOUT_SUBSCRIBE_AFTER = 30000;

export const waitUntilSubscribeCondition = async (
  client,
  subDoc,
  condition
) => {
  return new Promise((res, rej) => {
    let timeoutId = null;
    const cancelTimeout = () => {
      clearTimeout(timeoutId);
    };

    const sub = client
      .subscribe({
        query: subDoc,
        fetchPolicy: "no-cache"
      })
      .subscribe({
        next: ({ data }) => {
          if (condition(data)) {
            sub.unsubscribe();
            cancelTimeout();
            res();
          }
        },
        error: (error) => {
          sub.unsubscribe();
          cancelTimeout();
          rej(error);
        }
      });

    timeoutId = setTimeout(() => {
      rej();
      sub.unsubscribe();
    }, TIMEOUT_SUBSCRIBE_AFTER);
  });
};

export const getContainerHeightPercentage = (currentEnv) => {
  const containerHeight =
    ((window.innerHeight - (currentEnv ? 70 : 33)) / window.innerHeight) * 100;
  return containerHeight;
};

export const getFilterModel = (filterModel) => {
  let filterQuery = ``;
  for (const key in filterModel) {
    let query = ``;
    filterModel[key]?.values?.forEach((element) => {
      query += ` ${key}:'${element}'`;
    });

    filterQuery += ` (or${query})`;
  }

  if (filterQuery?.length === 0) {
    return null;
  }

  return filterQuery?.split("or")?.length > 2
    ? `(and${filterQuery})`
    : filterQuery?.trim();
};
