import { IconButton, Paper } from "@mui/material";
import { OwcIcon } from "@one/react";
import { useState } from "react";
import AuthenticatedWidget from "../qfront/AuthenticatedWidget";
import WidgetItemMenu from "./WidgetItemMenu";

const WidgetItem = ({
  widget,
  widgets,
  isGridEditable,
  pendingSaving,
  key,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <Paper {...props} data-testid="widget-item">
      {isGridEditable && !pendingSaving && (
        <div style={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}>
          <IconButton
            className="dont-drag"
            id={`widget_${widget.widgetId}`}
            data-testid="widget-item-show-edit-menu-button"
            onClick={(event) => setAnchorEl(event.target)}
          >
            <OwcIcon
              name="more_horizontal"
              type="filled"
              data-testid="widget-item-show-edit-menu-icon"
            />
          </IconButton>
          <WidgetItemMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            widget={widget}
            widgets={widgets}
          />
        </div>
      )}
      <AuthenticatedWidget
        appId={widget.widgetId}
        params={{ ...widget.size, isEditable: isGridEditable }}
      />
    </Paper>
  );
};

export default WidgetItem;
