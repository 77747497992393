import React, { useContext } from "react";
import styled from "styled-components";
import CustomList from "../../../components/shared/List";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { Module } from "../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const {adminwidget} = Module.PAGE.ADMIN;

const WidgetsTableStyled = styled.div`
  & .row {
    background-color: var(--one-color-gray-50);
  }
`;

const WidgetsTable = ({ dataTestId, meta }) => {
  const { data, fetching, sortDirection, orderBy, dispatchAction } =
    useContext(PaginationContext);
  return (
    <WidgetsTableStyled data-testid={dataTestId} id={generateID.UUID(adminwidget, `widgetTable`, "style")}>
      <CustomList
        id={generateID.UUID(adminwidget, `widgetTable`, "customTable")}
        orderBy={orderBy}
        isReverseOrder={sortDirection === "ASC"}
        onRequestSort={(key, theSortDirection) => {
          dispatchAction({
            type: "sortDirection",
            payload: {
              sortDirection: theSortDirection,
              orderBy: key
            }
          });
        }}
        data={data}
        meta={meta}
        loading={fetching}
      />
    </WidgetsTableStyled>
  );
};

export default WidgetsTable;
