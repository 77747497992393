import React, { useEffect, useState } from "react";
import { compose, withApollo } from "react-apollo";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import {
  OwcDrawer,
  OwcIcon,
  OwcIconButton,
  OwcList,
  OwcTypography,
  OwcExpandable
} from "@one/react";
import { getEnv } from "../utils/helpers/text";
import { OwcListItem } from "@one/react/dist";
import { useHistory } from "react-router";
import styles from "./AppDrawer.module.scss";
import { snakeCase } from "lodash";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../constants";

const currentEnv = getEnv();

const AppDrawer = ({
  children,
  selectedType,
  applicationSets = [],
  client,
  email,
  NotificationBanner,
  sideMenu,
  activeMenuText,
  isTopBarActive
}) => {
  const history = useHistory();
  const [isMini, setIsMini] = useState(false);
  const topBannerHt = 55;
  const envHeaderHt = 46.39;
  const mainHeaderHt = 124 + 27.8; // main menu + footer
  const TopBannerDatas = useSelector((state) => state.user.NotificationBanner);

  const calcHeight = () => {
    return (
      window.innerHeight -
      (currentEnv && TopBannerDatas?.enabled
        ? mainHeaderHt + topBannerHt + envHeaderHt
        : currentEnv
        ? mainHeaderHt + envHeaderHt
        : TopBannerDatas?.enabled
        ? mainHeaderHt + topBannerHt
        : mainHeaderHt)
    );
  };

  const containerHeightLoad = calcHeight();

  const [containerHeight, setContainerHeight] = useState(containerHeightLoad);

  useEffect(() => {
    const height = calcHeight();
    setContainerHeight(height);
    // eslint-disable-next-line
  }, [TopBannerDatas]);

  window.addEventListener("resize", function () {
    setContainerHeight(calcHeight());
  });

  const handleRedirect = (item) => {
    if (!item.isDisabled && item.url !== null) {
      history.replace(item.url);
    }
  };
  const [isGSubMenuExpanded, setIsGSubMenuExpanded] = useState(false);
  const [isBSubMenuExpanded, setIsBSubMenuExpanded] = useState(false);
  const handleExpandableStatusChanged = (event) => {
    if (isMini) {
      setIsMini(!isMini);
    }
    switch (event.currentTarget.id) {
      case "General":
        setIsGSubMenuExpanded(!isMini ? event.detail : true);
        break;
      case "Booking":
        setIsBSubMenuExpanded(!isMini ? event.detail : true);
        break;
      default:
        break;
    }
  };
  const serCaretIcon = (ExpandableId) => {
    switch (ExpandableId) {
      case "General":
        return isGSubMenuExpanded ? "caret_down" : "caret_up";
      case "Booking":
        return isBSubMenuExpanded ? "caret_down" : "caret_up";
      default:
        break;
    }
  };
  const drawerSideBar = () => {
    return (
      <OwcList
        id={generateID.UUID(Module.SIDEPANEL.name, `drawersidebar`, "list")}
      >
        {sideMenu.map((item, i) => {
          if (item?.text === "dummy") {
            return "";
          }
          if (item.children.length === 0 && !item?.hidden) {
            return (
              <OwcListItem
                selected={item.isActive}
                onClick={() => handleRedirect(item)}
                noBorder
                key={`${snakeCase(item.text)}_${i}`}
                id={generateID.UUID(
                  Module.SIDEPANEL.name,
                  `${snakeCase(item.text)}`,
                  "li"
                )}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {typeof item.icon === "string" ? (
                    <OwcIcon
                      name={item.icon}
                      type="legacy"
                      className={styles.appdrawerIconColor}
                      id={generateID.UUID(
                        Module.SIDEPANEL.name,
                        `${snakeCase(item.text)}_icon`,
                        "li"
                      )}
                    />
                  ) : (
                    <div
                      className={styles.appdrawerIconStyle}
                      id={generateID.UUID(
                        Module.SIDEPANEL.name,
                        `${snakeCase(item.text)}_icon`,
                        "li"
                      )}
                    >
                      {item.icon}
                    </div>
                  )}
                  <span
                    style={{ marginLeft: 15 }}
                    id={generateID.UUID(
                      Module.SIDEPANEL.name,
                      `${snakeCase(item.text)}_text`,
                      "span"
                    )}
                  >
                    {item.text}
                  </span>
                </div>
              </OwcListItem>
            );
          } else {
            return (
              <>
                <OwcExpandable
                  id={item.text}
                  style={{ width: "100%" }}
                  variant="dense"
                  onStatusChanged={(ev) => handleExpandableStatusChanged(ev)}
                  expanded={
                    item.text === "General"
                      ? isGSubMenuExpanded
                      : isBSubMenuExpanded
                  }
                >
                  <span
                    slot="control"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      height: "48px"
                    }}
                  >
                    <OwcIconButton
                      flat
                      id={generateID.UUID(
                        Module.SIDEPANEL.name,
                        `drawersidebar`,
                        "icon_button"
                      )}
                      style={{ pointerEvents: "none" }}
                    />
                    {typeof item.icon === "string" ? (
                      <OwcIcon
                        name={item.icon}
                        type="legacy"
                        id={generateID.UUID(
                          Module.SIDEPANEL.name,
                          `drawersidebar`,
                          "icon_legacy"
                        )}
                        className={styles.appdrawerIconColor}
                      />
                    ) : (
                      <div
                        className={styles.appdrawerIconStyle}
                        id={generateID.UUID(
                          Module.SIDEPANEL.name,
                          `drawersidebar`,
                          "item_icon"
                        )}
                      >
                        {item.icon}
                      </div>
                    )}
                    <OwcTypography
                      variant="subtitle1"
                      style={{ marginLeft: "15px" }}
                      id={generateID.UUID(
                        Module.SIDEPANEL.name,
                        `drawersidebar`,
                        "text_item"
                      )}
                    >
                      {item.text}
                    </OwcTypography>
                    <OwcIconButton
                      icon={serCaretIcon(item.text)}
                      flat
                      style={{ margin: "0 8px 0 auto", pointerEvents: "none" }}
                    />
                  </span>
                  <span
                    id={generateID.UUID(
                      Module.SIDEPANEL.name,
                      `drawersidebar`,
                      "list_span"
                    )}
                    slot="content"
                    style={{ padding: "0" }}
                  >
                    {!isMini &&
                      item.children.map((subItem) => (
                        <OwcListItem
                          iconType="outlined"
                          icon={subItem.icon}
                          selected={subItem.isActive}
                          onClick={() => handleRedirect(subItem)}
                          noBorder
                          size="compact"
                          indent="2"
                          disabled={subItem.isDisabled === true}
                          id={generateID.UUID(
                            Module.SIDEPANEL.name,
                            `drawersidebar`,
                            "list_subItem"
                          )}
                        >
                          {subItem.text}
                        </OwcListItem>
                      ))}
                  </span>
                </OwcExpandable>
              </>
            );
          }
        })}
      </OwcList>
    );
  };

  return (
    <div
      className={styles.appdrawerOwcdrawer}
      style={{ height: containerHeight }}
      id={generateID.UUID(Module.SIDEPANEL.name, `AppDrawer`, "main")}
    >
      <OwcDrawer
        id="drawer"
        mode="push"
        hideBackdrop
        visible={sideMenu.length !== 0}
        mini={isMini}
      >
        <div
          slot="panel"
          className={styles.appdrawerPanel}
          id={generateID.UUID(
            Module.SIDEPANEL.name,
            `AppDrawer`,
            "panel_sidebar"
          )}
        >
          {drawerSideBar()}
        </div>
        <div
          slot="header"
          className={styles.appdrawerHeader}
          id={generateID.UUID(
            Module.SIDEPANEL.name,
            `AppDrawer`,
            "slot_header"
          )}
        >
          <OwcIconButton
            flat
            id="menu-button"
            icon="menu"
            style={{ margin: "8px" }}
            onClick={() => (!isMini ? setIsMini(true) : setIsMini(false))}
          />
          <OwcTypography
            variant="button"
            id={generateID.UUID(
              Module.SIDEPANEL.name,
              `${snakeCase(activeMenuText)}`,
              "title"
            )}
          >
            {activeMenuText}
          </OwcTypography>

          <OwcIconButton
            flat
            id="collapse-button"
            icon="clear"
            className={styles.appdrawerMenuclearicon}
            onClick={() => (!isMini ? setIsMini(true) : setIsMini(false))}
          />
        </div>

        <div
          className={styles.appdrawerDrawercontent}
          id={generateID.UUID(Module.SIDEPANEL.name, `AppDrawer`, "content")}
        >
          <main
            id={generateID.UUID(
              Module.SIDEPANEL.name,
              `AppDrawer`,
              "main_child"
            )}
            style={{ padding: "1rem" }}
          >
            {children}
          </main>
        </div>
      </OwcDrawer>
    </div>
  );
};
const mapStateToProps = (store) => ({
  selectedType: store.user.selected.type,
  applicationSets: store.user.applicationSets,
  email: store.user.email,
  NotificationBanner: store.user.NotificationBanner
});
export default compose(connect(mapStateToProps), withApollo)(AppDrawer);
