import { ICON_UPLOAD_URL } from "../../gql/landingapi/mutations";
import { get } from "lodash";

export const ICON_VALID_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/bmp"
];

export const getUploadIconUrl = async ({
  client,
  groupId = "",
  applicationId = "",
  contentType = "image/bmp"
}) => {
  const data = await client.query({
    query: ICON_UPLOAD_URL,
    fetchPolicy: "no-cache",
    variables: {
      groupId,
      applicationId,
      contentType
    }
  });
  return get(data, "data.iconUploadUrl");
};

export const uploadIconFile = async ({ file, uploadUrl }) => {
  await fetch(uploadUrl, {
    method: "PUT",
    body: file,
    headers: {
      "x-amz-server-side-encryption": "AES256"
    }
  });
};

export const uploadIconFlow = async ({
  client,
  groupId = "",
  applicationId = "",
  file,
  onSuccess,
  onError
}) => {
  try {
    const linkToUpload = await getUploadIconUrl({
      client,
      groupId,
      applicationId,
      contentType: file?.type
    });
    await uploadIconFile({ file, uploadUrl: linkToUpload });
    if (typeof onSuccess === "function") {
      onSuccess(new URL(linkToUpload).pathname.replace(/^\//, ""));
    }
  } catch (err) {
    if (typeof onError === "function") {
      onError(err?.message);
    }
  }
};

export const readFileAsDataURL = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      if (reader.result) {
        resolve(reader.result);
      } else {
        reject("No data");
      }
    });
    reader.readAsDataURL(file);
  });

export const readImageFromFile = async (dataUrl) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.src = dataUrl;
    img.addEventListener("load", () => {
      const w = img.width;
      const h = img.height;
      if (w !== 64 && h !== 64) {
        reject("Icon size must be 64x64!");
      } else {
        resolve(true);
      }
    });
  });

export const checkFile = async (file) => {
  if (!ICON_VALID_TYPES.includes(file.type)) {
    throw new Error("Unsupported icon type");
  }
  await readFileAsDataURL(file);
  const dataUrl = await readFileAsDataURL(file);
  return readImageFromFile(dataUrl);
};
