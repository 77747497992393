const EmptyMyLab = (props) => (
  <svg
    width="97"
    height="96"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4210_171630)">
      <path
        d="M69.0854 85.2005C71.1693 88.1097 72.4278 91.0658 72.5039 94.9H25.0425C25.1537 89.9829 26.8922 86.1195 30.141 82.8766C32.3713 80.6522 36.182 78.9956 39.3137 78.1468H12.5449V73.1068H52.1274C60.662 73.1068 67.6103 66.2931 67.6103 57.7702C67.6103 50.8102 62.9215 44.6814 56.2015 42.8609C56.2015 42.8609 65.9947 28.5136 68.3947 29.9068C78.1352 35.5848 84.4454 46.1214 84.4454 57.7644C84.4513 69.3487 78.2991 79.5224 69.0854 85.2005Z"
        fill="#F5F6FA"
      />
      <path
        d="M25.7988 89.7367C26.7296 87.1319 28.2281 84.7962 30.1481 82.8821C30.1715 82.8587 30.1949 82.8353 30.2125 82.8177C33.2974 79.7738 37.512 78.1523 41.8437 78.1523H45.912H51.2447H55.313C59.6447 78.1523 63.8652 79.7738 66.9442 82.8177C66.9676 82.8411 66.991 82.8645 67.0086 82.8821C70.2574 86.1192 72.3003 90.5738 72.4115 95.4909H25.7988V89.7367Z"
        fill="#B2D1F0"
      />
      <path
        d="M51.9749 25.5886L30.377 47.1865L36.8547 53.6642L58.4526 32.0663L51.9749 25.5886Z"
        fill="#589AE1"
      />
      <path
        d="M51.9761 26.4115L57.6249 32.0603L36.8561 52.8349L31.2074 47.1861L51.9761 26.4115ZM51.9761 24.7607L29.5508 47.1861L36.8561 54.4915L59.2815 32.0661L51.9761 24.7607Z"
        fill="#589AE1"
      />
      <path
        d="M66.8097 7.25276L38.3574 35.7051L48.3327 45.6804L76.785 17.228L66.8097 7.25276Z"
        fill="#F5F6FA"
      />
      <path
        d="M66.8093 8.07846L75.9586 17.2277L48.3352 44.8511L39.1859 35.7019L66.8093 8.07846ZM66.8093 6.42188L37.5293 35.7019L48.3352 46.5077L77.6152 17.2277L66.8093 6.42188Z"
        fill="#589AE1"
      />
      <path
        d="M59.1804 6.57449L33.791 31.9639L52.0777 50.2505L77.467 24.8611L59.1804 6.57449Z"
        fill="#B2D1F0"
        stroke="#589AE1"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M36.9334 35.1056L34.541 37.498L46.5445 49.5015L48.9369 47.1091L36.9334 35.1056Z"
        fill="#B2D1F0"
        stroke="#589AE1"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M66.6172 7.00128L67.0679 1.31152L83.0718 17.3154L77.0543 17.4384L66.6172 7.00128Z"
        fill="#B2D1F0"
      />
      <path
        d="M67.5473 2.62244L81.6839 16.759L77.2937 16.8468L67.2254 6.77854L67.5473 2.62244ZM66.5815 0L66.0137 7.22342L76.8195 18.0293L84.4585 17.8712L66.5815 0Z"
        fill="#589AE1"
      />
      <path
        d="M39.269 69.8809H16.7676V72.5209H39.269V69.8809Z"
        fill="#589AE1"
      />
      <path
        d="M38.6831 70.4666V71.9359H17.3524V70.4666H38.6831ZM39.8538 69.2959H16.1816V73.1066H39.8538V69.2959Z"
        fill="#589AE1"
      />
      <path
        d="M42.7732 78.1468H12.5449V73.1068H52.1274C60.662 73.1068 67.6103 66.2931 67.6103 57.7702C67.6103 50.8102 62.9215 44.6814 56.2015 42.8609C56.2015 42.8609 65.9947 28.5136 68.3947 29.9068C78.1352 35.5848 84.4454 46.1214 84.4454 57.7644C84.4454 69.3429 78.2932 79.5107 69.0796 85.1946C71.1635 88.1039 72.422 91.06 72.4981 94.8941"
        stroke="#589AE1"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
      <path
        d="M88.7671 89.6836H8.23242V95.2914H88.7671V89.6836Z"
        fill="#B2D1F0"
      />
      <path
        d="M88.0581 90.3922V94.5834H8.94002V90.3922H88.0581ZM89.4747 88.9756H7.52344V96H89.4747V88.9756Z"
        fill="#589AE1"
      />
      <path
        d="M65.8439 50.5638C72.3873 50.5638 77.6917 45.2593 77.6917 38.716C77.6917 32.1726 72.3873 26.8682 65.8439 26.8682C59.3005 26.8682 53.9961 32.1726 53.9961 38.716C53.9961 45.2593 59.3005 50.5638 65.8439 50.5638Z"
        fill="#B2D1F0"
        stroke="#589AE1"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M65.8431 43.3454C64.608 43.3454 63.4431 42.8654 62.5709 41.9874C60.768 40.1845 60.768 37.2459 62.5709 35.4372C63.4431 34.565 64.608 34.0791 65.8431 34.0791C67.0783 34.0791 68.2431 34.5591 69.1153 35.4372C70.9183 37.2401 70.9183 40.1786 69.1153 41.9874C68.2431 42.8654 67.0841 43.3454 65.8431 43.3454Z"
        fill="#589AE1"
      />
      <path
        d="M65.8426 34.6707C66.9255 34.6707 67.9382 35.0922 68.705 35.8532C70.2855 37.4337 70.2855 39.9976 68.705 41.578C67.9382 42.3449 66.9255 42.7605 65.8426 42.7605C64.7596 42.7605 63.747 42.339 62.9801 41.578C61.3996 39.9976 61.3996 37.4337 62.9801 35.8532C63.747 35.0922 64.7655 34.6707 65.8426 34.6707ZM65.8426 33.5C64.5079 33.5 63.1733 34.0093 62.1548 35.0278C60.1177 37.0649 60.1177 40.3663 62.1548 42.4034C63.1733 43.422 64.5079 43.9312 65.8426 43.9312C67.1772 43.9312 68.5118 43.422 69.5304 42.4034C71.5674 40.3663 71.5674 37.0649 69.5304 35.0278C68.5177 34.0093 67.1772 33.5 65.8426 33.5Z"
        fill="#589AE1"
      />
      <path
        d="M25.7988 89.7367C26.7296 87.1319 28.2281 84.7962 30.1481 82.8821C30.1715 82.8587 30.1949 82.8353 30.2125 82.8177C33.2974 79.7738 37.512 78.1523 41.8437 78.1523H45.912"
        stroke="#589AE1"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4210_171630">
        <rect width="96" height="96" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default EmptyMyLab;
