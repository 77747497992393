import { Auth } from "aws-amplify";
import get from "lodash/get";
import { GROUPS_TOKEN_PATH, START_PAGE_USERS } from "../../constants";

export const checkAuthUserAppRole = async (
  required_group = START_PAGE_USERS
) => {
  const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
  const access_groups = get(currentAuthenticatedUser, GROUPS_TOKEN_PATH);
  if (
    !Array.isArray(access_groups) ||
    !access_groups.includes(required_group)
  ) {
    throw new Error("You do not have permission to access.");
  }
  return currentAuthenticatedUser;
};
