import { createSelector } from "reselect";
import { emailToName } from "../utils/helpers/text";

export const getUser = (state) => state.user;
export const getUserEmail = createSelector(
  [getUser],
  user => user?.email);

export const displayNameSelector = createSelector(
  [getUser],
  (user) => user?.name || emailToName(user?.email) || "-"
);
