import React from "react";
import WidgetListForBarMeta from "./WidgetListForBarMeta";
import WidgetTableForBar from "./WidgetTableForBar";

const WidgetListForBar = ({
  widgets,
  isReverseOrder,
  setIsReverseOrder,
  filteredData,
  setOrderBy,
  orderBy
}) => {
  return (
    <WidgetTableForBar
      dataTestId="dashboard-widget-table"
      meta={WidgetListForBarMeta}
      widgets={widgets}
      isReverseOrder={isReverseOrder}
      setIsReverseOrder={setIsReverseOrder}
      filteredData={filteredData}
      setOrderBy={setOrderBy}
      orderBy={orderBy}
    />
  );
};

export default WidgetListForBar;
