import { OwcDatepicker } from "@one/react";
import locale from "date-fns/locale/en-US";
import React from "react";

function CustomDatePicker({
  minDate,
  maxDate,
  date,
  setDate,
  clearable = false,
  ...rest
}) {
  return (
    <>
      <OwcDatepicker
        label="Date"
        maxDate={maxDate}
        minDate={minDate}
        style={{ width: "200px" }}
        onValueChange={(e) => setDate(e.detail)}
        format="dd-MMM-yyyy"
        data-testid="date-picker-for-range-in-calendar"
        id="date-picker-for-range-in-calendar"
        locale={{
          ...locale,
          options: {
            ...locale.options,
            weekStartsOn: 1
          }
        }}
        {...rest}
      >
        <span slot="assistive-text">Assistive text</span>
      </OwcDatepicker>
    </>
  );
}

export default CustomDatePicker;
