import { initialState as paginationInitialState } from "../../../components/shared/pagination/PaginationWrapper";
import { LIST_WIDGETS } from "../../../gql/landingapi/queries";
import { getAllData } from "../../../utils/helpers/fetching";

export const fetchWidgets = async ({
  client,
  limit,
  nextToken,
  drillData = false
}) => {
  const { nextToken: token, items } = await getAllData({
    client,
    drillData,
    query: LIST_WIDGETS,
    fetchPolicy: "no-cache",
    dataPath: ["data", "listWidgets"],
    variables: {
      limit: limit || paginationInitialState.limit,
      nextToken
    }
  });

  return {
    items,
    token
  };
};
