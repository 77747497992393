import { AdminPanelContext } from "./context";
import React, { useState } from "react";

export const AdminPanelWrapper = ({ children }) => {
  const [value, setValue] = useState("appAndCategory");

  return (
    <AdminPanelContext.Provider value={{ value, setValue }}>
      {children}
    </AdminPanelContext.Provider>
  );
};
