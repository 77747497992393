import React from "react";
import AdminPanelEditOrDelete from "./AdminPanelEditOrDelete.jsx";
import { changeStringToDate } from "../../../utils/helpers/text";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable.js";

export function metaData({
  setApplication,
  setApplicationToDelete,
  openDialog
}) {
  return {
    fields: {
      name: {
        text: DATA_MODEL_TABLE.applicationName.value,
        sortable: true
      },
      updatedAt: {
        text: DATA_MODEL_TABLE.updatedAt.value,
        sortable: true,

        component: ({ item }) => (
          <span>{changeStringToDate(item?.updatedAt)}</span>
        )
      },
      actions: {
        text: "",
        headProps: {
          style: {
            display: "flex",
            justifyContent: "flex-end"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            justifyContent: "flex-end"
          }
        },
        component: ({ item }) => (
          <AdminPanelEditOrDelete
            item={item}
            setApplication={setApplication}
            setApplicationToDelete={setApplicationToDelete}
            openDialog={openDialog}
          />
        )
      }
    }
  };
}
