import { DELETE_DIGITAL_LAB_LANDING_PAGE_APPLICATION } from "../../../../gql/landingapi/mutations";
import Snackbar from "../../../snackbar/Snackbar";

export function deleteApplicationFactory({
  client,
  loadUserInfoFn,
  data,
  setApplicationToDelete,
  refetch
}) {
  return async (applicationForm) => {
    await client.mutate({
      mutation: DELETE_DIGITAL_LAB_LANDING_PAGE_APPLICATION,
      variables: {
        id: applicationForm.id
      }
    });
    loadUserInfoFn({
      allAdminApps: data.filter((theApp) => {
        if (theApp.id !== applicationForm.id) {
          return true;
        }
        return false;
      })
    });
    Snackbar({
      type: "success",
      icon: "circle_confirm",
      appName: applicationForm.name,
      text: `was successfully removed!`
    });
    setApplicationToDelete(null);
    refetch();
  };
}
