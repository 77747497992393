import { endOfDay, startOfDay } from "date-fns";
import { useContext } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { get } from "underscore";
import CustomSelect from "../../../components/shared/CustomSelect";
import CustomDatePicker from "../../../components/shared/DatePicker";
import { Module } from "../../../constants";
import { SEVERITY_NOTIFICATIONS_BACKEND } from "../../SeverityNotifications";
import { FiltersContext } from "./FiltersContext/context";
import { generateID } from "@digitallab/grid-common-components";

const FiltersBox = styled.div`
  display: flex;
`;

const {notificationName} = Module.PAGE.NOTIFICATION;

const FilterSelect = ({ shortName, label, emptyOption, options, ...props }) => {
  return (
    <CustomSelect
      id={generateID.UUID(notificationName, `filterselect_${shortName}`, "select")}
      selectLabel={label}
      emptyOption
      displayEmpty
      emptyOptionText={emptyOption}
      options={options}
      propValue="name"
      propLabel="name"
      testid={`notifications-filters-${shortName}`}
      {...props}
    />
  );
};

const fnOrValue = (fn) => (x) => x ? fn(x) : x;

const FiltersRow = () => {
  const applications = useSelector((state) => state.user.sources);
  const { startDate, endDate, application, severity, dispatchFiltersAction } =
    useContext(FiltersContext);

  const handleChangeFor =
    (actionName, { path = ["target", "value"], manipulate = (x) => x } = {}) =>
    (event) => {
      dispatchFiltersAction({
        type: "keyChange",
        payload: {
          key: actionName,
          value:
            path === null ? manipulate(event) : get(manipulate(event), path)
        }
      });
    };

  return (
    <FiltersBox 
    id={generateID.UUID(notificationName, `filter`, "filter_box")}
    data-testid="filters-box">
      <FilterSelect
        id={generateID.UUID(notificationName, `filter`, "filter_select")}
        shortName="severity"
        label="Severity type"
        emptyOption="All severities"
        options={Object.values(SEVERITY_NOTIFICATIONS_BACKEND).map((value) => ({
          value
        }))}
        propValue="value"
        propLabel="value"
        value={severity}
        onChange={handleChangeFor("severity")}
      />
      <FilterSelect
        id={generateID.UUID(notificationName, `filter`, "filter_select")}
        shortName="application"
        label="Application/domain"
        emptyOption="All applications"
        options={applications}
        propValue="source"
        propLabel="source"
        value={application}
        onChange={handleChangeFor("application")}
      />
      <CustomDatePicker
        clearable
        emptyLabel="unset"
        date={startDate}
        data-testid={`notifications-filters-date-picker-start`}
        id={`notifications-filters-date-picker-start`}
        label="Start date"
        setDate={handleChangeFor("startDate", {
          path: null,
          manipulate: fnOrValue(startOfDay)
        })}
        maxDate={endDate || undefined}
      />
      <CustomDatePicker
        clearable
        emptyLabel="unset"
        date={endDate}
        data-testid={`notifications-filters-date-picker-end`}
        id={`notifications-filters-date-picker-end`}
        label="End date"
        setDate={handleChangeFor("endDate", {
          path: null,
          manipulate: fnOrValue(endOfDay)
        })}
        minDate={startDate || undefined}
      />
    </FiltersBox>
  );
};
export default FiltersRow;
