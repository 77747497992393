import {
  LaunchStepInfoRow,
  LaunchStepLinkRow,
  LaunchStepNameRow,
  LaunchStepRow,
  LaunchStepActionRow
} from "./applicationEditFormStyles";
import { OwcIconButton } from "@one/react";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";

const LaunchStepList = ({
  validationInputs,
  application,
  setDirtyInputs,
  dirtyInputs,
  setEntryPoint,
  setEntryPointToDelete,
  openDialog
}) => {
  return (
    <div data-testid="application-edit-form-launch-step-list" id={generateID.UUID(Module.PAGE.ADMIN.launchStepList, `launchStepList`, "style")}>
      {/* {!validationInputs.entryPointLength && (
        <OwcTypography
          data-testid="application-edit-form-launch-step-list-no-entrypoints-info"
          variant="body1"
          align="center"
          style={{ color: "var(--one-color-cobas-red-400)" }}
          color="error"
          id={generateID.UUID(Module.PAGE.ADMIN.launchStepList, `launchStepList`, "type")}
        >
          Add at least one entry point to the application
        </OwcTypography>
      )} */}
      {application?.entryPoints?.map((thisEntryPoint, index) => (
        <LaunchStepRow
          key={thisEntryPoint.id}
          data-testid="application-edit-form-launch-step-list-row"
          id={generateID.UUID(Module.PAGE.ADMIN.launchStepList, `launchStepList_${index}`, "row")}
        >
          <LaunchStepInfoRow data-testid="application-edit-form-launch-step-list-info"
          id={generateID.UUID(Module.PAGE.ADMIN.launchStepList, `launchStepList_${index}`, "inforow")}
          >
            <LaunchStepNameRow 
            id={generateID.UUID(Module.PAGE.ADMIN.launchStepList, `launchStepList_${index}`, "name_row")}
            data-testid="application-edit-form-launch-step-list-entryPoint-name">
              {thisEntryPoint.name}
            </LaunchStepNameRow>
            <LaunchStepLinkRow
              id={generateID.UUID(Module.PAGE.ADMIN.launchStepList, `launchStepList_${index}`, "linkrow")}
              href={thisEntryPoint.url}
              rel="noreferrer"
              target="_blank"
              data-testid="application-edit-form-launch-step-list-entryPoint-link"
            >
              {thisEntryPoint.url}
            </LaunchStepLinkRow>
          </LaunchStepInfoRow>
          <LaunchStepActionRow 
          id={generateID.UUID(Module.PAGE.ADMIN.launchStepList, `launchStepList_${index}`, "actionrow")}
          data-testid="application-edit-form-launch-step-list-action-buttons">
            <OwcIconButton
              onClick={() => {
                setDirtyInputs({
                  ...dirtyInputs,
                  entryPoint_url: false,
                  entryPoint_name: false
                });
                setEntryPoint({ ...thisEntryPoint });
              }}
              id={generateID.UUID(Module.PAGE.ADMIN.launchStepList, `launchStepList_${index}`, "icon_edit")}
              data-testid="application-edit-form-launch-step-list-edit-iconbutton"
              flat
              type="legacy"
              icon="edit"
            />
            <OwcIconButton
              onClick={() => {
                setEntryPointToDelete(thisEntryPoint.id);
                openDialog();
              }}
              id={generateID.UUID(Module.PAGE.ADMIN.launchStepList, `launchStepList_${index}`, "icon_delete")}
              data-testi1d="application-edit-form-launch-step-list-delete-iconbutton"
              flat
              type="outlined"
              icon="delete"
            />
          </LaunchStepActionRow>
        </LaunchStepRow>
      ))}
    </div>
  );
};
export default LaunchStepList;
