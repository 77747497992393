import { Auth } from "aws-amplify";
import { get } from "lodash";
import throttle from "lodash/throttle";
import { UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE_FILTER } from "../gql/landingapi/mutations";
import { store } from "../store";
import { VIEW_TYPE, DEFAULT_FILTER } from "../constants";
const extractVariables = (state) => {
  const selected = get(state, "user.selected") || [];
  const viewTypeData = get(state, "user.viewType") || VIEW_TYPE;
  const lastFavFilterData = get(state, "user.lastFavFilter") || DEFAULT_FILTER;
  const id = get(state, "user.id") || "";
  const email = get(state, "user.email") || "";
  const lastFilter = {
    selectedGroup: selected,
    viewType: viewTypeData,
    lastFavFilter: lastFavFilterData
  };
  return {
    id,
    email,
    lastFilter: encodeURI(JSON.stringify(lastFilter))
  };
};

export async function signOut(apolloClient) {
  try {
    if (apolloClient) {
      const _variables = extractVariables(store.getState());
      process.env.NODE_ENV === "development" &&
        (await apolloClient.mutate({
          mutation: UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE_FILTER,
          variables: _variables
        }));
    }
    await Auth.signOut({ global: true });
    window.location.reload();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

let _initialize = false;

export const subscribeToFilterChange = (apolloClient) => {
  if (!_initialize) {
    _initialize = true;
    store.subscribe(
      throttle(
        async () => {
          const _variables = extractVariables(store.getState());
          await apolloClient.mutate({
            mutation: UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE_FILTER,
            variables: _variables
          });
          process.env.NODE_ENV === "development" &&
            console.log("filter saved!", _variables);
        },
        5000,
        { leading: false }
      )
    );
  }
};
