import { useEffect } from "react";
import { getLatestNotification } from "../helpers/selectors";
import { withSystemNotification } from "./SystemNotificationsWrapper";
const SystemNotificationsUpdate = ({
  notifications,
  severityCounter,
  notify
}) => {
  useEffect(() => {
    const latestNotification = getLatestNotification(notifications)?.[0];
    if (latestNotification) {
      notify({
        type: latestNotification?.severity,
        payload: {
          id: latestNotification?.id,
          source: latestNotification?.source,
          counter: severityCounter,
          message: latestNotification?.message
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications?.length]);
  return null;
};

export default withSystemNotification(SystemNotificationsUpdate);
