import React, { useCallback, useContext, useEffect } from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { Auth } from "aws-amplify";
import {
  LIST_APPLICATION_GROUPS,
  LIST_APPLICATIONS,
  LIST_DASHBOARDS,
  LIST_DIGITAL_LAB_LANDING_PAGE_USER_FILTER,
  LIST_NOTIFICATION_BANNER,
  GET_LOGGED_USER_DETAILS
} from "../../gql/landingapi/queries";
import { loadUserInfo as loadUser } from "./redux/actions";
import { getAllData } from "../../utils/helpers/fetching";
import { getSelectedEquipment } from "../../features/equipments/update-equipment";
import {
  CREATE_DASHBOARD,
  CREATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE,
  UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE,
  UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE_FIELDS,
  UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE_RECENT_APP_USAGE
} from "../../gql/landingapi/mutations";
import { isEqual, pick } from "lodash";
import styled from "styled-components";
import { signOut } from "../../utils/signout";
import SubscriptionFilter from "../../utils/SubscriptionFilter";
import {
  START_PAGE_USERS,
  SYNC_PROFILE_FIELDS,
  ACCESS_TOKEN_PATH,
  VIEW_TYPE,
  NOTIFICATION_BANNER_INITIALS,
  DEFAULT_MYLAB_FILTER,
  DEFAULT_FILTER
} from "../../constants";
import { getCustomGroups } from "./getCustomGroup";
import { SiteContext } from "../siteChange/context";
import { omit } from "underscore";
import { useLoadState } from "../../utils/hooks/useLoadState";
import AppLoadState from "../../components/shared/AppLoadState";
import { checkAuthUserAppRole } from "../../utils/helpers/checkAuthUserAppRole";
const { v4: uuidv4 } = require("uuid");

export const FullScreenCentered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  width: 100vw;
  height: 100vh;
`;

const stripPhoneNumber = (phoneNumber) =>
  phoneNumber?.replace(/[^+\d]/gi, "") ?? "";

const checkRoleAndGetUserEmail = async (required_group, selectedSite) => {
  const currentAuthenticatedUser = await checkAuthUserAppRole(required_group);
  let syncFields = pick(
    get(currentAuthenticatedUser, ACCESS_TOKEN_PATH),
    Object.values(SYNC_PROFILE_FIELDS)
  );
  if (!syncFields?.[SYNC_PROFILE_FIELDS.site]) {
    syncFields = {
      ...syncFields,
      [SYNC_PROFILE_FIELDS.site]: selectedSite?.siteName
    };
  }
  if (syncFields?.[SYNC_PROFILE_FIELDS.phone]) {
    syncFields[SYNC_PROFILE_FIELDS.phone] = stripPhoneNumber(
      syncFields?.[SYNC_PROFILE_FIELDS.phone]
    );
  }
  const email = get(currentAuthenticatedUser, "attributes.email");
  return { email, ...syncFields };
};

export const updateProfileFields = async ({
  client,
  email,
  user,
  ...syncFields
}) => {
  const syncKeys = Object.keys(SYNC_PROFILE_FIELDS);
  const profileObj = syncKeys.reduce(
    (acc, currKey) => ({
      ...acc,
      [currKey]: syncFields?.[SYNC_PROFILE_FIELDS?.[currKey]] ?? ""
    }),
    {}
  );
  if (!Object.keys(user).length) {
    return {
      ...profileObj
    };
  }
  const shouldUpdate = !isEqual(pick(user, syncKeys), profileObj);
  if (!shouldUpdate) {
    return user;
  }
  const {
    data: { updateDigitalLabLandingPageUserProfile: updatedUser }
  } = await client.mutate({
    mutation: UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE_FIELDS,
    fetchPolicy: "no-cache",
    variables: {
      id: user?.id,
      email,
      ...profileObj
    }
  });
  return updatedUser;
};

export const getDigitalLabLandingPageUserProfile = async ({
  client,
  query_email = GET_LOGGED_USER_DETAILS
}) => {
  const digitalDigitalLabLandingPageUserProfile = await getAllData({
    query: query_email,
    client,
    dataPath: ["data", "getLoggedInUserDetails"],
    drillData: true
  });
  return {
    digitalLabLandingPageUserProfile:
      digitalDigitalLabLandingPageUserProfile?.items[0] || {}
  };
};
export const getAllApplications = async ({ client, query_apps, allGroups }) => {
  let access_groups = getCustomGroups(
    get(await Auth.currentSession(), "idToken.payload.custom:groups") || []
  );
  access_groups = access_groups.length ? access_groups : ["NOT_EXIST"];
  const {
    data: {
      listDigitalLabLandingPageApplications: { items: allApps = [] }
    }
  } = await client.query({
    query: query_apps,
    fetchPolicy: "no-cache",
    variables: {
      roleFilter: access_groups.map((group) => ({
        allowedRoles: { contains: group }
      }))
    }
  });
  const newAllApps = allApps.map((app) => ({
    ...app,
    applicationGroupIDs: app.applicationGroupIDs.filter((group) => {
      return allGroups.find((theGroup) => theGroup.id === group);
    })
  }));
  return {
    allApps: newAllApps
  };
};
export const getAllGroups = async ({ client, query_groups }) => {
  const {
    data: {
      listDigitalLabLandingPageApplicationGroups: { items: allGroups = [] }
    }
  } = await client.query({
    query: query_groups,
    fetchPolicy: "no-cache"
  });

  return {
    allGroups
  };
};

const getDashboardInfo = async ({ client, query_dashboard }) => {
  // dashboard not showing issue fix 12-01-2023
  const { items: dashboards = [] } = await getAllData({
    query: query_dashboard,
    client,
    dataPath: ["data", "listDashboards"],
    drillData: true
  });

  if (dashboards.length < 1) {
    const idDashboard = uuidv4();
    await client.mutate({
      mutation: CREATE_DASHBOARD,
      variables: {
        id: idDashboard,
        widgets: []
      }
    });
    dashboards.push({ id: idDashboard, widgets: [] });
  }

  const [dashboard] = dashboards.map((d) =>
    omit(
      {
        ...d,
        widgets: d.widgets.map((w) =>
          omit(
            {
              ...w,
              size: omit(w.size, "__typename")
            },
            "__typename"
          )
        )
      },
      "__typename"
    )
  );

  return {
    dashboard
  };
};

const createDigitalLabLandingPageUserProfile = async ({
  client,
  email,
  query_create,
  ...profileFieldsOrUpdatedUser
}) => {
  const newDigitalLabLandingPageUserProfile = await client.mutate({
    mutation: query_create,
    variables: {
      email,
      ...profileFieldsOrUpdatedUser,
      name: profileFieldsOrUpdatedUser.name.toLowerCase(),
      applicationSets: [
        {
          id: uuidv4(),
          name: "favourites",
          icon: "star_filled",
          applicationReferences: []
        }
      ]
    }
  });
  const _createDigitalLabLandingPageUserProfile = get(
    newDigitalLabLandingPageUserProfile,
    "data.createDigitalLabLandingPageUserProfile"
  );
  const { id, applicationSets = [] } = _createDigitalLabLandingPageUserProfile;
  return { id, applicationSets };
};

const getUserFilter = async ({ client, query_user_filter }) => {
  const userFilterData = await getAllData({
    query: query_user_filter,
    client,
    dataPath: ["data", "getLoggedInUserDetails"],
    drillData: true
  });

  const userFilters = userFilterData?.items[0]?.userFilters?.items || [];

  userFilters.forEach((item, index, items) => {
    if (typeof item?.filterDefinition === "string") {
      items[index]["filterDefinition"] = JSON.parse(item?.filterDefinition);
    }
  });
  return {
    userFilters
  };
};
export const getFavouriteEquipments = async ({ client, site }) => {
  const { favEquipsWithIds: favEquipments } = await getSelectedEquipment({
    client,
    siteName: site
  });

  return {
    favouriteEquipments: favEquipments
  };
};

const updateDeletedAppsInAppsSets = async ({
  client,
  id,
  email,
  applicationSets,
  allApps
}) => {
  let hasChanged = false;
  const newApplicationSets = applicationSets.map(({ __typename, ...set }) => ({
    ...set,
    applicationReferences: set.applicationReferences
      .filter((ref) => {
        if (
          allApps.some(
            (app) =>
              app.id === ref.applicationID &&
              app.entryPoints.some(
                (entryPoint) => entryPoint.id === ref.applicationEntryPointID
              )
          )
        ) {
          return true;
        }

        hasChanged = true;
        return false;
      })
      .map(({ __typename: typename, ...ref }) => ref)
  }));

  if (!hasChanged) {
    return applicationSets;
  }

  const updatedDigitalLabLandingPageUserProfile = await client.mutate({
    mutation: UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE,
    variables: { id, email, applicationSets: newApplicationSets }
  });
  return get(
    updatedDigitalLabLandingPageUserProfile,
    "data.updateDigitalLabLandingPageUserProfile.applicationSets"
  );
};

const updateDeletedAppsInRecentApps = async ({
  client,
  id,
  email,
  recentApplicationUsage,
  allApps
}) => {
  const newRecentApplicationUsage = recentApplicationUsage
    .filter(({ __typename, ...recentUsage }) =>
      allApps.some(
        (app) =>
          app.id === recentUsage.applicationRef.applicationID &&
          app.entryPoints.some(
            (entryPoint) =>
              entryPoint.id ===
              recentUsage.applicationRef.applicationEntryPointID
          )
      )
    )
    .map(({ __typename, ...recentUsage }) => ({
      ...recentUsage,
      applicationRef: {
        applicationID: recentUsage.applicationRef.applicationID,
        applicationEntryPointID:
          recentUsage.applicationRef.applicationEntryPointID
      }
    }));

  if (newRecentApplicationUsage.length === recentApplicationUsage.length) {
    return recentApplicationUsage;
  }

  const updatedDigitalLabLandingPageUserProfile = await client.mutate({
    mutation: UPDATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE_RECENT_APP_USAGE,
    variables: {
      id,
      email,
      recentApplicationUsage: newRecentApplicationUsage
    }
  });
  return get(
    updatedDigitalLabLandingPageUserProfile,
    "data.updateDigitalLabLandingPageUserProfile.recentApplicationUsage"
  );
};

// eslint-disable-next-line no-unused-vars
const deleteDigitalLabLandingPageUserProfile = async ({
  client,
  query_delete,
  id,
  email
}) =>
  client.mutate({
    mutation: query_delete,
    variables: {
      id,
      email
    }
  });

const defaultSelectedGroup = {
  id: "",
  name: "",
  type: "",
  accordionState: {
    adminpanel: false,
    quickAccess: false,
    dashboard: false,
    myequipments: false,
    alltools: false,
    groupcategories: false
  }
};

const getLastFilter = (filter) => {
  try {
    let savedData = pick(JSON.parse(decodeURI(filter)), [
      "selectedGroup",
      "viewType",
      "lastFavFilter"
    ]);
    if (!savedData?.selectedGroup?.accordionState) {
      savedData.selectedGroup.accordionState =
        defaultSelectedGroup.accordionState;
    }
    if (!savedData?.viewType) {
      savedData.viewType = VIEW_TYPE;
    }
    if (!savedData?.lastFavFilter) {
      savedData.lastFavFilter = DEFAULT_MYLAB_FILTER;
    }
    if (savedData?.lastFavFilter?.filterName === DEFAULT_FILTER.filterName) {
      savedData.lastFavFilter = DEFAULT_MYLAB_FILTER;
    }
    return savedData;
  } catch (err) {
    console.warn(err);
    return { selectedGroup: defaultSelectedGroup };
  }
};

const getNotificationBanner = async (client) => {
  const {
    data: {
      listNotificationBanners: { items: NotificationBanner = [] }
    }
  } = await client.query({
    query: LIST_NOTIFICATION_BANNER,
    fetchPolicy: "no-cache"
  });
  if (NotificationBanner[0]) {
    return { ...NotificationBanner[0] };
  } else {
    return NOTIFICATION_BANNER_INITIALS;
  }
};

const loadLandingPageUserInfo = async ({
  selectedSite,
  client,
  loadUserInfo,
  dispatchAction
}) => {
  const { email, ...syncFields } = await checkRoleAndGetUserEmail(
    START_PAGE_USERS,
    selectedSite
  );
  const { digitalLabLandingPageUserProfile } =
    await getDigitalLabLandingPageUserProfile({
      client,
      query_email: GET_LOGGED_USER_DETAILS
    });
  let digitalEmail = digitalLabLandingPageUserProfile?.email || email;
  if (!digitalEmail) {
    return signOut();
  }
  const profileFieldsOrUpdatedUser = await updateProfileFields({
    client,
    email: digitalEmail,
    user: digitalLabLandingPageUserProfile,
    ...syncFields
  });

  let id;
  let applicationSets = [];
  let recentApplicationUsage = [];
  let lastFilter = "";
  let user,
    name = "",
    site = "";

  if (!Object.keys(digitalLabLandingPageUserProfile).length) {
    const profile = await createDigitalLabLandingPageUserProfile({
      client,
      email,
      query_create: CREATE_DIGITAL_LAB_LANDING_PAGE_USER_PROFILE,
      ...profileFieldsOrUpdatedUser
    });
    id = profile.id;
    applicationSets = profile.applicationSets;
    user = profile.user;
    site = profile?.site || selectedSite?.siteName;
  } else {
    id = profileFieldsOrUpdatedUser.id;
    applicationSets = profileFieldsOrUpdatedUser.applicationSets ?? [];
    recentApplicationUsage =
      profileFieldsOrUpdatedUser.recentApplicationUsage ?? [];
    lastFilter = profileFieldsOrUpdatedUser.lastFilter ?? "";
    user = profileFieldsOrUpdatedUser.user;
    name = profileFieldsOrUpdatedUser.name;
    site = profileFieldsOrUpdatedUser.site;
  }
  const { allGroups } = await getAllGroups({
    client,
    query_groups: LIST_APPLICATION_GROUPS
  });
  const { dashboard: dashboardMyVar } = await getDashboardInfo({
    client,
    query_dashboard: LIST_DASHBOARDS
  });
  const { allApps } = await getAllApplications({
    client,
    allGroups,
    query_apps: LIST_APPLICATIONS
  });

  const newApplicationSets = await updateDeletedAppsInAppsSets({
    client,
    id,
    email: digitalEmail,
    allApps,
    applicationSets
  });
  const newRecentApplicationUsage = await updateDeletedAppsInRecentApps({
    client,
    id,
    email: digitalEmail,
    allApps,
    recentApplicationUsage
  });

  const { userFilters } = await getUserFilter({
    client,
    query_user_filter: LIST_DIGITAL_LAB_LANDING_PAGE_USER_FILTER
  });

  const { favouriteEquipments } = await getFavouriteEquipments({
    client,
    site
  });

  const {
    selectedGroup: selected,
    viewType,
    lastFavFilter
  } = getLastFilter(lastFilter);

  const NotificationBanner = await getNotificationBanner(client);
  loadUserInfo({
    applicationSets: newApplicationSets,
    recentApplicationUsage: newRecentApplicationUsage,
    selected: selected ? selected : defaultSelectedGroup,
    id,
    email: digitalEmail,
    viewType,
    userFilters,
    favouriteEquipments,
    lastFavFilter,
    allGroups,
    allApps,
    user,
    NotificationBanner,
    name,
    lastFilter,
    dashboard: dashboardMyVar,
    site
  });
  dispatchAction({
    type: "loading",
    payload: false
  });
};

const DigitalLabLandingPageUserProfile = ({
  children,
  client,
  loadUserInfo
}) => {
  const { loading, error, retry, dispatchAction } = useLoadState();
  const { selected: selectedSite } = useContext(SiteContext);

  useEffect(() => {
    const loadData = async () => {
      dispatchAction({
        type: "loading",
        payload: true
      });
      try {
        await loadLandingPageUserInfo({
          selectedSite,
          client,
          loadUserInfo,
          dispatchAction
        });
      } catch (err) {
        console.warn(err);
        dispatchAction({
          type: "error",
          payload: err
        });
      }
    };
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadUserInfo, client, retry]);
  const onRetry = useCallback(
    () => dispatchAction({ type: "retry" }),
    [dispatchAction]
  );
  return (
    <AppLoadState
      loading={loading}
      error={error}
      onLogout={signOut}
      onRetry={onRetry}
    >
      <SubscriptionFilter>{children}</SubscriptionFilter>
    </AppLoadState>
  );
};

export default compose(
  connect(null, {
    loadUserInfo: loadUser
  }),
  withApollo
)(DigitalLabLandingPageUserProfile);
