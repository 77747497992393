import {
  checkValidInput,
  checkValidInputWithNewline,
  checkValidURL
} from "../../../../utils/helpers/text";

export const validateInputsFactory = ({
  application,
  entryPoint,
  setValidationInputs,
  applications,
  setUniqueNameApp,
  onValid,
  onInvalid
}) => {
  return () => {
    const newValid = {
      name: checkValidInput(application.name),
      description:
        !application.description ||
        application.description.length === 0 ||
        checkValidInputWithNewline(application.description),
      entryPoint_name: checkValidInput(entryPoint.name),
      entryPoint_url: checkValidURL(entryPoint.url),
      entryPointLength: application.entryPoints.length > 0
    };
    setValidationInputs(newValid);
    let isUniqueValid = false;
    if (
      applications.some(
        (app) => app.name === application.name && app.id !== application.id
      )
    ) {
      setUniqueNameApp(false);
    } else {
      isUniqueValid = true;
      setUniqueNameApp(true);
    }
    const { entryPoint_name, entryPoint_url, ...validApplication } = newValid;
    if (
      Object.values(validApplication).every((isValid) => isValid) &&
      isUniqueValid
    ) {
      onValid();
    } else {
      onInvalid();
    }
  };
};
