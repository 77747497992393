import * as R from "ramda";
import { at } from "lodash/fp";
import { DAYS, MONTHS } from "../../../constants";
import { uniqBy } from "lodash";

export const emailToName = (email) =>
  typeof email === "string" ? email.split("@")[0].replace(/\./g, " ") : "";

export const checkValidInput = (input) => {
  return /^[ \p{L}0-9_\-:!@`#$%^&*'(),.?";{}|<>\\/+=[\]]+$/u.test(input);
};

export const checkWhitespace = (input) => input?.trim().length !== 0;

export const checkNoTabsAndNewline = (input) =>
  input?.length > 0 &&
  checkWhitespace(input) &&
  !input.includes("\t") &&
  !input.includes("\n");

export const checkValidInputWithNewlineAnyChar = (input) => {
  return (
    input?.length > 0 && checkWhitespace(input) && input.split("\n").length <= 6
  );
};

export const checkValidInputWithNewline = (input) => {
  return (
    /^[ \p{L}0-9_\-:!@`#$%^&*'(),.?";{}|<>\\/+=[\]\n]+$/u.test(input) &&
    input.split("\n").length <= 6
  );
};

export const checkValidURL = (input) => {
  return /^((https?):\/\/)[-A-Za-z0-9+&@#/%?=~_|!:,;][-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]$/.test(
    input
  );
};

export const getText = (text) =>
  typeof text === "string" ? R.compose(R.toLower, R.trim)(text) : "";

export const filterByQuery = (
  data,
  query,
  propFilter = ["instrumentName", ["serialNumber"]],
  propSort = ["instrumentName"],
  reverse = false
) => {
  if (!query) {
    R.sort(R.descend(R.path(propSort)))(data);
  }
  const _query = getText(query);
  const valuesAt = at(propFilter);
  const result = R.compose(
    R.sort(R.descend(R.path(propSort))),
    R.filter(
      R.compose(R.contains(_query), getText, R.compose(R.join(""), valuesAt))
    )
  )(data);

  return reverse ? result.reverse() : result;
};

export const filterFilters = (
  data,
  filters,
  getValue = (item, key) => item[key]
) => {
  return data.filter((item) => {
    return Object.keys(filters)
      .filter((key) => filters[key] !== "" && filters[key].length !== 0)
      .every((key) => {
        const value = getValue(item, key);
        if (Array.isArray(filters[key]) && filters[key].includes(value)) {
          return true;
        }
        return filters[key] === value;
      });
  });
};

export const getLocationText = (itemWithLocationDetails) => {
  const { location } = itemWithLocationDetails ?? {};

  return location && location !== "null" ? location : "-";
};

export const sortData = (data, keys, reverse = false) => {
  return [...data].sort((a, b) => {
    for (const key of keys) {
      const aRawValue = a[key] ?? "";
      const bRawValue = b[key] ?? "";
      const aValue = `${aRawValue}`.toLowerCase();
      const bValue = `${bRawValue}`.toLowerCase();

      const result = (reverse ? 1 : -1) * aValue.localeCompare(bValue);

      if (result !== 0) {
        return result;
      }
    }

    return 0;
  });
};

export const getListOfItemsByKey = (
  instruments,
  key,
  getValue = (item, _key) => item[_key]
) => {
  const Sortinstruments = sortData(instruments, [key], true);
  return Sortinstruments.map((instrument) => getValue(instrument, key))
    .filter((v, i, a) => a.indexOf(v) === i)
    .filter((v) => v);
};

export const omitNullStringFromList = (list, key) => {
  return list.filter(
    (v, i) => v[key].trim() !== null && v[key].trim() !== "null"
  );
};

export const changeStringToDate = (inputDate) => {
  const dateObj = new Date(inputDate).toUTCString();
  const splitDateFormat = dateObj?.split(" ");
  return (
    splitDateFormat[1] + "-" + splitDateFormat[2] + "-" + splitDateFormat[3]
  );
};

export const changeDateFormat = (inputDate, format = null) => {
  try {
    if (typeof inputDate === "string") {
      if (format === null) {
        const dateObj = new Date(inputDate).toUTCString();
        const splitDateFormat = dateObj?.split(" ");
        const currentDate =
          splitDateFormat[1] +
          "-" +
          splitDateFormat[2] +
          "-" +
          splitDateFormat[3];
        const FormattedDateObj = new Date(currentDate);
        return FormattedDateObj;
      }
      if (format === "dd-MMM-yyyy" || format === "DD-MMM-YYYY") {
        const dateObj = new Date(inputDate).toUTCString();
        const splitDateFormat = dateObj?.split(" ");
        const currentDate =
          splitDateFormat[1] +
          "-" +
          splitDateFormat[2] +
          "-" +
          splitDateFormat[3];
        return currentDate;
      }
      if (format === "mm/dd/yyyy h:mm:ss") {
        const dateObjString = new Date(inputDate).toUTCString();
        let dateArray = inputDate.split("T");
        dateArray = dateArray[0].split("-");
        const splitDateFormat = dateObjString?.split(" ");
        const currentDate =
          parseInt(dateArray[1]) +
          "/" +
          dateArray[2] +
          "/" +
          splitDateFormat[3];
        const timeVal = inputDate.split("T");
        const time = timeVal[1].split(":");
        let hours = parseInt(time[0]);
        const seconds = time[2].slice(0, 2);
        let mid = " AM";
        if (hours === 12) {
          mid = " PM";
        }
        if (hours > 12) {
          hours = hours % 12;
          mid = " PM";
          if (hours === 0) {
            mid = " AM";
          }
        }
        if (hours < 10) {
          hours = "0" + hours;
        }
        return currentDate + ", " + hours + ":" + time[1] + ":" + seconds + mid;
      }
      if (format === "h:mm") {
        let timeVal = inputDate.split("T");
        let time = timeVal[1].split(":");
        let hours = parseInt(time[0]);

        let mid = " am";
        if (hours === 12) {
          mid = " pm";
        }
        if (hours > 12) {
          hours = hours % 12;
          mid = " pm";
          if (hours === 0) {
            mid = " am";
          }
        }
        return hours + ":" + time[1] + mid;
      }
      if (format === "ISOToDate" || format === "ISOToDateDefaultTime") {
        const timeVal = inputDate.split("T");
        const date = timeVal[0].split("-");
        const month = parseInt(date[1]) - 1;
        const time = timeVal[1].split(":");
        const hours = parseInt(time[0]);
        const dateObj = new Date(date[2] + "-" + MONTHS[month] + "-" + date[0]);
        if ("ISOToDateDefaultTime") {
          return dateObj;
        }
        dateObj.setTime(dateObj.getTime() + hours * 60 * 60 * 1000);
        return dateObj;
      }
    } else {
      if (format === "EEEE, dd-MMM-yyyy") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const weekDay = inputDate.getDay();
        const monthVal = inputDate.getMonth();
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let year = splitDateFormat[2];

        return (
          DAYS[weekDay] +
          ", " +
          day +
          "-" +
          MONTHS[monthVal] +
          "-" +
          year +
          " ."
        );
      }
      if (
        format === "DD-MMM-YYYY" ||
        format === "dd-MMM-yyyy" ||
        format === "DD-MMM-YYYY h:mm"
      ) {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const monthVal = inputDate.getMonth();
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let year = splitDateFormat[2];

        let time = splitDate[1].trim()?.split(":");
        let hours = parseInt(time[0]);

        let mid = " am";
        if (hours === 12) {
          mid = " pm";
        }
        if (hours > 12) {
          hours = hours % 12;
          mid = " pm";
          if (hours === 0) {
            mid = " am";
          }
        }
        if (format === "DD-MMM-YYYY h:mm") {
          return (
            day +
            "-" +
            MONTHS[monthVal] +
            "-" +
            year +
            " " +
            hours +
            ":" +
            time[1] +
            mid
          );
        }

        return day + "-" + MONTHS[monthVal] + "-" + year;
      }
      if (format === "yyyy-MM-dd") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        var month = splitDateFormat[1];
        let year = splitDateFormat[2];

        return year + "-" + month + "-" + day;
      }
      if (format === "ISO") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        let isoVal = inputDate.toTimeString();
        isoVal = isoVal.split(" ");
        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let month = splitDateFormat[1];
        let year = splitDateFormat[2];

        const isoString =
          year + "-" + month + "-" + day + "T" + isoVal[0] + ".000Z";
        return isoString;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const envs = ["dev", "test", "staging", "stage", "hotfix"];

export const getEnv = () => {
  if (envs?.includes(process.env?.REACT_APP_ENV)) {
    if (
      process.env?.REACT_APP_ENV === "staging" ||
      process.env?.REACT_APP_ENV === "stage"
    ) {
      return "stg";
    } else {
      return process.env?.REACT_APP_ENV;
    }
  } else {
    return null;
  }
};

export const getEnvLebel = (currentEnv) => {
  switch (currentEnv) {
    case "hotfix":
      return "Test Hotfix Environment";
    case "test":
      return "Test Environment";
    case "stg":
      return "Staging Environment";
    case "dev":
      return "Development Environment";
    default:
      return "";
  }
};

export const checkEnvReturnValue = (currentEnv, returnVal) => {
  if (
    currentEnv === "hotfix" ||
    currentEnv === "test" ||
    currentEnv === "stg" ||
    currentEnv === "dev"
  ) {
    return returnVal[0];
  } else {
    return returnVal[1];
  }
};

export const getHashCommitValue = (url) => {
  const fragments = url.split("/");
  return fragments[fragments.length - 1].toUpperCase();
};

export const scrollAvailability = () => {
  const currLocation = window.location.href.split("/");
  const scrollAvailability =
    currLocation[currLocation.length - 1] === "info" ? "auto" : "";
  return scrollAvailability;
};

/**
 * Getting cluster popover informations .
 * @param {Object} clusterData - The object/cluster info is to generate formatted json.
 * @param {Object} clusterDetailsColumns - The object is a base object structure for cluster detail.
 * @param {Object} clusterSubEquipmentsObj - The object is a base object structure for cluster subequipment.
 * @param {Object} options - The object is for display different section display option for the popover.
 * @param {string} clusterDetailHeading - The string is for display cluster Detail Heading String.
 * @param {string} clusterSubEquipmentsHeading - The string is for display cluster SubEquipments Heading String.
 */
export const getClusterPopoverInfo = (
  clusterData,
  clusterDetailsColumns,
  clusterSubEquipmentsObj,
  options,
  clusterDetailHeading,
  clusterSubEquipmentsHeading
) => {
  const equipmentInfo = {
    equipmentDetail: {
      id: clusterData.id ? clusterData.id : clusterData?.inventoryId,
      clusterSubEquipmentsHeading,
      detailHeading: clusterDetailHeading,
      options
    }
  };

  const clusterSubEquipmentsData = clusterData?.subEquipment?.items.map(
    (equipment, index) => {
      const subEquipmentRow = {};
      for (const [key] of Object.entries(clusterSubEquipmentsObj)) {
        if (key === "index") {
          subEquipmentRow[key] = index + 1;
        } else if (key === "qualificationStatus") {
          subEquipmentRow[key] =
            equipment[key] !== null && equipment[key] !== "null"
              ? equipment[key] !== "NA"
                ? equipment[key].charAt(0).toUpperCase() +
                  equipment[key].slice(1).toLowerCase()
                : equipment[key]
              : "-";
        } else {
          subEquipmentRow[key] =
            equipment[key] !== null && equipment[key] !== "null"
              ? equipment[key]
              : "-";
        }
      }
      return subEquipmentRow;
    }
  );

  equipmentInfo.equipmentDetail.clusterSubEquipments = [
    clusterSubEquipmentsObj,
    ...clusterSubEquipmentsData
  ];
  equipmentInfo.equipmentDetail.detail = {
    ...clusterDetailsColumns
  };
  Object.keys(equipmentInfo.equipmentDetail.detail).forEach((key) => {
    if (clusterData.hasOwnProperty(key)) {
      equipmentInfo.equipmentDetail.detail[key].value =
        clusterData[key] !== null && clusterData[key] !== "null"
          ? clusterData[key]
          : "-";
    }
  });
  return equipmentInfo;
};
export const convertToSnakeCase = (value) => {
  return value
    .replace(/([A-Z])/g, "_$1")
    .trim()
    ?.toLowerCase();
};

export const convertToCameCase = (str) => {
  if (!str?.includes("_")) {
    return str;
  }
  let arr = str.split("_");

  return arr
    .map((value, index) => {
      return index === 0
        ? value
        : value.charAt(0).toUpperCase() + value.slice(1);
    })
    ?.join("");
};

export const onlyUnique = (list) => {
  return [...new Set(list)];
};

export const uniqList = (items, fieldName) => {
  return uniqBy(items, fieldName);
};

export const getUniqValuesWithCaseInsensitive = (values) => {
  let uniqueValues = [];
  let repeatedValuesCaseInSensitive = [];
  values?.forEach((value) => {
    if (!repeatedValuesCaseInSensitive?.includes(value?.toLowerCase())) {
      uniqueValues.push(value);
      repeatedValuesCaseInSensitive.push(value?.toLowerCase());
    }
  });
  return uniqueValues;
};
