export function updateNameUniqueFactory(
  customGroups,
  customGroup,
  setIsNameUnique
) {
  return (groupName) => {
    if (
      customGroups.some(
        (group) => group.name === groupName && customGroup.id !== group.id
      )
    ) {
      setIsNameUnique(false);
    } else {
      setIsNameUnique(true);
    }
  };
}
