export const createNotificationCallback = (prev, subscriptionData) => {
  const newDigitalLabLandingPageNotification =
    subscriptionData.data?.onCreateDigitalLabLandingPageNotification;
  return {
    ...prev,
    listDigitalLabLandingPageNotifications: {
      ...prev.listDigitalLabLandingPageNotifications,
      items: [
        newDigitalLabLandingPageNotification,
        ...prev.listDigitalLabLandingPageNotifications?.items
      ]
    }
  };
};

export const updateNotificationCallback = (prev, subscriptionData) => {
  const updatedDigitalLabLandingPageNotification =
    subscriptionData.data?.onUpdateDigitalLabLandingPageNotification;
  return {
    ...prev,
    listDigitalLabLandingPageNotifications: {
      ...prev.listDigitalLabLandingPageNotifications,
      items: prev.listDigitalLabLandingPageNotifications?.items?.map((item) =>
        item?.id === updatedDigitalLabLandingPageNotification?.id
          ? {
              ...item,
              createdAt: updatedDigitalLabLandingPageNotification?.createdAt,
              details: updatedDigitalLabLandingPageNotification?.details,
              dueDate: updatedDigitalLabLandingPageNotification?.dueDate,
              expiresAt: updatedDigitalLabLandingPageNotification?.expiresAt,
              message: updatedDigitalLabLandingPageNotification?.message,
              link: updatedDigitalLabLandingPageNotification?.link,
              time: updatedDigitalLabLandingPageNotification?.time,
              payload: updatedDigitalLabLandingPageNotification?.payload,
              sender: updatedDigitalLabLandingPageNotification?.sender,
              severity: updatedDigitalLabLandingPageNotification?.severity,
              source: updatedDigitalLabLandingPageNotification?.source,
              type: updatedDigitalLabLandingPageNotification?.type,
              updatedAt: updatedDigitalLabLandingPageNotification?.updatedAt,
              user: updatedDigitalLabLandingPageNotification?.user
            }
          : item
      )
    }
  };
};

export const deleteNotificationCallback = (prev, subscriptionData) => {
  const deletedDigitalLabLandingPageNotification =
    subscriptionData.data?.onDeleteDigitalLabLandingPageNotification;
  return {
    ...prev,
    listDigitalLabLandingPageNotifications: {
      ...prev.listDigitalLabLandingPageNotifications,
      items: prev.listDigitalLabLandingPageNotifications?.items?.filter(
        (item) => item?.id !== deletedDigitalLabLandingPageNotification?.id
      )
    }
  };
};
