import React, { useContext, useState } from "react";
import { withApollo } from "react-apollo";
import ConfirmDialog from "../../../components/shared/ConfirmDialog";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import useDialog from "../../../utils/hooks/useDialog";
import { deleteWidget } from "./delete-widget";
import ChangeWidgetWorkflow from "./widgetsForWidgets/ChangeWidgetWorkflow";
import { OwcIconButton } from "@one/react";
import { Menu, MenuItem } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

const Actions = ({ item, client }) => {
  const [widget, setWidget] = useState(null);
  const [startFrom, setStartFrom] = useState(1);
  const { openDialog, ...dialogProps } = useDialog();
  const { dispatchAction } = useContext(PaginationContext);
  const refresh = () => dispatchAction({ type: "refresh" });
  const widgetsFromStore = useSelector(
    (state) => state.user?.dashboard?.widgets
  );
  const dispatch = useDispatch();
  const handleDelete = async () => {
    await deleteWidget(client, item, widgetsFromStore, dispatch);
    refresh();
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const moreIconItems = [
    {
      name: "Refresh",
      componentIcon: <OwcIconButton type="outlined" flat icon="refresh" />
    },
    {
      name: "Edit",
      componentIcon: <OwcIconButton type="outlined" flat icon="edit" />
    },
    {
      name: "Delete",
      componentIcon: <OwcIconButton type="outlined" flat icon="delete" />
    }
  ];

  const handleOnMenuClick = (menuItem, item) => {
    setAnchorEl(null);
    switch (menuItem.name) {
      case "Refresh":
        setWidget(item);
        setStartFrom(1);
        break;
      case "Edit":
        setWidget(item);
        setStartFrom(2);
        break;
      case "Delete":
        openDialog();
        break;
      default:
        break;
    }
  };

  const itemList = moreIconItems.map((menuItem) => {
    return (
      <MenuItem onClick={() => handleOnMenuClick(menuItem, item)}>
        {menuItem.componentIcon} {menuItem.name}
      </MenuItem>
    );
  });

  return (
    <>
      <OwcIconButton
        type="outlined"
        flat
        icon="more_vertical"
        onClick={handleClick}
      />
      <Menu
        data-testid="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        {itemList}
      </Menu>
      <ConfirmDialog
        {...dialogProps}
        onApprove={() => handleDelete()}
        approveText="Delete widget"
        approveColor="error"
        approveVariant="contained"
        cancelText="Cancel"
        cancelVariant="outlined"
        cancelColor="primary"
        title="Delete widget"
        content={`Are you sure you want to delete the widget?`}
      />
      {widget && (
        <ChangeWidgetWorkflow
          startFrom={startFrom}
          widget={widget}
          onCancel={() => setWidget(null)}
          onApprove={refresh}
        />
      )}
    </>
  );
};
export default withApollo(Actions);
