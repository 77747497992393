import React from "react";
import { CircularProgress } from "@mui/material";
import ApplicationTable from "./ApplicationTable";
import { Query } from "react-apollo";
import { LIST_APPLICATIONS_FOR_ADMIN } from "../../../gql/landingapi/queries";
import { OwcButton, OwcTypography } from "@one/react";
import { Module } from "../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const { applicationAdmin } = Module.PAGE.ADMIN;

export const ApplicationAddd = React.createContext(
  <ApplicationTable showTextOnly={true} />
);
const ApplicationsAdmin = () => (
  <Query
    query={LIST_APPLICATIONS_FOR_ADMIN}
    variables={{}}
    fetchPolicy="cache-and-network"
    id={generateID.UUID(applicationAdmin, `admin`, "query")}	
  >
    {({ loading, error, refetch, data }) => {
      if (loading) {
        return (
          <div className="info" data-testid="loading">
            <CircularProgress size={40} />
          </div>
        );
      }
      if (error) {
        return (
          <div className="info" data-testid="error"
          id={generateID.UUID(applicationAdmin, `admin`, "div_error")} >
            <OwcTypography 
            id={generateID.UUID(applicationAdmin, `admin`, "div_type")}
            variant="subtitle1" gutterBottom>
              {error?.message}
            </OwcTypography>
            <OwcButton
              variant="primary"
              onClick={refetch}
              color="secondary"
              // startIcon={<ErrorIcon />}
              data-testid="try-again-button"
              id={generateID.UUID(applicationAdmin, `admin`, "div_try")}
            >
              Try again
            </OwcButton>
          </div>
        );
      }
      return (
        <ApplicationTable
          data={data?.listDigitalLabLandingPageApplications?.items}
          refetch={refetch}
        />
      );
    }}
  </Query>
);

export default ApplicationsAdmin;
