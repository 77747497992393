import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { selectGroupUserInfo as selectGroupUserInfoActions } from "../features/landingPageUser/redux/actions";
import { GridAutocomplete } from "@digitallab/grid-common-components";
import styled from "styled-components";
import { GridAutoCompleteStyles } from "../components/shared/GridAutoCompleteStyles";
import { OwcTypography } from "@one/react";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../constants";

export const ApplicationStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const AllApplicationMainView = ({
  applicationGroups = [],
  selectGroupUserInfo,
  typeSelected,
  selected = "",
  onClick,
  type = "groups",
  name,
  setActiveFilter,
  searchQuery = ""
}) => {
  const acceptedGroup = ["allApplications", "groups"];
  const default_group = {
    id: "ALL_TOOLS",
    name: "All"
  };
  const [isGroupItem, setGroupItem] = useState([]);
  let allItemList = ["All"];

  useEffect(() => {
    const groupItemList = applicationGroups.map((group) => {
      return group?.name;
    });
    const groupItemListSorted = groupItemList?.sort(function (a, b) {
      return a?.toLowerCase().localeCompare(b?.toLowerCase());
    });
    setGroupItem(groupItemListSorted);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  allItemList = allItemList.concat(isGroupItem);

  return (
    <>
      <ApplicationStyled>
        {acceptedGroup.includes(typeSelected) && (
          <>
            <OwcTypography
              variant="title5"
              id={generateID.UUID(
                Module.PAGE.APPLICATIONS.allApplication,
                `application`,
                "title"
              )}
            >
              {searchQuery ? "Applications Search" : "Applications"}
            </OwcTypography>
            {!searchQuery && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <OwcTypography
                  variant="body1"
                  id={generateID.UUID(
                    Module.PAGE.APPLICATIONS.allApplication,
                    `application`,
                    "category"
                  )}
                >
                  Category: &nbsp;
                </OwcTypography>
                <GridAutocomplete
                  size="small"
                  sx={GridAutoCompleteStyles?.gridAutoCompleteLableShrink}
                  optionsList={allItemList}
                  label="Category"
                  key={name}
                  disableClearable
                  value={name === "Applications" ? "All" : name}
                  onAutoCompleteChange={(selectedValue) => {
                    const group = applicationGroups.find(
                      (item) => item.name === selectedValue
                    );
                    if (selectedValue === "All") {
                      setActiveFilter("All");
                      selectGroupUserInfo({ ...default_group, type });
                    } else {
                      setActiveFilter(selectedValue);
                      selectGroupUserInfo({ ...group, type });
                    }
                  }}
                  style={{ width: 200 }}
                  id={generateID.UUID(
                    Module.PAGE.APPLICATIONS.allApplication,
                    `application`,
                    "auto-complete"
                  )}
                />
              </div>
            )}
          </>
        )}
      </ApplicationStyled>
    </>
  );
};
const selectGroups = (groups) =>
  groups?.map((group) => ({
    id: group.id,
    name: group.name,
    icon: group.icon,
    iconPath: group.iconPath
  }));
const mapStateToProps = (store) => ({
  applicationGroups: selectGroups(store.user.allGroups),
  selected: store.user.selected?.id,
  typeSelected: store.user.selected?.type,
  name: store?.user?.selected?.name,
  searchQuery: store.searchTools.searchQuery
});
export default connect(mapStateToProps, {
  selectGroupUserInfo: selectGroupUserInfoActions
})(AllApplicationMainView);
