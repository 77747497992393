const AdminPanelSettings = (props) => (
  <svg
    id="adminpanelSettings"
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 21C5.7 20.4666 3.79167 19.1625 2.275 17.0875C0.758333 15.0125 0 12.6333 0 9.94998V3.97498L8 0.974976L16 3.97498V10.725C15.7667 10.6083 15.5167 10.5041 15.25 10.4125C14.9833 10.3208 14.7333 10.2583 14.5 10.225V5.02498L8 2.62498L1.5 5.02498V9.94998C1.5 11.2166 1.70417 12.3833 2.1125 13.45C2.52083 14.5166 3.04167 15.4541 3.675 16.2625C4.30833 17.0708 5.00833 17.7416 5.775 18.275C6.54167 18.8083 7.28333 19.1916 8 19.425C8.1 19.625 8.25 19.85 8.45 20.1C8.65 20.35 8.81667 20.5416 8.95 20.675C8.8 20.7583 8.64167 20.8208 8.475 20.8625C8.30833 20.9041 8.15 20.95 8 21ZM13.275 16.25C13.7083 16.25 14.075 16.0916 14.375 15.775C14.675 15.4583 14.825 15.0833 14.825 14.65C14.825 14.2166 14.675 13.85 14.375 13.55C14.075 13.25 13.7083 13.1 13.275 13.1C12.8417 13.1 12.4667 13.25 12.15 13.55C11.8333 13.85 11.675 14.2166 11.675 14.65C11.675 15.0833 11.8333 15.4583 12.15 15.775C12.4667 16.0916 12.8417 16.25 13.275 16.25ZM13.25 19.375C13.8 19.375 14.3 19.2583 14.75 19.025C15.2 18.7916 15.5833 18.4583 15.9 18.025C15.4667 17.7916 15.0333 17.6166 14.6 17.5C14.1667 17.3833 13.7167 17.325 13.25 17.325C12.7833 17.325 12.3292 17.3833 11.8875 17.5C11.4458 17.6166 11.0167 17.7916 10.6 18.025C10.9167 18.4583 11.2958 18.7916 11.7375 19.025C12.1792 19.2583 12.6833 19.375 13.25 19.375ZM13.325 21C12.025 21 10.9167 20.5375 10 19.6125C9.08333 18.6875 8.625 17.5916 8.625 16.325C8.625 15.025 9.08333 13.9125 10 12.9875C10.9167 12.0625 12.025 11.6 13.325 11.6C14.6083 11.6 15.7125 12.0625 16.6375 12.9875C17.5625 13.9125 18.025 15.025 18.025 16.325C18.025 17.5916 17.5625 18.6875 16.6375 19.6125C15.7125 20.5375 14.6083 21 13.325 21Z"
      fill="#333333"
    />
  </svg>
);

export default AdminPanelSettings;
