import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { intersection, get } from "lodash";
import ForbiddenAccess from "../../components/ForbiddenAccess";
import { GROUPS_TOKEN_PATH } from "../../constants";
import { CircularProgress } from "@mui/material";

export const hasAllRequiredRoles = (roles, requiredRoles) => {
  if (!Array.isArray(requiredRoles)) {
    return false;
  }
  if (!Array.isArray(roles)) {
    return false;
  }
  return intersection(roles, requiredRoles).length === requiredRoles.length;
};

const withRoles =
  ({
    requiredRoles,
    LoadingComponent = CircularProgress,
    ErrorComponent = ForbiddenAccess
  }) =>
  (Component) =>
  (props) => {
    const [permission, setPermisson] = useState({
      checking: true,
      access: false,
      message: ""
    });
    useEffect(() => {
      const checkRoles = async () => {
        try {
          const currentAuthenticatedUser =
            await Auth.currentAuthenticatedUser();
          const access_groups = get(
            currentAuthenticatedUser,
            GROUPS_TOKEN_PATH
          );
          setPermisson({
            checking: false,
            access: hasAllRequiredRoles(access_groups, requiredRoles)
          });
        } catch (err) {
          setPermisson({
            checking: false,
            access: false,
            message: err.message
          });
        }
      };
      checkRoles();
    }, []);
    if (permission.checking) {
      return LoadingComponent === null ? null : <LoadingComponent />;
    }
    if (!permission.access) {
      return ErrorComponent === null ? null : (
        <ErrorComponent text={permission?.message} />
      );
    }
    return <Component {...props} />;
  };

export default withRoles;
