import React, { useRef } from "react";
import { OwcMenu, OwcListItem } from "@one/react";
import { entryType, MyLabMenuArray } from "../../constants";
// import { setCookieData } from "../../utils/helpers/cookie";
const MyEquipmentMenu = ({
  params,
  setShowMenu,
  removeMyLabEquipment,
  allMegaclusterIds
}) => {
  const menuElement = useRef(null);

  // const redirectWithCookie = (search, type) => {
  //   if (search) {
  //     setCookieData("searchQuery", `${search}:${type}`, 1);
  //   }
  //   const launchURL =
  //     `https://${process.env.REACT_APP_OAUTH_DOMAIN}/` +
  //     `authorize?client_id=${process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID}` +
  //     `&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${process.env.REACT_APP_GRAPHQL_BOOKING_ENDPOINT_CLOUDFRONT}`;
  //   window.open(launchURL);
  // };
  const redirectWithoutCookie = (inventoryId, option) => {
    let launchURL = "";
    if (option === "bookEquipment" || option === "bookCluster") {
      launchURL = `${process.env.REACT_APP_GRAPHQL_BOOKING_ENDPOINT_CLOUDFRONT}equipmentList/${inventoryId}`;
    }
    if (option === "viewLogbook") {
      launchURL = `${process.env.REACT_APP_GRAPHQL_LOGBOOK_ENDPOINT_CLOUDFRONT}cover-sheet/${inventoryId}`;
    }
    if (option === "viewMonitoring") {
      launchURL = `${process.env.REACT_APP_GRAPHQL_MONITORING_ENDPOINT_CLOUDFRONT}equipmentList/${inventoryId}`;
    }
    if (option === "viewRepo") {
      launchURL = `${process.env.REACT_APP_GRAPHQL_REPO_ENDPOINT_CLOUDFRONT}equipmentList/${inventoryId}`;
    }
    window.open(launchURL);
  };

  const handleClick = (option) => {
    setShowMenu(false);
    switch (option) {
      case "bookEquipment":
        redirectWithoutCookie(params?.data?.id[0], option);
        break;
      case "viewLogbook":
        redirectWithoutCookie(params?.data?.id[0], option);
        break;
      case "viewMonitoring":
        redirectWithoutCookie(params?.data?.id[0], option);
        break;
      case "viewRepo":
        redirectWithoutCookie(params?.data?.id[0], option);
        break;
      case "bookCluster":
        redirectWithoutCookie(params?.data?.id[0], option);
        break;
      case "remove":
        removeMyLabEquipment(params?.data?.id[0]);
        break;
      default:
    }
  };

  return (
    <div>
      <OwcMenu
        ref={menuElement}
        visible={true}
        onClickOutside={() => setShowMenu(false)}
        anchor={params?.data?.id[0]}
        disable-portal={true}
        strategy="fixed"
      >
        {MyLabMenuArray.map((item) => {
          let canShow =
            item.availableFor.indexOf(params?.data?.entry_type[0]) !== -1;
          if (
            item.key === "bookEquipment" &&
            params?.data?.entry_type[0] === entryType.standaloneEquipment
          ) {
            canShow =
              params?.data?.is_bookable !== undefined &&
              params?.data?.is_bookable[0] === "true";
          }
          if (
            item.key === "bookCluster" &&
            params?.data?.entry_type[0] === entryType?.cluster
          ) {
            canShow =
              params?.data?.is_bookable !== undefined &&
              params?.data?.is_bookable[0] === "true";
          }

          // if (
          //   item.key === "viewLogbook" &&
          //   allMegaclusterIds?.current?.includes(params?.data?.id[0])
          // ) {
          //   canShow =
          //     params?.data?.is_visualized !== undefined &&
          //     params?.data?.is_visualized[0] === "true";
          // }

          if (
            item.key === "bookCluster" &&
            params?.data?.entry_type[0] === entryType?.cluster
          ) {
            canShow =
              params?.data?.is_bookable !== undefined &&
              params?.data?.is_bookable[0] === "true";
          }

          if (item.key === "viewMonitoring") {
            canShow =
              params?.data?.is_visualized !== undefined &&
              params?.data?.is_visualized[0] === "true";
          }

          return (
            canShow && (
              <OwcListItem
                onClick={() => {
                  handleClick(item.key);
                }}
              >
                {item.value}
              </OwcListItem>
            )
          );
        })}
      </OwcMenu>
    </div>
  );
};

export default MyEquipmentMenu;
