import { Auth } from "aws-amplify";
import get from "lodash/get";
import { GROUPS_TOKEN_PATH, LANDING_PAGE_ADMIN } from "../../constants";


export const checkRoleIsLaningPageAdmin = async (
  required_group = LANDING_PAGE_ADMIN
) => {
  const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
  const access_groups = get(currentAuthenticatedUser, GROUPS_TOKEN_PATH);
  if (
    !Array.isArray(access_groups) ||
    !access_groups.includes(required_group)
  ) {
    return null;
  }
  return currentAuthenticatedUser;
};
