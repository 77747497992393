import { useCallback, useEffect, useState } from "react";
import Snackbar from "../../snackbar/Snackbar";
import useDialog from "../../../utils/hooks/useDialog";
import { validateInputsFactory } from "./helpers/validateInputsFactory";
import { handleEntryPointUpdateFactory } from "./helpers/handleEntryPointUpdateFactory";
import { addEntryPointFactory } from "./helpers/addEntryPointFactory";
import { emptyEntryPoint } from "./helpers/emptyEntryPoint";
import { updateEntryPointFactory } from "./helpers/updateEntryPointFactory";
import ApplicationEditFormContent from "./ApplicationEditFormContent";
import { createFilterOptions } from "@mui/material";

export const filter = createFilterOptions();

const emptyValidationInputs = {
  name: true,
  description: true,
  entryPoint_name: true,
  entryPoint_url: true,
  entryPointLength: true
};
const useApplicationForm = ({
  application,
  applications,
  onUpdate,
  onValid,
  onInvalid
}) => {
  const [uniqueNameApp, setUniqueNameApp] = useState(true);
  const [uniqueNameEntryPoint, setUniqueNameEntryPoint] = useState(true);
  const [entryPoint, setEntryPoint] = useState(emptyEntryPoint);
  const [validationInputs, setValidationInputs] = useState({
    ...emptyValidationInputs
  });
  const [dirtyInputs, setDirtyInputs] = useState({
    name: false,
    description: false,
    entryPoint_name: false,
    entryPoint_url: false,
    entryPointLength: false
  });

  const { openDialog, ...dialogProps } = useDialog();
  const [entryPointToDelete, setEntryPointToDelete] = useState(null);
  const validateInputs = validateInputsFactory({
    application,
    entryPoint,
    setValidationInputs,
    applications,
    setUniqueNameApp,
    onValid,
    onInvalid
  });
  const handleEntryPointUpdate = handleEntryPointUpdateFactory({
    setEntryPoint,
    entryPoint,
    application,
    setUniqueNameEntryPoint
  });
  const checkValidEntryPoint = () =>
    entryPoint.name !== "" && entryPoint.url !== "";

  const updateApplication = (dataToChange) => {
    onUpdate({
      ...application,
      [dataToChange.key]: dataToChange.value
    });
  };
  const addEntryPoint = addEntryPointFactory({
    setDirtyInputs,
    dirtyInputs,
    checkValidEntryPoint,
    application,
    entryPoint,
    updateApplication,
    setEntryPoint
  });
  const updateEntryPoint = updateEntryPointFactory({
    setDirtyInputs,
    dirtyInputs,
    checkValidEntryPoint,
    application,
    entryPoint,
    updateApplication,
    setEntryPoint
  });

  return {
    uniqueNameApp,
    uniqueNameEntryPoint,
    setUniqueNameEntryPoint,
    entryPoint,
    setEntryPoint,
    validationInputs,
    dirtyInputs,
    setDirtyInputs,
    openDialog,
    dialogProps,
    entryPointToDelete,
    setEntryPointToDelete,
    validateInputs,
    handleEntryPointUpdate,
    addEntryPoint,
    updateEntryPoint,
    updateApplication
  };
};

const ApplicationEditForm = ({
  isApplicationNull,
  application,
  onUpdate,
  categories,
  allowedRoles,
  onValid,
  onInvalid,
  applications,
  file,
  setFile
}) => {
  const {
    uniqueNameApp,
    uniqueNameEntryPoint,
    setUniqueNameEntryPoint,
    entryPoint,
    setEntryPoint,
    validationInputs,
    dirtyInputs,
    setDirtyInputs,
    openDialog,
    dialogProps,
    entryPointToDelete,
    setEntryPointToDelete,
    validateInputs,
    handleEntryPointUpdate,
    addEntryPoint,
    updateEntryPoint,
    updateApplication
  } = useApplicationForm({
    application,
    applications,
    onUpdate,
    onValid,
    onInvalid
  });

  useEffect(() => {
    validateInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application, entryPoint]);
  const onFailedRequirements = useCallback((error) => {
    Snackbar({
      type: "warning",
      icon: "caution",
      appName: "",
      text: error?.message || error
    });
  }, []);
  const deleteEntryPoint = (entryPointId) => {
    updateApplication({
      value: application.entryPoints.filter(
        (theEntryPoint) => theEntryPoint.id !== entryPointId
      ),
      key: "entryPoints"
    });
  };

  return (
    <ApplicationEditFormContent
      dialogProps={dialogProps}
      deleteEntryPoint={deleteEntryPoint}
      entryPointToDelete={entryPointToDelete}
      setEntryPointToDelete={setEntryPointToDelete}
      application={application}
      setFile={setFile}
      onFailedRequirements={onFailedRequirements}
      file={file}
      validationInputs={
        isApplicationNull ? emptyValidationInputs : validationInputs
      }
      dirtyInputs={dirtyInputs}
      uniqueNameApp={uniqueNameApp}
      updateApplication={updateApplication}
      setDirtyInputs={setDirtyInputs}
      categories={categories}
      allowedRoles={allowedRoles}
      entryPoint={entryPoint}
      uniqueNameEntryPoint={uniqueNameEntryPoint}
      handleEntryPointUpdate={handleEntryPointUpdate}
      addEntryPoint={addEntryPoint}
      updateEntryPoint={updateEntryPoint}
      setEntryPoint={setEntryPoint}
      setUniqueNameEntryPoint={setUniqueNameEntryPoint}
      openDialog={openDialog}
    />
  );
};
export default ApplicationEditForm;
