import { OwcListItem } from "@one/react";
import React, { forwardRef } from "react";
import { connect } from "react-redux";
import { Module } from "../../../constants";
import { selectNotificationUserInfo } from "../../landingPageUser/redux/actions";
import ActionOfNotify from "../ActionOfNotify";
import DateOfNotify from "../DateOfNotify";
import ShortDescriptionOfNotify from "../ShortDescriptionOfNotify";
import SeverityCircleNotify from "./SeverityCircleNotify";
import { generateID } from "@digitallab/grid-common-components";

const {notificationName} = Module.PAGE.NOTIFICATION;
export const ItemOfShortViewNotify = forwardRef(
  ({ notification, onClick, mobileView, lastElement }, elementRef) => {
    return (
      <div ref={lastElement ? elementRef : null} id={generateID.UUID(notificationName, `itemofshort`, "div_flex")}>
        <OwcListItem style={{ display: "flex" }} id={generateID.UUID(notificationName, `itemofshort`, "list_item")}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
            id={generateID.UUID(notificationName, `itemofshort`, "list_div")}
          >
            <SeverityCircleNotify severity={notification.severity} />
            <ShortDescriptionOfNotify
              source={`${notification.source} `}
              description={`${notification.message} `}
            />
            <DateOfNotify date={notification.time} />
            <ActionOfNotify
              link={notification.link}
              short
              id={notification.id}
            />
          </div>
        </OwcListItem>
      </div>
    );
  }
);

export default connect(
  null,
  {
    onClick: selectNotificationUserInfo
  },
  null,
  {
    forwardRef: true
  }
)(ItemOfShortViewNotify);
