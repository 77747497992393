import React, { useState, useEffect } from "react";
import { DLabAppSwitcher } from "@digitallab/grid-common-components";
import { resolveSSOLink } from "../utils/ssoLinkStrategyResolver";
import { selectGroupUserInfo as selectGroupUserInfoActions } from "../features/landingPageUser/redux/actions";
import { compose } from "react-apollo";
import { connect } from "react-redux";
import { selectFavourites } from "../selectors";
import get from "lodash/get";
import "./AppSwitch.scss";
import { OwcIconButton } from "@one/react";
import { useHistory } from "react-router";
import { ASSETS_BASE_URL } from "../constants";
const onOpenAppFactory = () => {
  return async (launchUrl, application) => {
    if (launchUrl === "#" || !Array.isArray(application.entryPoints)) {
      return;
    }
    window.open(launchUrl);
  };
};
const AppSwitcher = ({ favourites = [] }) => {
  const [list, setList] = useState(favourites);
  const [showPopup, setShowPopup] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setList(favourites);
  }, [favourites]);
  const onOpenApp = onOpenAppFactory({});

  const handleSwitch = (application) => {
    const entryPointSelected =
      application?.entryPointSelected || application?.entryPoints[0]?.id;
    const entryPoint = application?.entryPoints;
    const appEntryPointID =
      get(
        entryPoint.find((x) => x.id === entryPointSelected),
        "url"
      ) || entryPoint[0]?.url;
    onOpenApp(
      resolveSSOLink(application?.type, appEntryPointID || "#"),
      application
    );
  };

  return (
    <>
      <OwcIconButton
        flat
        type="legacy"
        icon="app_launcher"
        id="popup-anchor"
        onClick={async () => {
          setShowPopup(true);
        }}
      />
      <DLabAppSwitcher
        anchor="popup-anchor"
        showPopup={showPopup}
        applicationList={list.map((item) => {
          if (item?.iconPath && item?.iconPath.startsWith("resources")) {
            item.iconPath = `${ASSETS_BASE_URL}${item.iconPath}`;
          }
          return item;
        })}
        handleAppSwitch={(application) => {
          setShowPopup(false);
          handleSwitch(application);
        }}
        handleAllAppsClick={() => {
          setShowPopup(false);
          history.replace("/home/application");
        }}
        handleClickOutSide={() => {
          setShowPopup(false);
        }}
      />
    </>
  );
};
const mapStateToProps = (store) => ({
  favourites: selectFavourites(
    store.user.allApps,
    store.user.applicationSets
  ).sort((a, b) => {
    if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
      return 1;
    } else {
      if (b.name?.toLowerCase() > a.name?.toLowerCase()) {
        return -1;
      } else {
        return 0;
      }
    }
  })
});
export default compose(
  connect(mapStateToProps, { selectGroupUserInfo: selectGroupUserInfoActions })
)(AppSwitcher);
