import { OwcIcon, OwcInput } from "@one/react";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../constants";

const getHelperText = (
  validationInputs,
  dirtyInputs,
  uniqueNameEntryPoint,
  entryPoint
) => {
  if (!validationInputs.entryPoint_name && dirtyInputs.entryPoint_name) {
    return `Entry point name must have at least 1 character and can only contain alphanumeric characters, space, and _ - :`;
  } else if (!uniqueNameEntryPoint) {
    return `Entry point named '${entryPoint.name}' already exists`;
  } else {
    return undefined;
  }
};

const LaunchStepNameInput = ({
  entryPoint,
  validationInputs,
  dirtyInputs,
  uniqueNameEntryPoint,
  handleEntryPointUpdate,
  setDirtyInputs
}) => {
  return (
    <>
      <OwcInput
        id={generateID.UUID(Module.PAGE.ADMIN.launchStepName, `launchStepName`, "input_modal")}
        data-testid="application-edit-form-launch-step-name-input"
        label="Launch step name *"
        value={entryPoint.name}
        size="small"
        variant="filled"
        maxLength="20"
        required
        FormHelperTextProps={{
          "data-testid": "application-edit-form-launch-step-name-helper-text"
        }}
        error={
          (!validationInputs.entryPoint_name && dirtyInputs.entryPoint_name) ||
          !uniqueNameEntryPoint
        }
        onInputKeyDown={(event) =>
          handleEntryPointUpdate({ key: "name", value: event.target.value })
        }
        onBlur={() => {
          setDirtyInputs({
            ...dirtyInputs,
            entryPoint_name: true
          });
        }}
        style={{ marginRight: 22 }}
      >
        {entryPoint.name.length > 0 && (
          <OwcIcon
            slot="suffix"
            type="filled"
            name="circle_clear"
            onClick={() => {
              handleEntryPointUpdate({ key: "name", value: "" });
              setDirtyInputs({
                ...dirtyInputs,
                entryPoint_name: true
              });
            }}
            id={generateID.UUID(Module.PAGE.ADMIN.launchStepName, `launchStepName`, "icon_circle")}
            className="pointer"
          />
        )}
        <label slot="error-text">
          {getHelperText(
            validationInputs,
            dirtyInputs,
            uniqueNameEntryPoint,
            entryPoint
          )}
        </label>
      </OwcInput>
      {/* <TextField
        data-testid="application-edit-form-launch-step-name-input"
        label="Launch step name"
        value={entryPoint.name}
        size="small"
        variant="filled"
        required
        FormHelperTextProps={{
          "data-testid": "application-edit-form-launch-step-name-helper-text"
        }}
        helperText={getHelperText(
          validationInputs,
          dirtyInputs,
          uniqueNameEntryPoint,
          entryPoint
        )}
        error={
          (!validationInputs.entryPoint_name && dirtyInputs.entryPoint_name) ||
          !uniqueNameEntryPoint
        }
        InputProps={
          entryPoint.name.length > 0 && {
            endAdornment: (
              <InputAdornment position="end" id={generateID.UUID(Module.PAGE.ADMIN.launchStepName, `launchStepName`, "input_adronment")}>
                <IconButton
                  id={generateID.UUID(Module.PAGE.ADMIN.launchStepName, `launchStepName`, "icon")}
                  data-testid="application-edit-form-clear-button-launch-step-name"
                  edge="end"
                  onClick={() => {
                    handleEntryPointUpdate({ key: "name", value: "" });
                    setDirtyInputs({
                      ...dirtyInputs,
                      entryPoint_name: true
                    });
                  }}
                >
                  <i className="one-icons-outlined" id={generateID.UUID(Module.PAGE.ADMIN.launchStepName, `launchStepName`, "i")}>clear</i>
                </IconButton>
              </InputAdornment>
            )
          }
        }
        inputProps={{
          maxLength: 20
        }}
        fullWidth
        onChange={(event) =>
          handleEntryPointUpdate({ key: "name", value: event.target.value })
        }
        onBlur={() =>
          setDirtyInputs({
            ...dirtyInputs,
            entryPoint_name: true
          })
        }
      /> */}
    </>
  );
};
export default LaunchStepNameInput;
