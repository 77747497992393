import { uniqBy } from "lodash";
export const updateItem = (data, payload) => {
  const { id, ...rest } = payload;
  return data.map((item) => (item.id === id ? { ...item, ...rest } : item));
};

export const deleteItem = (data, deleteItemId) =>
  data.filter((item) => item.id !== deleteItemId);

export const addItem = (data, item) => uniqBy([item, ...data], "id");
