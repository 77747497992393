import { withApollo } from "react-apollo";
import { useDispatch, useSelector } from "react-redux";
import { addWidgetToDashboard } from "../../landingPageUser/redux/actions";
import { UPDATE_DASHBOARD } from "../../../gql/landingapi/mutations";
import Snackbar from "../../snackbar/Snackbar";
import { OwcIcon, OwcIconButton } from "@one/react";
import { IconButton } from "@mui/material";

const isWidgetInDashboard = (widgetId, dashboardWidgets) => {
  return dashboardWidgets?.some((dw) => dw?.widgetId === widgetId);
};

const addWidgetToDashboardFn = async (dashboard, widget, dispatch, client) => {
  const widgetSize = widget.supportedSizes[0];
  const firstEmptyRowY = dashboard.widgets.reduce((lastY, theWidget) => {
    const currentY = theWidget.locationY + theWidget.size.height;
    if (lastY < currentY) {
      return currentY;
    }
    return lastY;
  }, 0);
  const newWidget = {
    locationX: 0,
    locationY: firstEmptyRowY,
    widgetId: widget.id,
    size: {
      height: widgetSize.height,
      mockupURL: widgetSize.mockupURL,
      width: widgetSize.width
    }
  };
  dispatch(addWidgetToDashboard(newWidget));
  try {
    await client.mutate({
      mutation: UPDATE_DASHBOARD,
      variables: {
        id: dashboard.id,
        widgets: [...dashboard.widgets, newWidget]
      }
    });
    Snackbar({
      appName: "",
      icon: "circle_confirm",
      type: "success",
      text: `Successfully added widget to dashboard`
    });
  } catch (e) {
    Snackbar({
      appName: "",
      icon: "no",
      type: "warning",
      text: `Failed adding widget dashboard`
    });
    return;
  }
};

const ActionsForWidgetsListBar = ({
  item,
  toggleExpanded,
  expanded,
  client
}) => {
  const dashboard = useSelector((state) => state.user.dashboard);
  const cantAddWidget = isWidgetInDashboard(item.id, dashboard?.widgets);
  const dispatch = useDispatch();

  return (
    <>
      <IconButton
        data-testid={`dashboard-widgets-more-less-button-${item.id}`}
        onClick={() => {
          toggleExpanded();
        }}
      >
        {!expanded ? (
          <OwcIcon
            name="chevron_down"
            type="filled"
            data-testid={`dashboard-widgets-expanded-more-icon-${item.id}`}
          />
        ) : (
          <OwcIcon
            name="chevron_up"
            type="filled"
            data-testid={`dashboard-widgets-expanded-less-icon-${item.id}`}
          />
        )}
      </IconButton>
      {cantAddWidget ? (
        <OwcIconButton
          flat
          type="outlined"
          icon="circle_confirm"
          data-testid="dashboard-already-added-widget-icon"
        />
      ) : (
        <IconButton
          color="primary"
          data-testid={`dashboard-add-widget-button-${item.id}`}
          onClick={() => {
            addWidgetToDashboardFn(dashboard, item, dispatch, client);
          }}
        >
          <OwcIcon
            name="sign_plus"
            type="filled"
            data-testid="dashboard-add-widget-icon"
          />
        </IconButton>
      )}
    </>
  );
};
export default withApollo(ActionsForWidgetsListBar);
