import React, { useContext, useEffect, useState } from "react";
import { compose } from "redux";
import { LIST_APPLICATION_GROUPS } from "../../gql/landingapi/queries";
import { QueryContext } from "./contexts";
import Actions from "./Actions.jsx";
import ApplicationCategoryList from "../../components/shared/ApplicationCategoryList";
import { withData } from "../../utils/hocs/withData";
import { filterByQuery } from "./helpers";
import { changeStringToDate } from "../../utils/helpers/text";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable.js";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../constants";

const { appAndCategories } = Module.PAGE.ADMIN;

const ListMetaInfo = {
  fields: {
    name: {
      text: DATA_MODEL_TABLE.categoryName.value,
      sortable: true
    },
    updatedAt: {
      text: DATA_MODEL_TABLE.updatedAt.value,
      sortable: true,
      component: ({ item }) => (
        <span
        id={generateID.UUID(appAndCategories, `category`, "span_item")}
        >{changeStringToDate(item?.updatedAt)}</span>
      )
    },
    actions: {
      text: "",
      headProps: {
        style: {
          display: "flex",
          justifyContent: "flex-end"
        }
      },
      cellProps: {
        style: {
          display: "flex",
          justifyContent: "flex-end"
        }
      },
      component: ({ item }) => <Actions item={item} />
    }
  },
  rowKeyId: "id"
};

const CategoriesList = ({ data }) => {
  const { query, onRequestSort, orderBy, isReverseOrder } =
    useContext(QueryContext);
  const [pageNo, setPageNo] = useState(1);
  const [showPerPage, setShowPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    filterData(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, query, ["name"], orderBy, isReverseOrder, pageNo, showPerPage]);

  const filterData = (q) => {
    const allData = filterByQuery(data, q, ["name"], orderBy, isReverseOrder);
    setTotalCount(allData.length);
    const lowIndex = pageNo === 1 ? 0 : (pageNo - 1) * showPerPage;
    const highIndex = pageNo === 1 ? showPerPage : lowIndex + showPerPage;
    const paginationData = allData.slice(lowIndex, highIndex);
    setFilteredData(paginationData);
  };

  return (
    <ApplicationCategoryList
      data={filteredData}
      meta={ListMetaInfo}
      isReverseOrder={isReverseOrder}
      orderBy={orderBy[0]}
      pageNo={pageNo}
      setPageNo={setPageNo}
      showPerPage={showPerPage}
      setShowPerPage={setShowPerPage}
      totalCount={totalCount}
      setTotalCount={setTotalCount}
      onRequestSort={onRequestSort}
      id={generateID.UUID(appAndCategories, `categorylist`, "list")}

    />
  );
};

export default compose(
  withData({
    dataPath: "listDigitalLabLandingPageApplicationGroups.items",
    queryGQL: LIST_APPLICATION_GROUPS
  })
)(CategoriesList);
