import { CREATE_DIGITAL_LAB_LANDING_PAGE_FAV_EQUIPMENT } from "../../gql/landingapi/mutations";
import { GET_LOGGED_USER_DETAILS_FAV_EQUIPMENTS } from "../../gql/landingapi/queries";
import Snackbar from "../snackbar/Snackbar";
import { getAllData } from "../../utils/helpers/fetching";

export const updateEquipment = async (
  userId,
  client,
  inventoryIDs,
  selectedSite
) => {
  try {
    const response = await client.mutate({
      fetchPolicy: "no-cache",
      mutation: CREATE_DIGITAL_LAB_LANDING_PAGE_FAV_EQUIPMENT,
      variables: {
        userID: userId,
        inventoryIDs,
        siteName: selectedSite?.siteName
      }
    });

    if (response) {
      Snackbar({
        appName: "",
        icon: "circle_confirm",
        type: "success",
        text: `Successfully updated the equipment!`
      });
      return response;
    }
  } catch (e) {
    Snackbar({
      appName: "",
      icon: "no",
      type: "warning",
      text: `Failed to update equipments!`
    });
    return false;
  }
};

export const getSelectedEquipment = async ({ client, siteName }) => {
  try {
    const response = await getAllData({
      query: GET_LOGGED_USER_DETAILS_FAV_EQUIPMENTS,
      client,
      dataPath: ["data", "getLoggedInUserDetails"],
      drillData: true,
      variables: {
        siteName
      }
    });

    const equipments = response?.items?.[0]?.favoriteEquipments?.items || [];
    const mappedEquipments = equipments?.map((item) => item.equipment) || [];
    const otherFavEquip =
      mappedEquipments?.filter((item) => item.siteName !== siteName) || [];
    const filteredEquipments =
      mappedEquipments?.filter((item) => item.siteName === siteName) || [];
    const favEquipsWithId = equipments.filter(
      (item) => item?.equipment?.siteName === siteName
    );
    const favEquipsWithIds = favEquipsWithId.map((favEquip) => {
      favEquip.equipment.favEquipId = favEquip.id;
      return favEquip.equipment;
    });
    const favoriteEquipments = {
      favEquips: filteredEquipments || [],
      siteFavEquip: otherFavEquip || [],
      favEquipsWithIds: favEquipsWithIds || []
    };

    return favoriteEquipments;
  } catch (e) {
    return null;
  }
};
