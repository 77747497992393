import React, { useEffect, useContext } from "react";
import { Query } from "react-apollo";
import { NOTIFICATIONS_BY_USER_SEVERITY_AND_TIME } from "../../gql/landingapi/queries";
import {
  ON_CREATE_DIGITAL_LAB_LANDING_PAGE_NOTIFICATION,
  ON_UPDATE_DIGITAL_LAB_LANDING_PAGE_NOTIFICATION,
  ON_DELETE_DIGITAL_LAB_LANDING_PAGE_NOTIFICATION,
  ON_UPDATE_NOTIFICATION_BANNER_COMMAND,
  ON_CREATE_NOTIFICATION_BANNER_COMMAND
} from "../../gql/landingapi/subscriptions";
import UserNotificationSubscription from "./UserNotificationSubscription";
import {
  createNotificationCallback,
  updateNotificationCallback,
  deleteNotificationCallback
} from "./helpers/actions";
import Snackbar from "../snackbar/Snackbar";
import { useSelector } from "react-redux";
import { NOTIFICATIONS_LIMIT } from "../../constants";
import { FiltersContext } from "../landingPageNotifications/fullViewOfNotification/FiltersContext/context";
import { updateNotificationBanner } from "../landingPageUser/redux/actions";
import { compose, withApollo } from "react-apollo";
import { connect } from "react-redux";

const DisplayError = () => {
  useEffect(() => {
    Snackbar({
      type: "warning",
      appName: "Notifications",
      text: `were not connected, please refresh and try again`
    });
  }, []);
  return null;
};

const dispatchAndReturn = (dispatchFiltersAction, fn) => (prev, subData) => {
  dispatchFiltersAction({ type: "nextData" });

  return fn(prev, subData);
};

const UserNotification = ({ UpdateNotificationBanner }) => {
  const userId = useSelector((state) => state.user.user);
  const { dispatchFiltersAction } = useContext(FiltersContext);

  //Top Banner Notification
  const topBannerNotificationCallback = (prev, subscriptionData) => {
    const resultValue = subscriptionData?.data?.onUpdateNotificationBanner
      ? subscriptionData?.data?.onUpdateNotificationBanner
      : subscriptionData?.data?.onCreateNotificationBanner;
    UpdateNotificationBanner(resultValue);
  };

  return (
    <Query
      query={NOTIFICATIONS_BY_USER_SEVERITY_AND_TIME}
      variables={{
        user: userId,
        limit: NOTIFICATIONS_LIMIT,
        sortDirection: "DESC"
      }}
      fetchPolicy="no-cache"
    >
      {({ loading, error, subscribeToMore, refetch }) => {
        if (loading) {
          return null;
        }
        if (error !== undefined) {
          return <DisplayError />;
        }
        return (
          <>
            <UserNotificationSubscription
              refetch={refetch}
              subscribeToMore={subscribeToMore}
              documentSubscription={
                ON_CREATE_DIGITAL_LAB_LANDING_PAGE_NOTIFICATION
              }
              dataCallback={dispatchAndReturn(
                dispatchFiltersAction,
                createNotificationCallback
              )}
              user={userId ?? ""}
            />
            <UserNotificationSubscription
              refetch={refetch}
              subscribeToMore={subscribeToMore}
              documentSubscription={
                ON_UPDATE_DIGITAL_LAB_LANDING_PAGE_NOTIFICATION
              }
              dataCallback={dispatchAndReturn(
                dispatchFiltersAction,
                updateNotificationCallback
              )}
              user={userId ?? ""}
            />
            <UserNotificationSubscription
              refetch={refetch}
              subscribeToMore={subscribeToMore}
              documentSubscription={
                ON_DELETE_DIGITAL_LAB_LANDING_PAGE_NOTIFICATION
              }
              dataCallback={dispatchAndReturn(
                dispatchFiltersAction,
                deleteNotificationCallback
              )}
              user={userId ?? ""}
            />

            <UserNotificationSubscription
              refetch={refetch}
              subscribeToMore={subscribeToMore}
              documentSubscription={ON_UPDATE_NOTIFICATION_BANNER_COMMAND}
              dataCallback={topBannerNotificationCallback}
              user={userId ?? ""}
            />
            <UserNotificationSubscription
              refetch={refetch}
              subscribeToMore={subscribeToMore}
              documentSubscription={ON_CREATE_NOTIFICATION_BANNER_COMMAND}
              dataCallback={topBannerNotificationCallback}
              user={userId ?? ""}
            />
          </>
        );
      }}
    </Query>
  );
};

export default compose(
  connect(null, {
    UpdateNotificationBanner: updateNotificationBanner
  }),
  withApollo
)(UserNotification);
