import { CircularProgress } from "@mui/material";
import {
  OwcButton,
  OwcIcon,
  OwcInput,
  OwcTextarea,
  OwcTypography
} from "@one/react";
import { Formik } from "formik";
import { withApollo } from "react-apollo";
import ConfirmDialog from "../../../components/shared/ConfirmDialog";
import CustomSingleSelect from "../../../components/shared/CustomSingleSelect";
import { Module } from "../../../constants";
import BUTTON_MODEL_LABEL from "../../../utils/constants/buttonModelLabel";
import useDialog from "../../../utils/hooks/useDialog";
import { SEVERITY_NOTIFICATIONS_BACKEND } from "../../SeverityNotifications";
import { emptyNotify } from "./emptyNotify";
import { notifyValidationSchemaFactory } from "./notify-validation-schema";
import { sendGroupNotify } from "./send-group-notify";
import styles from "./SendGroupNotifyForm.module.scss";
import { generateID } from "@digitallab/grid-common-components";

const {adminSendGroupNotify} = Module.PAGE.ADMIN;

const severityOptions = Object.values(SEVERITY_NOTIFICATIONS_BACKEND).map(
  (value) => ({
    value,
    label: value
  })
);

const NotifyTextFiled = ({ formik, shortName, label, style, ...props }) => {
  return (
    <OwcInput
      data-testid={`send-group-notify-${shortName}-input`}
      color="primary"
      variant="filled"
      id={shortName}
      name={shortName}
      label={label}
      style={style}
      onBlur={formik.handleBlur}
      value={formik.values[shortName]}
      onInputChange={formik.handleChange}
      error={formik.touched[shortName] && Boolean(formik.errors[shortName])}
      {...props}
    >
      {formik.touched[shortName] && formik.errors[shortName] && (
        <>
          <span slot="error-text">{formik.errors[shortName]}</span>
        </>
      )}
      {formik.values[shortName] !== "" && (
        <OwcIcon
          data-testid={`send-group-notify-${shortName}-input-clear`}
          name="circle_clear"
          slot="suffix"
          type="filled"
          onClick={() => formik.setFieldValue(shortName, "", true)}
          className="pointer"
        />
      )}
    </OwcInput>
  );
};
const onSubmitFactory = (client) => {
  return async (values, formikHelpers) => {
    const succeeded = await sendGroupNotify(client, values);
    if (succeeded) {
      formikHelpers.resetForm({
        ...emptyNotify
      });
    }
  };
};

const SendGroupNotifyForm = ({ client }) => {
  const { openDialog, ...dialogProps } = useDialog();
  return (
    <Formik
      enableReinitialize={true}
      validationSchema={notifyValidationSchemaFactory}
      initialValues={{ ...emptyNotify }}
      isInitialValid={false}
      onSubmit={onSubmitFactory(client)}
      id={generateID.UUID(adminSendGroupNotify, `sendgroupform`, "formik")}
    >
      {(formik) => (
        <div
          className={styles.mainBoxForm}
          id={generateID.UUID(adminSendGroupNotify, `sendgroupform`, "div_main")}
          data-testid="main-box-form-for-send-group-notify"
        >
          <div 
          id={generateID.UUID(adminSendGroupNotify, `sendgroupform`, "div")}
          data-testid="main-box-form-all-inputs-for-send-group-notify">
            <OwcTypography 
            id={generateID.UUID(adminSendGroupNotify, `sendgroupform`, "group_title")}
            className={styles.headerTitle}>
              Group notification
            </OwcTypography>
            <NotifyTextFiled
              style={{ width: "100%", marginTop: 20 }}
              required
              formik={formik}
              shortName={"short"}
              label={"Title*"}
              maxLength="100"
              id={generateID.UUID(adminSendGroupNotify, `sendgroupform`, "notify_text_1")}
            />
            <div className={styles.severityTitle}>
              <CustomSingleSelect
                formik={formik}
                label={"Severity *"}
                options={severityOptions}
                style={{ width: "50%", marginTop: 25, marginRight: 20 }}
                selectLabel="Severity*"
                required={true}
                name="severity"
                onChange={(e) => {
                  formik.setFieldValue("severity", e);
                }}
                value={formik.values.severity}
                propValue={"value"}
                propLabel={"label"}
                id={generateID.UUID(adminSendGroupNotify, `sendgroupform`, "severity_input")}
                testid="send-group-notify-severity-input"
              />

              <NotifyTextFiled
                style={{ width: "50%", marginTop: 25 }}
                formik={formik}
                shortName={"link"}
                label={"URL"}
                required
                id={generateID.UUID(adminSendGroupNotify, `sendgroupform`, "notify_text_filed")}
              />
            </div>
            <OwcTextarea
              formik={formik}
              name={"details"}
              label={"Description"}
              onInputChange={formik.handleChange}
              onInputReset={() => formik.setFieldValue("details", "", true)}
              maxLength={300}
              minLength={0}
              cols="18"
              rows={4}
              reset
              id={generateID.UUID(adminSendGroupNotify, `sendgroupform`, "textarea_div")}
            />
          </div>
          <div
            className={styles.actionFooter}
            id={generateID.UUID(adminSendGroupNotify, `sendgroupform`, "div_action")}
            data-testid="main-box-form-action-box-for-send-group-notify"
          >
            <OwcButton
              style={{ marginTop: 15 }}
              disabled={
                !formik.isValid || formik.isSubmitting || dialogProps.open
              }
              id={generateID.UUID(adminSendGroupNotify, `sendgroupform`, "button_send")}
              aria-label={"Send"}
              data-testid="send-group-notify-button"
              onClick={() => openDialog()}
            >
              {formik.isSubmitting && (
                <CircularProgress
                  id={generateID.UUID(adminSendGroupNotify, `sendgroupform`, "circularProgress")}
                  data-testid="circular-progress-send-group-notify-button"
                  color="primary"
                  size={20}
                  style={{ marginRight: 6 }}
                />
              )}
              {BUTTON_MODEL_LABEL.send}
            </OwcButton>
          </div>
          <ConfirmDialog
            {...dialogProps}
            onApprove={() => formik.submitForm()}
            approveText="Send"
            approveColor="primary"
            approveVariant="contained"
            cancelText="Cancel"
            cancelVariant="outlined"
            cancelColor="primary"
            title="Send group notification"
            content={`Are you sure you want to send notification to all group users?`}
          />
        </div>
      )}
    </Formik>
  );
};
export default withApollo(SendGroupNotifyForm);
