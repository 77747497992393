import React, { useState, memo } from "react";
import { mountRootParcel } from "single-spa";
import ParcelComponent from "single-spa-react/lib/esm/parcel";
import WidgetError from "./WidgetError";
import { remoteImport } from "./helpers";
import { publish, subscribe } from "./pubSub.js";

const Widget = memo(({
  appId = "appId",
  widgetName = "widgetName",
  getToken,
  email,
  ...params
}) => {
  const [error, setError] = useState(null);
  if (error) {
    return <WidgetError appName={widgetName} />;
  }
  return (
    <ParcelComponent
      key={appId}
      mountParcel={mountRootParcel}
      config={remoteImport(appId)}
      publish={publish}
      subscribe={subscribe}
      getToken={getToken}
      user={{ email }}
      wrapWith="div"
      wrapClassName="widget"
      handleError={(err) => setError(err)}
      params={params}
    />
  );
});

export default Widget;
