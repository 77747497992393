import React, { useContext } from "react";
import BodyPopoverNotify from "./BodyPopoverNotify";
import FooterPopoverNotify from "./FooterPopoverNotify";
import HeadPopoverNotify from "./HeadPopoverNotify";
import { ShortViewOfNotifyContext } from "./ShortViewOfNotifyContext/context";
import { SHORT_NOTIFICATIONS_LIMIT } from "../../../constants";
import { Popover } from "@mui/material";

const PopoverNotify = ({ notifications = [] }) => {
  const isData = notifications.length > 0;
  const { shortViewListOpen, anchorElNotify, handleCloseNotify } = useContext(
    ShortViewOfNotifyContext
  );

  return (
    <Popover
      data-testid="short-notification-list"
      id="notify-popover"
      open={shortViewListOpen}
      anchorEl={anchorElNotify}
      onClose={handleCloseNotify}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      PaperProps={{
        style: {
          minWidth: 500,
          maxHeight: 414,
          borderRadius: 4,
          boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.301961)",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden"
        }
      }}
    >
      <HeadPopoverNotify />
      <BodyPopoverNotify
        notifications={notifications.slice(0, SHORT_NOTIFICATIONS_LIMIT)}
        isData={isData}
      />
      <FooterPopoverNotify
        isData={isData}
        handleCloseNotify={handleCloseNotify}
      />
    </Popover>
  );
};

export default PopoverNotify;
