import {
  UPDATE_DIGITAL_LAB_LANDING_PAGE_APPLICATION_GROUP,
  CREATE_DIGITAL_LAB_LANDING_PAGE_APPLICATION_GROUP,
  DELETE_DIGITAL_LAB_LANDING_PAGE_APPLICATION_GROUP,
  DELETE_APPLICATION_CATEGORY
} from "../../gql/landingapi/mutations";
import {
  LIST_APPLICATIONS_FOR_ADMIN,
  LIST_APPLICATION_GROUPS
} from "../../gql/landingapi/queries";
import { updateItem, addItem, deleteItem } from "../../utils/store/crud";

export const editAction = async ({ item, client, name }) =>
  client.mutate({
    mutation: UPDATE_DIGITAL_LAB_LANDING_PAGE_APPLICATION_GROUP,
    variables: {
      id: item?.id,
      name
    },
    optimisticResponse: {
      __typename: "Mutation",
      updateDigitalLabLandingPageApplicationGroup: {
        __typename: "DigitalLabLandingPageApplicationGroup",
        id: item?.id,
        updatedAt: new Date().toISOString(),
        name
      }
    },
    update: (
      proxy,
      { data: { updateDigitalLabLandingPageApplicationGroup } }
    ) => {
      const data = proxy.readQuery({ query: LIST_APPLICATION_GROUPS });
      proxy.writeQuery({
        query: LIST_APPLICATION_GROUPS,
        data: {
          ...data,
          listDigitalLabLandingPageApplicationGroups: {
            ...data.listDigitalLabLandingPageApplicationGroups,
            items: updateItem(
              data?.listDigitalLabLandingPageApplicationGroups?.items,
              updateDigitalLabLandingPageApplicationGroup
            )
          }
        }
      });
    }
  });

export const addAction = async ({ name, client }) =>
  client.mutate({
    mutation: CREATE_DIGITAL_LAB_LANDING_PAGE_APPLICATION_GROUP,
    variables: {
      name,
      icon: "folder"
    },
    refetchQueries: [
      {
        query: LIST_APPLICATIONS_FOR_ADMIN
      }
    ],
    optimisticResponse: {
      __typename: "Mutation",
      createDigitalLabLandingPageApplicationGroup: {
        id: -(window.crypto?.getRandomValues(new Uint32Array(1))?.[0] || 10),
        __typename: "DigitalLabLandingPageApplicationGroup",
        icon: "folder",
        parentID: null,
        updatedAt: new Date().toISOString(),
        iconPath: null,
        name
      }
    },
    update: (
      proxy,
      { data: { createDigitalLabLandingPageApplicationGroup } }
    ) => {
      const data = proxy.readQuery({ query: LIST_APPLICATION_GROUPS });
      proxy.writeQuery({
        query: LIST_APPLICATION_GROUPS,
        data: {
          ...data,
          listDigitalLabLandingPageApplicationGroups: {
            ...data.listDigitalLabLandingPageApplicationGroups,
            items: addItem(
              data?.listDigitalLabLandingPageApplicationGroups?.items,
              createDigitalLabLandingPageApplicationGroup
            )
          }
        }
      });
    }
  });

export const deleteAction = async ({ id, client }) => {
  await client.mutate({
    mutation: DELETE_DIGITAL_LAB_LANDING_PAGE_APPLICATION_GROUP,
    variables: {
      id
    },
    optimisticResponse: {
      __typename: "Mutation",
      deleteDigitalLabLandingPageApplicationGroup: {
        __typename: "DigitalLabLandingPageApplicationGroup",
        id
      }
    },
    update: (
      proxy,
      { data: { deleteDigitalLabLandingPageApplicationGroup } }
    ) => {
      const data = proxy.readQuery({ query: LIST_APPLICATION_GROUPS });

      proxy.writeQuery({
        query: LIST_APPLICATION_GROUPS,
        data: {
          ...data,
          listDigitalLabLandingPageApplicationGroups: {
            ...data.listDigitalLabLandingPageApplicationGroups,
            items: deleteItem(
              data?.listDigitalLabLandingPageApplicationGroups?.items,
              deleteDigitalLabLandingPageApplicationGroup?.id
            )
          }
        }
      });
    }
  });
  await client.mutate({
    mutation: DELETE_APPLICATION_CATEGORY,
    variables: {
      categoryId: id
    },
    refetchQueries: [
      {
        query: LIST_APPLICATIONS_FOR_ADMIN
      }
    ]
  });
};
