import Snackbar from "../../snackbar/Snackbar";
import { DELETE_WIDGET } from "../../../gql/landingapi/mutations";
import { updateDashboardWidgets } from "../../landingPageUser/redux/actions";

export const deleteWidget = async (client, widget, widgets, dispatch) => {
  try {
    await client.mutate({
      fetchPolicy: "no-cache",
      mutation: DELETE_WIDGET,
      variables: {
        id: widget.id
      }
    });
    const newWidgetsList = widgets.filter(
      (theWidget) => theWidget.widgetId !== widget.id
    );
    dispatch(updateDashboardWidgets(newWidgetsList));
    Snackbar({
      appName: "",
      icon: "circle_confirm",
      type: "success",
      text: `Successfully deleted widget!`
    });
  } catch (e) {
    Snackbar({
      appName: "",
      icon: "no",
      type: "warning",
      text: `Failed deleted widget!`
    });
  }
};
