import React, { useContext } from "react";
import { Formik } from "formik";
import { compose, withApollo } from "react-apollo";
import { connect } from "react-redux";
import CustomSingleSelect from "../../../components/shared/CustomSingleSelect";
import BUTTON_MODEL_LABEL from "../../../utils/constants/buttonModelLabel";
import {
  Module,
  NOTIFICATIONS_BANNER,
  TOPBANNER_LABEL,
  TOPBANNER_SHORTNAME
} from "../../../constants";
import { topBannerMessageValidationSchemaFactory } from "./top-banner-message-validation-schema";
import { TopBannerMessagenotify } from "./Top-Banner-Message-notify";
import { AdminPanelContext } from "../../context/context";
import {
  OwcButton,
  OwcSlideToggle,
  OwcTypography,
  OwcIcon,
  OwcInput,
  OwcTextarea
} from "@one/react";
import { CircularProgress } from "@mui/material";
import styles from "./TopBannerMessageNotifyForm.module.scss";
import { generateID } from "@digitallab/grid-common-components";
import { updateTopBannerFlag } from "../../landingPageUser/redux/actions";
const { adminTopBannerMessage } = Module.PAGE.ADMIN;

const colorOptions = Object.values(NOTIFICATIONS_BANNER).map((value) => ({
  value,
  label: value
}));

const TopBannerMessageTextFiled = ({
  formik,
  shortName,
  label,
  inputLength,
  ...props
}) => {
  return (
    <OwcInput
      style={{ marginTop: 20, width: "100%", marginBottom: 10 }}
      onBlur={formik.handleBlur}
      data-testid={`send-group-notify-${shortName}-input`}
      helperText={formik.touched[shortName] && formik.errors[shortName]}
      error={formik.errors[shortName]}
      validation-mode="realtime"
      name={shortName}
      label={label}
      value={formik.values[shortName]}
      onInputChange={formik.handleChange}
      onInputKeyDown={formik.handleChange}
      maxLength={inputLength}
      type="text"
      variant="filled"
      id={generateID.UUID(
        adminTopBannerMessage,
        `topBannerMessage`,
        "input_field"
      )}
      {...props}
    >
      {formik.errors[shortName] && (
        <>
          <span
            id={generateID.UUID(
              adminTopBannerMessage,
              `topBannerMessage`,
              "error_slot"
            )}
            slot="error-text"
          >
            {formik.errors[shortName]}
          </span>
        </>
      )}
      {formik.values[shortName] !== "" && (
        <OwcIcon
          id={generateID.UUID(
            adminTopBannerMessage,
            `topBannerMessage`,
            "icon_clear_circle"
          )}
          name="circle_clear_filled"
          slot="suffix"
          type="legacy"
          onClick={() => formik.setFieldValue(shortName, "", true)}
        />
      )}
    </OwcInput>
  );
};
const onSubmitFactory = (client, setValue) => {
  return async (values) => {
    TopBannerMessagenotify(client, values, setValue);
    setTimeout(() => {
      setValue("");
      setValue("topBannerMessage");
    }, 2000);
  };
};

const TopBannerMessageNotifyForm = ({
  client,
  NotificationBanner,
  UpdateTopBannerFlag
}) => {
  const { setValue } = useContext(AdminPanelContext);

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={topBannerMessageValidationSchemaFactory}
      initialValues={{ ...NotificationBanner }}
      isInitialValid={false}
      onSubmit={onSubmitFactory(client, setValue)}
      id={generateID.UUID(adminTopBannerMessage, `topBannerMessage`, "formik")}
    >
      {(formik) => (
        <div
          id={generateID.UUID(
            adminTopBannerMessage,
            `topBannerMessage`,
            "mainbox"
          )}
          className={styles.mainBoxForm}
          data-testid="main-box-form-for-send-group-notify"
        >
          <div
            id={generateID.UUID(
              adminTopBannerMessage,
              `topBannerMessage`,
              "div_message"
            )}
            data-testid="main-box-form-all-inputs-for-top-banner-message"
          >
            <OwcTypography
              id={generateID.UUID(
                adminTopBannerMessage,
                `topBannerMessage`,
                "header_title"
              )}
              className={styles.headerTitle}
            >
              Top banner message
            </OwcTypography>

            <TopBannerMessageTextFiled
              formik={formik}
              shortName={TOPBANNER_SHORTNAME.heading}
              label={TOPBANNER_LABEL.displayTitle}
              inputLength={"30"}
              required
              id={generateID.UUID(
                adminTopBannerMessage,
                `topBannerMessage`,
                "topbanner_1"
              )}
            />
            <TopBannerMessageTextFiled
              formik={formik}
              shortName={TOPBANNER_SHORTNAME.inforURL}
              label={TOPBANNER_LABEL.url}
              id={generateID.UUID(
                adminTopBannerMessage,
                `topBannerMessage`,
                "topbanner_2"
              )}
            />
          </div>
          <OwcTextarea
            style={{ marginTop: 20 }}
            name={TOPBANNER_SHORTNAME.displayText}
            label={TOPBANNER_LABEL.diplayText}
            value={formik.values["displayText"]}
            onInputChange={formik.handleChange}
            onInputKeyDown={formik.handleChange}
            error-text={formik.errors["displayText"]}
            onInputReset={() => formik.setFieldValue("displayText", "", true)}
            reset
            required
            maxLength={100}
            minLength={0}
            validation-mode="realtime"
            cols="18"
            rows={3}
            variant="filled"
            id={generateID.UUID(
              adminTopBannerMessage,
              `topBannerMessage`,
              "topbanner_textarea"
            )}
          />

          <div
            id={generateID.UUID(
              adminTopBannerMessage,
              `topBannerMessage`,
              "title_toggle"
            )}
            className={styles.titleslidetoggle}
          >
            <div
              className={styles.slideToggleTitle}
              id={generateID.UUID(
                adminTopBannerMessage,
                `topBannerMessage`,
                "slide_toggle"
              )}
            >
              <OwcTypography
                variant="subtitle1"
                id="testtypo"
                data-testid={`doc-data-enabled`}
              >
                {TOPBANNER_LABEL.enableBanner}
              </OwcTypography>
            </div>
            <OwcSlideToggle
              id={generateID.UUID(
                adminTopBannerMessage,
                `topBannerMessage`,
                "slide_toggle_enable"
              )}
              className={styles.SlideToggle}
              checked={formik.values?.enabled}
              onInputChange={(ev) => {
                console.log(ev);
                formik.setFieldValue(
                  "enabled",
                  !formik.values?.enabled,
                  ev.detail
                );
              }}
            />

            <div
              id={generateID.UUID(
                adminTopBannerMessage,
                `topBannerMessage`,
                "select_color"
              )}
              className={styles.selectBannerColor}
            >
              <CustomSingleSelect
                onChange={(e) => {
                  formik.setFieldValue("color", e);
                }}
                style={{ width: "516px" }}
                formik={formik}
                options={colorOptions}
                selectLabel={TOPBANNER_LABEL.selectColorCategory}
                required={true}
                showColor={true}
                name="color"
                value={formik.values.color}
                propValue={"value"}
                propLabel={"label"}
                testid="top-banner-message-color-input"
                id={generateID.UUID(
                  adminTopBannerMessage,
                  `topBannerMessage`,
                  "custom_single_select"
                )}
              />
            </div>
          </div>
          <div
            className={styles.actionFooter}
            id={generateID.UUID(
              adminTopBannerMessage,
              `topBannerMessage`,
              "actionFooter"
            )}
            data-testid="main-box-form-action-box-for-top-banner-message"
          >
            <OwcButton
              aria-label={"Save"}
              data-testid="top-banner-message-button"
              onClick={() => {
                formik.submitForm();
                UpdateTopBannerFlag(true);
              }}
              disabled={!formik.isValid || formik.isSubmitting}
              id={generateID.UUID(
                adminTopBannerMessage,
                `topBannerMessage`,
                "button_save"
              )}
            >
              {formik.isSubmitting && (
                <CircularProgress
                  data-testid="circular-progress-top-banner-message-button"
                  color="primary"
                  size={20}
                  style={{ marginRight: 6 }}
                  id={generateID.UUID(
                    adminTopBannerMessage,
                    `topBannerMessage`,
                    "circlularProgress"
                  )}
                />
              )}
              {BUTTON_MODEL_LABEL.save}
            </OwcButton>
          </div>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  NotificationBanner: state.user.NotificationBanner
});

export default compose(
  connect(mapStateToProps, { UpdateTopBannerFlag: updateTopBannerFlag }),
  withApollo
)(TopBannerMessageNotifyForm);
