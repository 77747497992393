import React from "react";
import styled, { css } from "styled-components";
import DeleteNotifyButton from "./DeleteNotifyButton";
import { OwcIconButton } from "@one/react";
import { Module } from "../../constants";
import { generateID } from "@digitallab/grid-common-components";

const justifyCenter = css`
  justify-content: center;
`;
const justifyEnd = css`
  justify-content: flex-end;
`;
const actionBoxShort = css`
  margin-bottom: -4px;
`;
const ActionBox = styled.div`
  display: flex;
  ${(props) => !props.justifyEnd && justifyCenter}
  ${(props) => props.justifyEnd && justifyEnd}
  justify-self: self-end;
  margin-right: -8px;
  ${(props) => props.short && actionBoxShort}
`;

const {notificationName} = Module.PAGE.NOTIFICATION;


const ActionOfNotify = ({ id, link, short, justifyEnd, children }) => {
  return (
    <ActionBox
      short={short}
      justifyEnd={justifyEnd}
      data-testid="action-box"
      onClick={(event) => {
        event.stopPropagation();
      }}
      id={generateID.UUID(notificationName, `action`, "box")}	
    >
      {children}
      <OwcIconButton
        disabled={!link}
        onClick={() => window.open(link, "_blank")}
        flat
        icon={"launch"}
        name="launch"
        type="legacy"
        data-testid="action-link-button"
        id={generateID.UUID(notificationName, `action`, "icon_link")}
      />
      <DeleteNotifyButton id={id} />
    </ActionBox>
  );
};

export default ActionOfNotify;
