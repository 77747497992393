import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { Module } from "../../constants";
import { checkFile } from "./helpers";
import { generateID } from "@digitallab/grid-common-components";

export const DefaultFileClearButton = styled.button`
  position: absolute;
  top: -12px;
  right: 0px;

  z-index: 1;

  width: 24px;
  height: 24px;

  padding: 0;

  font-size: 0;
  line-height: 0;

  color: var(--one-color-cobas-red-400);
  background: var(--one-color-cobas-white);

  border: 2px solid var(--one-color-cobas-white);
  border-radius: 12px;

  .one-icons {
    font-size: 24px;
    margin-left: -2px;
    margin-top: -2px;
  }
`;

const FileUploaderStyled = styled.div`
  position: relative;

  & [type="file"] {
    visibility: hidden;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden;
    position: absolute;
  }
  &,
  & .wrap {
    margin: 0;
    padding: 0;
    &:hover {
      cursor: pointer;
    }
  }

  ${DefaultFileClearButton} {
    display: none;
  }
  &:hover ${DefaultFileClearButton} {
    display: block;
  }
`;
const FileUploader = ({
  onFileSelect,
  onFailedRequirements,
  renderIcon,
  renderClear,
  uploadProps = {},
  shouldClear = false
}) => {
  const fileInput = React.useRef(null);
  const handleFileInput = useCallback(
    async (e) => {
      try {
        const file = e.target.files[0];
        await checkFile(file);
        onFileSelect(e.target.files[0]);
      } catch (err) {
        if (typeof onFailedRequirements === "function") {
          onFailedRequirements(err);
        }
      }
    },
    [onFileSelect, onFailedRequirements]
  );
  const handleClear = useCallback(() => {
    if (fileInput.current) {
      fileInput.current.value = null;
    }
  }, []);
  const clickHandler = useCallback(
    () => fileInput.current && fileInput.current.click(),
    []
  );
  useEffect(() => {
    if (shouldClear) {
      handleClear();
    }
  }, [shouldClear, handleClear]);
  return (
    <FileUploaderStyled data-testid="file-uploader" {...uploadProps}
      id={generateID.UUID(Module.PAGE.ADMIN.fileuploader, `appAndCategories`, "file")}
    >
      <input
        type="file"
        ref={fileInput}
        onChange={handleFileInput}
        accept="image/jpg, image/png, image/bmp"
        data-testid="file-input"
        id={generateID.UUID(Module.PAGE.ADMIN.fileuploader, `appAndCategories`, "file_input")}
      />
      <div
        data-testid="file-input-click"
        className="wrap"
        onClick={clickHandler}
        id={generateID.UUID(Module.PAGE.ADMIN.fileuploader, `appAndCategories`, "file_div")}
      >
        {renderIcon && renderIcon()}
      </div>
      {renderClear && renderClear({ clear: handleClear })}
    </FileUploaderStyled>
  );
};

export default FileUploader;
