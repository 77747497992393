import {
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_ALL
} from "../../gql/landingapi/mutations";

export const deleteNotification = async ({ client, id, all = false }) => {
  return await client.mutate({
    mutation: all ? DELETE_NOTIFICATION_ALL : DELETE_NOTIFICATION,
    variables: {
      id
    }
  });
};
