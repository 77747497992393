import React, { useEffect } from "react";
import SendGroupNotifyForm from "./SendGroupNotifyForm";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import styles from "./SendGroupNotifyForm.module.scss";
import { Module } from "../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const {adminSendGroupNotify} = Module.PAGE.ADMIN;

const SendGroupNotifyMainPage = () => {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView({
      documentTitle: "Admin/SendGroupNotify",
      href: `${window.location?.origin}/Admin/SendGroupNotify`
    });
    // eslint-disable-next-line
  }, []);
  return (
    <div
    id={generateID.UUID(adminSendGroupNotify, `sendgroup`, "main")}
      className={styles.MainPageBox}
      data-testid="main-page-for-send-group-notify"
    >
      <SendGroupNotifyForm />
    </div>
  );
};

export default SendGroupNotifyMainPage;
