export const LOAD_USER_INFO = "[USER_INFO] LOAD_USER_INFO";

export const SELECT_GROUP_USER_INFO = "[USER_INFO] SELECT_GROUP_USER_INFO";

export const SELECT_CATEGORY_USER_INFO =
  "[USER_INFO] SELECT_CATEGORY_USER_INFO";

export const SELECT_NOTIFICATION_USER_INFO =
  "[USER_INFO] SELECT_NOTIFICATION_USER_INFO";

export const UPDATE_GROUP_APP = "[USER_INFO] UPDATE_GROUP_APP";

export const ADD_WIDGET_TO_DASHBOARD = "[USER_INFO] ADD_WIDGET_TO_DASHBOARD";

export const UPDATE_DASHBOARD = "[USER_INFO] UPDATE_DASHBOARD";

export const UPDATE_DASHBOARD_WIDGETS = "[USER_INFO] UPDATE_DASHBOARD_WIDGETS";

export const LOAD_SOURCES = "[USER_INFO] LOAD_SOURCES";

export const UPDATE_ACCORDION_STATE = "[USER_INFO] UPDATE_ACCORDION_STATE";

export const UPDATE_FAVOURITE_EQUIPMENT_STATE =
  "[USER_INFO] UPDATE_FAVOURITE_EQUIPMENT_STATE";

export const UPDATE_FAVOURITE_EQUIPMENT_VIEW_TYPE_STATE =
  "[USER_INFO] UPDATE_FAVOURITE_EQUIPMENT_VIEW_TYPE_STATE";

export const UPDATE_USER_FILTERS = "[USER_INFO] UPDATE_USER_FILTERS";

export const UPDATE_LAST_FAV_FILTER = "[USER_INFO] UPDATE_LAST_FAV_FILTER";

export const UPDATE_NOTIFICATION_BANNER =
  "[USER_INFO] UPDATE_NOTIFICATION_BANNER";

export const UPDATE_TOP_BANNER_FLAG = "[USER_INFO] UPDATE_TOP_BANNER_FLAG";
