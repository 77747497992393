export function deleteEntryPointSelectionFactory(
  customGroupForm,
  setCustomGroupForm
) {
  return (application, entryPointId) => {
    customGroupForm.appsWithEntryPoints = customGroupForm.appsWithEntryPoints
      .map((theAppWithEntryPoints) => {
        if (theAppWithEntryPoints.application === application) {
          theAppWithEntryPoints.entryPoints =
            theAppWithEntryPoints.entryPoints.filter(
              (entryPoint) => entryPoint !== entryPointId
            );
          return { ...theAppWithEntryPoints };
        } else {
          return { ...theAppWithEntryPoints };
        }
      })
      .filter(
        (theAppWithEntryPoints) => theAppWithEntryPoints.entryPoints.length > 0
      );
    setCustomGroupForm({ ...customGroupForm });
  };
}
