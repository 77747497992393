import { useContext, useState } from "react";
import WidgetDialogForm from "./WidgetDialogForm";
import WidgetsForm from "./WidgetsForm";
import WidgetsMetadata from "./WidgetsMetadata";
import { AllWidgetsContext } from "../context/context";
import { isWidgetUnique } from "./is-widget-unique";
import { widgetNotUniqueError } from "./validate-metadata";
import { OwcTypography } from "@one/react";

const WidgetEditDialog = ({
  widget,
  metadata,
  onWidgetChange,
  onApprove,
  onCancel,
  open,
  loading
}) => {
  const [valid, setValid] = useState(false);
  const [error, setError] = useState(null);
  const { widgets } = useContext(AllWidgetsContext);
  let title = "";
  let buttonTitle = "";
  let dataTestId = "";
  if (widget.id && metadata) {
    title = "Update widget";
    buttonTitle = "Update widget";
    dataTestId = "update";
  }
  if (widget.id && !metadata) {
    title = "Edit widget";
    buttonTitle = "Confirm changes";
    dataTestId = "edit";
  }
  if (!widget.id) {
    title = "Add widget";
    buttonTitle = "Add widget";
    dataTestId = "add";
  }

  const handleChange = (key) => (value) => {
    onWidgetChange({
      ...widget,
      [key]: value
    });
    setError(null);
  };

  const handleApprove = () => {
    if (!isWidgetUnique(widgets, widget)) {
      return setError(widgetNotUniqueError);
    }
    setError(null);
    onApprove();
  };

  return (
    <WidgetDialogForm
      mainTitle={title}
      countTitle={
        metadata && (
          <>
            <strong>2</strong> of 2 steps
          </>
        )
      }
      approveButtonTitle={buttonTitle}
      dataTestId={dataTestId}
      onApprove={handleApprove}
      onCancel={onCancel}
      open={open}
      loading={loading}
      disabled={Boolean(error) || !valid}
    >
      <WidgetsForm
        name={widget.name}
        description={widget.description}
        onNameChange={handleChange("name")}
        onDescriptionChange={handleChange("description")}
        onValidChange={setValid}
      />
      {error && (
        <OwcTypography
          data-testid="widget-edit-dialog-error"
          color="error"
          variant="caption"
        >
          {error}
        </OwcTypography>
      )}
      {metadata && <WidgetsMetadata metadata={metadata} />}
    </WidgetDialogForm>
  );
};

export default WidgetEditDialog;
