import React from "react";
import AddWidgetButton from "./AddWidgetButton";
import WidgetsList from "./WidgetsList";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import styles from "./WidgetsPage.module.scss";
import { Module } from "../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const {adminWidget} = Module.PAGE.ADMIN;

export const WidgetAdd = React.createContext(
  <AddWidgetButton showTextOnly="True" />
);

const WidgetsPage = () => {
  const { trackPageView } = useMatomo();
  React.useEffect(() => {
    trackPageView({
      documentTitle: "Admin/Widget",
      href: `${window.location?.origin}/Admin/Widget`
    });
    // eslint-disable-next-line
  }, []);
  return (
    <div className={styles.WidgetMainBox} id={generateID.UUID(adminWidget, `widgetpage`, "main")}>
      <div className={styles.HeaderBox} id={generateID.UUID(adminWidget, `widgetpage`, "header")}>
        <div className={styles.Title} id={generateID.UUID(adminWidget, `widgetpage`, "title")} ></div>
        <AddWidgetButton />
      </div>
      <WidgetsList />
    </div>
  );
};

export default WidgetsPage;
