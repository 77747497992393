import React from "react";
import DescriptionCard from "./DescriptionCard";
import { DEFAULT_DESCRIPTION_CONSTANT } from "../../constants";
import { useContext } from "react";
import { BackendInfoContext } from "../backend-info-context/context";

const ReturnItems = ({ items }) => {
  return items?.map((item, id) =>
    item ? (
      <DescriptionCard title={`Backend ${id + 1}`} key={item?.id} {...item} id={item?.id} />
    ) : null
  );
};
const BackendInfo = () => {
  const { backendInfo } = useContext(BackendInfoContext);
  const defaultConstantValue = { ...DEFAULT_DESCRIPTION_CONSTANT };
  delete defaultConstantValue?.updatedAt;
  const backendData = backendInfo ? [backendInfo] : [defaultConstantValue];
  return (
    <>
      <ReturnItems items={backendData} />
    </>
  );
};

export default BackendInfo;
