import React from "react";
import { connect } from "react-redux";
import { selectGroupUserInfo as selectGroupUserInfoActions } from "../features/landingPageUser/redux/actions";
import { OwcSelectDropdown, OwcListItem } from "@one/react";
import {snakeCase} from "lodash";
const QuickAccessFilter = ({
  activeFilter,
  typeSelected,
  setActiveFilter,
  groupMenuArray
}) => {
  const selectChangeHandler = (e) => {
    const index = e.detail[0];
    setActiveFilter(groupMenuArray[index]);
  };
  return (
    <>
      <OwcSelectDropdown
        onSelectChange={selectChangeHandler}
        outlined
        size="compact"
        style={{ width: 200 }}
      >
        {groupMenuArray.map((v, i) => (
          <OwcListItem key={`${snakeCase(v)}_${i}`} selected={activeFilter === v}>{v}</OwcListItem>
        ))}
      </OwcSelectDropdown>
    </>
  );
};

const selectGroups = (groups) =>
  groups?.map((group) => ({
    id: group.id,
    name: group.name,
    icon: group.icon,
    iconPath: group.iconPath
  }));

const mapStateToProps = (store) => ({
  applicationGroups: selectGroups(store.user.allGroups),
  selected: store.user.selected?.id,
  typeSelected: store.user.selected?.type
});

export default connect(mapStateToProps, {
  selectGroupUserInfo: selectGroupUserInfoActions
})(QuickAccessFilter);
