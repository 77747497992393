import Search from "./Search";
import styles from "./TopbarForApplication.module.scss";

import { Module } from "../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const  { applicationTable } = Module.PAGE.ADMIN;
const TopBarForApplication = ({
  setQuery,
  filterData,
  showTextOnly = false
}) => {
  return (
    <>
      <div className={styles.topbarforapplicationContainer} id={generateID.UUID(applicationTable, `applicationtable`, "topbar_style")}>
        <Search
          setQuery={(q) => {
            setQuery(q);
            filterData(q);
          }}
          placeholder="Search for application"
          compact={true}
          id={generateID.UUID(applicationTable, `applicationtable`, "search")}

        />
      </div>
    </>
  );
};

export default TopBarForApplication;
