import React, { useEffect, forwardRef, useState, useRef } from "react";
import {
  OwcButton,
  OwcPagination,
  OwcProgressSpinner,
  OwcTable,
  OwcTableBody,
  OwcTableCell,
  OwcTableHeader,
  OwcTableHeaderCell,
  OwcTableRow
} from "@one/react";
import { emptyApplication } from "../../features/adminPanel/appAndCategories/emptyApplication";
import BUTTON_MODEL_LABEL from "../../utils/constants/buttonModelLabel";
import Add from "../../../src/features/categories/AddOrEdit";

const isObject = (item) => typeof item === "object" && item !== null;
export const AddCategory = React.createContext(<Add showTextOnly={true} />);

const ApplicationCategoryList = ({
  meta,
  data = [],
  onRequestSort,
  isReverseOrder,
  orderBy,
  onRowClick,
  loading,
  preExpanded,
  fetching,
  Component = null,
  setFilteredData,
  setApplication,
  setPageNo,
  showPerPage,
  setShowPerPage,
  totalCount,
  isSelected = () => false
}) => {
  const bodyRef = useRef();
  if (!isObject(meta)) {
    return null;
  }
  if (!isObject(meta?.fields)) {
    return null;
  }
  if (!Array.isArray(data)) {
    return null;
  }

  let body = null;

  if (data.length === 0) {
    body = <div data-testid="custom-list-no-data">No data</div>;
  } else {
    body = data.map((item, index) => {
      const id =
        item?.[meta?.rowId] ||
        item?.id ||
        (typeof meta?.getId === "function" ? meta?.getId(item) : index);
      return (
        <ItemRow
          expandable={meta?.expandable}
          ExpandedComponent={meta?.ExpandedComponent}
          item={item}
          key={id}
          id={id}
          fields={meta?.fields}
          preExpanded={preExpanded}
          bodyRef={bodyRef}
          selected={isSelected(item)}
          lastElement={false}
          onRowClick={onRowClick}
          meta={meta}
        />
      );
    });
  }

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        margin: "10px 0 0",
        filter: "none"
      }}
    >
      <OwcTable
        data-testid="custom-list"
        height={"300px"}
        spacing="compact"
        style={{ boxShadow: "none" }}
      >
        <OwcTableHeader sticky>
          <Header
            meta={meta}
            onRequestSort={onRequestSort}
            isReverseOrder={isReverseOrder}
            orderBy={orderBy}
          />
        </OwcTableHeader>
        <OwcTableBody ref={bodyRef} loading={loading}>
          {body}
        </OwcTableBody>
        {Component}
        <div
          slot="footer"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            justifyContent: "space-between"
          }}
        >
          <div></div>
          <div>
            {loading && <OwcProgressSpinner />}
            <OwcPagination
              onPageChange={(e) => {
                setShowPerPage(e.detail.rowsPerPage);
                setPageNo(e.detail.page);
              }}
              options={[5, 10, 20, 50]}
              rowsPerPage={showPerPage}
              total={Math.ceil(totalCount / showPerPage)}
            >
              <span slot="rows-per-page">Rows per page</span>
            </OwcPagination>
          </div>
          {setApplication ? (
            <OwcButton
              onClick={() => setApplication(emptyApplication)}
              data-testid="add-application-dialog-open-button"
            >
              {BUTTON_MODEL_LABEL.addApplication}
            </OwcButton>
          ) : (
            <Add />
          )}
        </div>
      </OwcTable>
    </div>
  );
};

const SortingHeader = ({
  xkey,
  meta,
  orderBy,
  onRequestSort,
  isReverseOrder
}) => {
  return (
    //header of table
    <OwcTableHeaderCell
      sortable
      onSortChange={isReverseOrder}
      data-testid={`list-head-${xkey}`}
      key={xkey}
      {...(meta?.fields?.[xkey]?.headProps ?? {})}
      onClick={() => {
        if (xkey === orderBy) {
          onRequestSort(xkey, !isReverseOrder);
        } else {
          onRequestSort(xkey, false);
        }
      }}
    >
      {meta?.fields?.[xkey]?.text}
    </OwcTableHeaderCell>
  );
};

const HeaderName = ({ xkey, meta }) => {
  return (
    <OwcTableHeaderCell
      data-testid={`list-head-${xkey}`}
      key={xkey}
      {...(meta?.fields?.[xkey]?.headProps ?? {})}
    >
      {meta?.fields?.[xkey]?.text}
    </OwcTableHeaderCell>
  );
};
export const Header = ({ meta, onRequestSort, isReverseOrder, orderBy }) => {
  return Object.keys(meta.fields).map((key) => {
    const Component = meta?.fields?.[key]?.headerComponent
      ? meta?.fields?.[key]?.headerComponent
      : null;
    if (!Component) {
      return meta.fields[key]?.sortable ? (
        <SortingHeader
          xkey={key}
          meta={meta}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          isReverseOrder={isReverseOrder}
        />
      ) : (
        <HeaderName xkey={key} meta={meta} />
      );
    }
    return (
      <OwcTableHeaderCell
        data-testid={`list-head-${key}`}
        key={key}
        {...(meta?.fields?.[key]?.headProps ?? {})}
      >
        <Component {...(meta?.fields?.[key]?.headProps ?? {})} />
      </OwcTableHeaderCell>
    );
  });
};
const mapItemsToRows = ({
  fields,
  id,
  item,
  toggleExpanded,
  shouldExpand,
  selected
}) => {
  return Object.keys(fields).map((field) => {
    const Component = fields?.[field]?.component
      ? fields?.[field].component
      : null;
    if (!Component) {
      return (
        <OwcTableCell
          style={{ alignItems: "center" }}
          key={`item-row-${id}-${field}`}
          data-testid={`item-row-${id}-${field}`}
          {...(fields?.[field]?.cellProps ?? {})}
        >
          {item[field] && item[field] !== "null" ? item[field] : "-"}
        </OwcTableCell>
      );
    }
    return (
      <OwcTableCell
        style={{ alignItems: "center" }}
        key={`item-row-${id}-${field}`}
        data-testid={`item-row-${id}-${field}`}
        {...(fields?.[field]?.cellProps ?? {})}
      >
        <Component
          item={item}
          {...(fields?.[field]?.cellProps ?? {})}
          toggleExpanded={toggleExpanded}
          expanded={shouldExpand}
          selected={selected}
        />
      </OwcTableCell>
    );
  });
};

export const ItemRow = forwardRef(
  (
    {
      item,
      fields,
      id,
      expandable,
      ExpandedComponent = React.Fragment,
      preExpanded,
      selected,
      lastElement,
      onRowClick,
      meta
    },
    elementRef
  ) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const ref = useRef();

    const toggleExpanded = () => {
      setIsExpanded((val) => !val);
    };

    useEffect(() => {
      if (preExpanded === item && expandable === true) {
        ref.current.scrollIntoView();
        setIsExpanded(true);
      }
    }, [ref, preExpanded, item, expandable]);

    const shouldExpand = isExpanded && expandable === true;

    return (
      <div ref={lastElement ? elementRef : null}>
        <div
          onClick={(event) => onRowClick && onRowClick(event, item)}
          data-testid={`${id}-row`}
          ref={ref}
        >
          <OwcTableRow>
            {mapItemsToRows({
              fields,
              id,
              item,
              toggleExpanded,
              shouldExpand,
              selected
            })}
          </OwcTableRow>
        </div>
      </div>
    );
  }
);

export default ApplicationCategoryList;
