import React from "react";
import { OwcIconButton, OwcListItem, OwcMenu } from "@one/react";
import ReactDOM from "react-dom";

const AdminPanelEditOrDelete = ({
  item,
  setApplication,
  setApplicationToDelete,
  openDialog
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnEdit = () => {
    setApplication(item);
    setAnchorEl(null);
  };

  const handleOnDelete = () => {
    setApplicationToDelete(item);
    openDialog();
    setAnchorEl(null);
  };

  const moreIconItems = [
    {
      name: "Edit"
    },
    {
      name: "Delete"
    }
  ];

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const itemList = moreIconItems.map((item) => {
    return (
      <OwcListItem
        onClick={item.name === "Edit" ? handleOnEdit : handleOnDelete}
      >
        {item.name}
      </OwcListItem>
    );
  });

  return (
    <>
      <OwcIconButton
        type="outlined"
        flat
        icon="more_vertical"
        onClick={handleClick}
        id={item.name}
        // id={generateID.UUID(Module.editOrDelete, 1, "menu")}
        // id={generateID.labelID(editOrDelete, "menu")}
      />
      {ReactDOM.createPortal(
        <OwcMenu
          // anchor={generateID.labelID(editOrDelete, "menu")}
          anchor={item.name}
          visible={anchorEl}
          onClickOutside={handleClose}
        >
          {itemList}
        </OwcMenu>,
        document.body
      )}
    </>
  );
};
export default AdminPanelEditOrDelete;
