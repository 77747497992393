import Snackbar from "../../snackbar/Snackbar";
import {
  CREATE_NOTIFICATION_BANNER,
  UPDATE_NOTIFICATION_BANNER
} from "../../../gql/landingapi/mutations";
export const checkSubscribeCondition = (id) => {
  return (data) =>
    data.onUpdateLandingPageNotificationCommand.id === id &&
    data.onUpdateLandingPageNotificationCommand.status !== null;
};

export const TopBannerMessagenotify = async (
  client,
  values,
  UpdateNotificationBanner,
  setValue
) => {
  try {
    let displayMessage = "";
    let query = "";
    if (values.id === "") {
      query = CREATE_NOTIFICATION_BANNER;
      displayMessage = "Top banner message successfully created";
    } else {
      query = UPDATE_NOTIFICATION_BANNER;
      displayMessage = "Top banner message successfully saved";
    }

    await client.mutate({
      mutation: query,
      variables: values
    });

    Snackbar({
      appName: "",
      icon: "circle_confirm",
      type: "success",
      text: displayMessage
    });
  } catch (err) {
    console.log(err);
    Snackbar({
      appName: "",
      text: "Top banner message updation failure"
    });
  }
};
