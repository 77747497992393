import WidgetsBarTopHeader from "./WidgetsBarTopHeader";

const WidgetsBarHeader = ({ widgetsBarClose }) => {
  return (
    <>
      <WidgetsBarTopHeader
        data-testid="widgets-drawer-header"
        onClick={widgetsBarClose}
      ></WidgetsBarTopHeader>
    </>
  );
};

export default WidgetsBarHeader;
