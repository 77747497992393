import { CircularProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Module } from "../../../constants";
import { AllWidgetsContext } from "../../adminPanel/widgets/context/context";
import { saveImportMapToDOM, toImportMap } from "./helpers";
import { generateID } from "@digitallab/grid-common-components";

export const LoadImportMap = ({ children }) => {
  const { widgets } = useContext(AllWidgetsContext);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!widgets) {
      return;
    }
    const notificationWidget = {
      description: "Notification Widget",
      id: "notificationApp",
      metadataURL:
        "https://notification.dev.digitallab.roche.com/widget/v1/settings.json",
      name: "Notification Widget",
      releaseDate: "2021-11-17T08:00:00.000Z",
      version: "1.0",
      widgetURL: process.env.REACT_APP_NOTIFICATION_MFE,
      fromAppName: "Notification widget",
      fromAppSource: "",
      supportedSizes: []
    };
    widgets.push(notificationWidget);
    const qMaps = toImportMap({
      data: widgets,
      mapItem: ({ widgetURL }) => widgetURL
    });
    saveImportMapToDOM({
      importsMap: {
        imports: qMaps
      },
      callback: () => setLoading(false)
    });
  }, [widgets]);
  if (loading) {
    return (
      <div
        className="loading"
        data-testid="loading-import-map"
        id={generateID.UUID(
          Module.PAGE.DASHBOARD.name,
          `dashboard_import_map`,
          "div"
        )}
      >
        <CircularProgress size={30} />
      </div>
    );
  }
  return children;
};
