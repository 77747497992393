import styled, { css } from "styled-components";
import { useContext, useEffect, useMemo, useState, useRef } from "react";
import ReactGridLayout from "react-grid-layout";
import { useSelector, useDispatch } from "react-redux";
import { GridContext } from "./context/context";
import WidgetItem from "./WidgetItem";
import { updateDashboardWidgets } from "../../landingPageUser/redux/actions";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";
import { AllWidgetsContext } from "../../adminPanel/widgets/context/context";
const Wrapper = styled.div`
  position: relative;
  margin-left: -0.5rem;
  overflow: hidden;

  .react-grid-item.react-grid-placeholder {
    background: transparent;
  }

  .react-draggable-dragging {
    box-shadow: 0px 10px 30px 10px rgba(57, 69, 80, 0.32);
  }
  & .widget {
    max-height: 100%;
    max-width: 100%;
    overflow-x: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

const theStrippedBackground = css`
  background-size: 144px 144px;
  background-repeat: repeat;
  background-image: linear-gradient(
      to right,
      rgba(0, 102, 204, 0.05) 0px,
      rgba(0, 102, 204, 0.05) 128px,
      transparent 128px,
      transparent 144px
    ),
    linear-gradient(
      to bottom,
      rgba(0, 102, 204, 0.05) 0px,
      rgba(0, 102, 204, 0.05) 128px,
      transparent 128px,
      transparent 144px
    );
`;
const StrippedBackground = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  bottom: 16px;
  width: ${(props) => props.width - 32}px;

  ${(props) => props.editable && theStrippedBackground}
`;

const minimumColumns = 5;

const ExpandableGrid = (props) => {
  const [columns, setColumns] = useState(minimumColumns);
  const ref = useRef(null);
  const [layoutWidth, setLayoutWidth] = useState(0);
  const maxColumns = useMemo(
    () =>
      props.layout.reduce((max, item) => {
        const size = item.x + item.w;

        return max > size ? max : size;
      }, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.layout, columns]
  );

  useEffect(() => {
    setColumns(Math.max(minimumColumns, maxColumns));
  }, [maxColumns]);

  useEffect(() => {
    setLayoutWidth(ref.current ? ref.current.offsetWidth : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper
      data-testid="dashboard-grid"
      ref={ref}
      id={generateID.UUID(
        Module.PAGE.DASHBOARD.name,
        `dashboard_grid`,
        "wrapper"
      )}
    >
      <StrippedBackground
        width={layoutWidth}
        editable={props.isDraggable}
        id={generateID.UUID(
          Module.PAGE.DASHBOARD.name,
          `dashboard_stripped`,
          "div"
        )}
      />
      <ReactGridLayout
        {...props}
        cols={Math.max(maxColumns, columns)}
        rowHeight={128}
        width={layoutWidth}
        id={generateID.UUID(
          Module.PAGE.DASHBOARD.name,
          `dashboard`,
          "grid_layout"
        )}
      />
    </Wrapper>
  );
};

export const widgetsToLayout = (widgets) =>
  widgets?.map((widget) => ({
    i: widget.widgetId,
    x: widget.locationX,
    y: widget.locationY,
    w: widget.size.width,
    h: widget.size.height,
    minW: widget.size.width,
    minH: widget.size.height,
    maxW: widget.size.width,
    maxH: widget.size.height
  }));

const DashboardLibGrid = () => {
  let widgets = useSelector((state) => state.user?.dashboard?.widgets);
  const { widgets: listedWidgets } = useContext(AllWidgetsContext);
  const dispatch = useDispatch();
  // To check if widget in listWidget & listDashboard has same ID
  const activeWidgetIds = listedWidgets.map((widget) => widget.id);
  widgets = widgets.filter((widgetFromStore) => {
    return activeWidgetIds.indexOf(widgetFromStore.widgetId) !== -1;
  });
  const { isGridEditable, pendingSaving } = useContext(GridContext);
  return (
    <ExpandableGrid
      isResizable={false}
      isDraggable={isGridEditable && !pendingSaving}
      onLayoutChange={(layout) => {
        dispatch(
          updateDashboardWidgets(
            layout.map((item) => {
              const widget = widgets.find(
                (theWidget) => theWidget.widgetId === item.i
              );
              return {
                ...widget,
                locationX: item.x,
                locationY: item.y,
                size: {
                  ...widget.size,
                  width: item.w,
                  height: item.h
                }
              };
            })
          )
        );
      }}
      layout={widgetsToLayout(widgets)}
      className="layout"
      compactType={"horizontal"}
      margin={[16, 16]}
      draggableCancel=".dont-drag"
      id={generateID.UUID(
        Module.PAGE.DASHBOARD.equipmentGrid,
        `dashboard`,
        "div"
      )}
    >
      {widgets?.map((widget) => (
        <WidgetItem
          elevation={1}
          pendingSaving={pendingSaving}
          widgets={widgets}
          key={widget.widgetId}
          isGridEditable={isGridEditable}
          widget={widget}
          id={generateID.UUID(
            Module.PAGE.DASHBOARD.equipmentGrid,
            `dashboard_${widget.widgetId}`,
            "item"
          )}
        />
      ))}
    </ExpandableGrid>
  );
};

export default DashboardLibGrid;
