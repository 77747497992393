import { OwcIcon, OwcInput } from "@one/react";
import LaunchStepButtons from "./LaunchStepButtons";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../constants";

const LaunchStepLinkInputWithButtons = ({
  entryPoint,
  validationInputs,
  dirtyInputs,
  handleEntryPointUpdate,
  setDirtyInputs,
  uniqueNameEntryPoint,
  addEntryPoint,
  classes,
  updateEntryPoint,
  setEntryPoint,
  setUniqueNameEntryPoint
}) => {
  return (
    <div 
    id={generateID.UUID(Module.PAGE.ADMIN.launchStepLink, `launchStepLink`, "div")}
    style={{ display: "flex", alignItems: "flex-start" }}>
      <OwcInput
        id={generateID.UUID(Module.PAGE.ADMIN.launchStepLink, `launchStepLink`, "input")}
        required
        label="Launch step link *"
        variant="filled"
        size="small"
        value={entryPoint.url}
        style={{ width: "100%" }}
        FormHelperTextProps={{
          "data-testid": "application-edit-form-launch-step-url-helper-text"
        }}
        error={!validationInputs.entryPoint_url && dirtyInputs.entryPoint_url}
        data-testid="application-edit-form-launch-step-url-input"
        onInputKeyDown={(event) =>
          handleEntryPointUpdate({ key: "url", value: event.target.value })
        }
        onBlur={() => {
          setDirtyInputs({
            ...dirtyInputs,
            entryPoint_url: true
          });
        }}
      >
        {entryPoint?.url?.length > 0 && (
          <OwcIcon
            slot="suffix"
            type="filled"
            name="circle_clear"
            onClick={() => {
              handleEntryPointUpdate({ key: "url", value: "" });
              setDirtyInputs({
                ...dirtyInputs,
                entryPoint_url: true
              });
            }}
            id={generateID.UUID(Module.PAGE.ADMIN.launchStepLink, `launchStepLink`, "icon")}
            className="pointer"
          />
        )}
        <label 
        id={generateID.UUID(Module.PAGE.ADMIN.launchStepLink, `launchStepLink`, "label")}
        slot="error-text">
          {!validationInputs.entryPoint_url &&
            dirtyInputs.entryPoint_url &&
            "Invalid link, should be a valid URL, e.g. https://roche.com"}
          {!validationInputs.entryPoint_url && dirtyInputs.entryPoint_url}
        </label>
      </OwcInput>
      <LaunchStepButtons
        entryPoint={entryPoint}
        uniqueNameEntryPoint={uniqueNameEntryPoint}
        validationInputs={validationInputs}
        addEntryPoint={addEntryPoint}
        classes={classes}
        updateEntryPoint={updateEntryPoint}
        setEntryPoint={setEntryPoint}
        setDirtyInputs={setDirtyInputs}
        dirtyInputs={dirtyInputs}
        setUniqueNameEntryPoint={setUniqueNameEntryPoint}
      />
    </div>
  );
};
export default LaunchStepLinkInputWithButtons;
