import React from "react";
import styled from "styled-components";
import CustomGroupedAppsList from "./CustomGroupedAppsList";
import CustomCategoryAppsList from "./CustomCategoryAppsList";
import CustomGroupedAppsCard from "./CustomGroupedAppsCard";
import { OwcTypography } from "@one/react";

const ApplicationGroupStyled = styled.div`
  display: flex;
  flex-direction: column;
`;
const SectionNameStyledOne = styled.div`
  padding-top: 10px;
`;

const CustomGroupedAppsGroup = ({
  customGroups = [],
  id = "",
  name = "",
  Component = CustomGroupedAppsCard,
  groupedType
}) => {
  return (
    <>
      <ApplicationGroupStyled data-testid="custom-groups-section">
        {name && (
          <SectionNameStyledOne>
            <OwcTypography
              variant="subtitle1"
              data-testid="custom-groups-section-name"
            >
              {name}
            </OwcTypography>
          </SectionNameStyledOne>
        )}
        {groupedType === "category-group" ? (
          <CustomCategoryAppsList
            key={id}
            customGroups={customGroups}
            Component={Component}
            name={name}
            groupedType={groupedType}
          />
        ) : (
          <CustomGroupedAppsList
            key={id}
            customGroups={customGroups}
            Component={Component}
            name={name}
            groupedType={groupedType}
          />
        )}
      </ApplicationGroupStyled>
    </>
  );
};

export default CustomGroupedAppsGroup;
