import { useEffect, useMemo, useState } from "react";
import {
  checkNoTabsAndNewline,
  checkValidInputWithNewlineAnyChar
} from "../../../../utils/helpers/text";
import { OwcIcon, OwcInput } from "@one/react";

const WidgetsForm = ({
  name,
  description,
  onNameChange,
  onDescriptionChange,
  onValidChange
}) => {
  const [touchedName, setTouchedName] = useState(false);
  const [touchedDesc, setTouchedDesc] = useState(false);

  const nameError = useMemo(() => !checkNoTabsAndNewline(name), [name]);
  const descError = useMemo(
    () => !checkValidInputWithNewlineAnyChar(description),
    [description]
  );

  useEffect(() => {
    onValidChange(!nameError && !descError);
  }, [nameError, descError, onValidChange]);

  return (
    <div>
      <div style={{ paddingTop: 4, paddingBottom: 16 }}>
        <OwcInput
          onBlur={() => setTouchedName(true)}
          data-testid="widget-name-input"
          id="standard-secondary"
          maxLength="60"
          label="Widget name*"
          error={touchedName && nameError}
          color="primary"
          variant="filled"
          required={true}
          style={{ width: "100%" }}
          size="small"
          value={name}
          onInputChange={(event) => onNameChange(event.target.value)}
          FormHelperTextProps={{
            "data-testid": "widget-name-input-helper-text"
          }}
        >
          <span slot="error-text">
            {touchedName &&
              nameError &&
              `Name cannot contain tabs and new line characters and must have at least 1 character`}
          </span>
          {name?.length > 0 && (
            <OwcIcon
              dataTestId="widget-name-input-clear-button"
              type="filled"
              slot="suffix"
              name="circle_clear"
              onClick={() => onNameChange(null)}
              className="pointer"
            />
          )}
        </OwcInput>
      </div>
      <div style={{ paddingTop: 4, paddingBottom: 16 }}>
        <OwcInput
          onBlur={() => setTouchedDesc(true)}
          data-testid="description-input"
          id="standard-secondary"
          label="Description*"
          maxLength="300"
          error={touchedDesc && descError}
          color="primary"
          variant="filled"
          required={true}
          style={{ width: "100%" }}
          size="small"
          value={description}
          onInputChange={(event) => onDescriptionChange(event.target.value)}
          FormHelperTextProps={{
            "data-testid": "description-input-helper-text"
          }}
        >
          <span slot="error-text">
            {touchedDesc &&
              descError &&
              `Description can contain up to 6 new line characters and must have at least 1 character`}
          </span>
          {description?.length > 0 && (
            <OwcIcon
              data-testid="application-edit-form-clear-button-description-button"
              type="filled"
              slot="suffix"
              name="circle_clear"
              onClick={() => onDescriptionChange(null)}
              className="pointer"
            />
          )}
        </OwcInput>
      </div>
    </div>
  );
};

export default WidgetsForm;
