import React from "react";
import WidgetsTable from "./WidgetsTable";
import UpdateData from "./UpdateData";
import { fetchWidgets } from "./fetch-widget";
import WidgetTableMeta from "./WidgetMeta";
import { Module } from "../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const {adminWidget} = Module.PAGE.ADMIN;

const WidgetsList = () => {
  return (
    <>
      <WidgetsTable
        dataTestId="admin-panel-widget-table"
        meta={WidgetTableMeta}
        id={generateID.UUID(adminWidget, `widgetList`, "widgetTable")}
      />
      <UpdateData fetchingFn={fetchWidgets} />
    </>
  );
};

export default WidgetsList;
