import React from "react";
import styled from "styled-components";
import NotificationBackButton from "../NotificationBackButton";
import FiltersRow from "./FiltersRow";
import { deleteNotification } from "../deleteNotification";
import GrouBySeverityCheckbox from "./GrouBySeverityCheckbox";
import { OwcButton } from "@one/react";
import { Module } from "../../../constants";
import { generateID } from "@digitallab/grid-common-components";

const {notificationName} = Module.PAGE.NOTIFICATION;

export const PageTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
`;
export const Headerbox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderButtons = styled.div`
  display: flex;
  && > * {
    margin-right: 8px;
  }

  && > :last-child {
    margin-right: 0;
  }
`;

const DeleteSelectedButton = ({
  pendingDelete,
  setPendingDelete,
  selected,
  client,
  dispatchFiltersAction,
  setSelected
}) => {
  return (
    <OwcButton
      id={generateID.UUID(notificationName, `deleteselectied`, "more_button")}
      data-testid="full-notification-delete-more-button"
      variant="error"
      disabled={pendingDelete}
      onClick={async () => {
        setPendingDelete(true);
        try {
          await Promise.all(
            selected.map(async (v) =>
              deleteNotification({
                client,
                id: v.id,
                all: true
              })
            )
          );
          await client.reFetchObservableQueries();
          dispatchFiltersAction({ type: "nextData" });
        } catch (e) {}
        setPendingDelete(false);
        setSelected([]);
      }}
    >
      Delete selected ({selected.length})
    </OwcButton>
  );
};

const NotificationsHeader = ({
  selected,
  pendingDelete,
  setPendingDelete,
  client,
  dispatchFiltersAction,
  setSelected,
  openFilters,
  setOpenFilters
}) => {
  return (
    <Headerbox 
    id={generateID.UUID(notificationName, `header_notification`, "box")}
    data-testid="full-notification-header-box">
      <PageTitle 
      id={generateID.UUID(notificationName, `header_notification`, "title")}
      data-testid="full-notification-page-title">
        <NotificationBackButton  id={generateID.UUID(notificationName, `header_notification`, "back_button")}/>
        Notifications
      </PageTitle>

      <HeaderButtons id={generateID.UUID(notificationName, `header_notification`, "buttons")}>
        {selected.length > 0 && (
          <DeleteSelectedButton
            pendingDelete={pendingDelete}
            setPendingDelete={setPendingDelete}
            selected={selected}
            client={client}
            dispatchFiltersAction={dispatchFiltersAction}
            setSelected={setSelected}
            id={generateID.UUID(notificationName, `header_notification`, "delete_selection")}
          />
        )}

        {!openFilters && <GrouBySeverityCheckbox  />}
        {openFilters && <FiltersRow />}
        {!openFilters && (
          <OwcButton
          id={generateID.UUID(notificationName, `header_notification`, "button_filter")}
            variant="secondary"
            data-testid="full-notification-open-filters-button"
            onClick={() => {
              setOpenFilters(true);
            }}
          >
            Open filters
          </OwcButton>
        )}
        {openFilters && (
          <OwcButton
          id={generateID.UUID(notificationName, `header_notification`, "button_clear")}
            variant="secondary"
            data-testid="full-notification-clear-all-button"
            onClick={() => {
              setOpenFilters(false);
              dispatchFiltersAction({
                type: "clearAll"
              });
            }}
          >
            Clear all
          </OwcButton>
        )}
      </HeaderButtons>
    </Headerbox>
  );
};

export default NotificationsHeader;
