export const saveImportMapToDOM = async ({ importsMap, callback }) => {
  let script = document.querySelector('script[type="systemjs-importmap"]');
  if (script) {
    script.textContent = null;
    script.textContent = JSON.stringify(importsMap);
  } else {
    script = document.createElement("script");
    script.type = "systemjs-importmap";
    script.async = true;
    script.textContent = JSON.stringify(importsMap);
    document.body.appendChild(script);
  }
  if (typeof callback === "function") {
    callback();
  }
};

export const toImportMap = ({
  data = [],
  getIdentity = ({ id }) => id,
  mapItem = (x) => x
}) =>
  data?.reduce((acc, cur) => {
    const id = getIdentity(cur);
    return id in acc ? acc : { ...acc, [id]: mapItem(cur) };
  }, {});

// eslint-disable-next-line no-undef
export const remoteImport = async (url) => SystemJS.import(url);
