import React, { useContext, useEffect } from "react";
import { Query } from "react-apollo";
import { connect } from "react-redux";
import get from "lodash/get";
import { NOTIFICATIONS_BY_USER_SEVERITY_AND_TIME } from "../../gql/landingapi/queries";
import { groupByKey, getSeverityCount } from "./helpers/selectors";
import { ShortViewOfNotifyContext } from "../landingPageNotifications/shortViewOfNotificaton/ShortViewOfNotifyContext/context";
import PopoverNotify from "../landingPageNotifications/shortViewOfNotificaton/PopoverNotify";
import { useMobile } from "../../utils/hooks/useMobile";
import { selectNotificationUserInfo as selectNotificationUserInfoActions } from "../landingPageUser/redux/actions";
import { mapNotifications } from "../landingPageNotifications/shortViewOfNotificaton/mapNotifications";
import { NOTIFICATIONS_LIMIT } from "../../constants";
import SystemNotificationsUpdate from "./systemNotification/SystemNotificationsUpdate";
import { IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { OwcBadge, OwcIcon } from "@one/react";

const NotificationCounter = ({ selectNotificationUserInfo, userId }) => {
  const { anchorElNotify, handleClickNotify } = useContext(
    ShortViewOfNotifyContext
  );
  const [isNotificationHighlighted, setisNotificationHighlighted] = useState();
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      location.pathname.indexOf("notification") === 1
        ? setisNotificationHighlighted(true)
        : setisNotificationHighlighted(false);
    });

    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMobile = useMobile();
  const showNotify = (event) => {
    if (!isMobile) {
      handleClickNotify(event);
    } else {
      selectNotificationUserInfo("all");
      history.replace("/notifications");
    }
  };
  return (
    <Query
      query={NOTIFICATIONS_BY_USER_SEVERITY_AND_TIME}
      variables={{
        user: userId,
        limit: NOTIFICATIONS_LIMIT,
        sortDirection: "DESC"
      }}
      fetchPolicy="cache-and-network"
    >
      {({ data }) => {
        const notifications = mapNotifications(
          get(data, "listDigitalLabLandingPageNotifications.items") ?? []
        );
        const severityCounterMap = groupByKey(notifications);

        const severityCounter = getSeverityCount(severityCounterMap);

        return (
          <>
            <SystemNotificationsUpdate
              notifications={notifications}
              severityCounter={severityCounter}
            />
            <IconButton
              style={{
                backgroundColor: `${
                  isNotificationHighlighted
                    ? "var(--one-color-interaction-focus-brand-3)"
                    : ""
                }`
              }}
              hasFocus={anchorElNotify !== null}
              onClick={(event) => showNotify(event)}
              aria-label="notification-counter"
              data-testid="notification-counter-button"
            >
              <OwcIcon name="notification" type="outlined"></OwcIcon>
              {severityCounter !== 0 && (
                <>
                  {severityCounterMap?.high > 0 ? (
                    <OwcBadge
                      type="alarm"
                      style={{
                        position: "absolute",
                        top: "-2px",
                        right: "2px"
                      }}
                    >
                      {severityCounter}
                    </OwcBadge>
                  ) : severityCounterMap?.medium > 0 ? (
                    <OwcBadge
                      type="warning"
                      style={{
                        position: "absolute",
                        top: "-2px",
                        right: "2px"
                      }}
                    >
                      {severityCounter}
                    </OwcBadge>
                  ) : (
                    <OwcBadge
                      type="info"
                      style={{
                        position: "absolute",
                        top: "-2px",
                        right: "2px"
                      }}
                    >
                      {severityCounter}
                    </OwcBadge>
                  )}
                </>
              )}
            </IconButton>
            <PopoverNotify notifications={notifications} />
          </>
        );
      }}
    </Query>
  );
};

export default connect(
  (state) => ({
    userId: state.user.user
  }),
  { selectNotificationUserInfo: selectNotificationUserInfoActions }
)(NotificationCounter);
