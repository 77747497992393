import * as yup from "yup";
import { isWidgetUnique } from "./is-widget-unique";

export const widgetNotUniqueError =
  "Name and version from metadata cannot be the same as one of the existing widgets' name and version";

const metadataSchema = yup
  .object({
    name: yup
      .string("'name' cannot be empty")
      .required("'name' cannot be empty"),
    description: yup
      .string("'description' cannot be empty")
      .required("'description' cannot be empty"),
    version: yup
      .string("'version' cannot be empty")
      .required("'version' cannot be empty"),
    fromAppName: yup.string().nullable(),
    fromAppSource: yup
      .string("'fromAppSource' cannot be empty")
      .required("'fromAppSource' cannot be empty"),
    supportedSizes: yup
      .array(
        yup.object({
          width: yup
            .string("'width' cannot be empty")
            .required("'width' cannot be empty"),
          height: yup
            .string("'height' cannot be empty")
            .required("'height' cannot be empty"),
          mockupURL: yup
            .string("'mockupURL' cannot be empty")
            .required("'mockupURL' cannot be empty")
        })
      )
      .required("'supportedSizes' cannot be empty"),
    widgetURL: yup
      .string("'widgetURL' cannot be empty")
      .required("'widgetURL' cannot be empty"),
    releaseDate: yup
      .date("'releaseDate' cannot be empty")
      .required("'releaseDate' cannot be empty")
  })
  .test({
    message:
      "Name and version from metadata cannot be the same as current widget's name and version",
    test: (value, context) => {
      if (!context.options.context.widget?.id) {
        return true;
      }

      return (
        value.name !== context.options.context.widget.name ||
        value.version !== context.options.context.widget.version
      );
    }
  })
  .test({
    message: widgetNotUniqueError,
    test: (value, context) => {
      if ((context.options.context.widgets?.length ?? 0) === 0) {
        return true;
      }

      return isWidgetUnique(context.options.context.widgets, value);
    }
  });

export const validateMetadata = (metadata, widget, widgets) => {
  try {
    metadataSchema.validateSync(metadata, {
      abortEarly: false,
      context: {
        widget,
        widgets
      }
    });
    return null;
  } catch (e) {
    console.log(e);
    return e.errors.map((er) => (
      <>
        {er}
        <br />
      </>
    ));
  }
};
