// import { get } from "lodash";
import { useEffect, useState } from "react";
import { withApollo } from "react-apollo";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
// import { NOTIFICATIONS_LIMIT } from "../../constants";
import {
  LIST_LANDING_PAGE_NOTIFICATIONS_SOURCES,
  // NOTIFICATIONS_BY_USER_AND_TIME,
  NOTIFICATIONS_BY_USER_SEVERITY_AND_TIME
} from "../../gql/landingapi/queries";
import { getAllData } from "../../utils/helpers/fetching";
import { useMobile } from "../../utils/hooks";
import { loadSources } from "../landingPageUser/redux/actions";
// import { FiltersContext } from "./fullViewOfNotification/FiltersContext/context";
import FullViewOfNotificationPage from "./fullViewOfNotification/FullViewOfNotificationPage";
import MobileViewOfNotificationsPage from "./mobileViewOfNotifications/MobileViewOfNotificationsPage";
// import MobileViewOfNotificationsPage from "./mobileViewOfNotifications/MobileViewOfNotificationsPage";
import { mapNotifications } from "./shortViewOfNotificaton/mapNotifications";

// const getFilter = ({ application, severity }) => {
//   if (!application && !severity) {
//     return undefined;
//   }

//   const filters = {};
//   if (application) {
//     filters.source = {
//       eq: application
//     };
//   }
//   if (severity) {
//     filters.severity = {
//       eq: severity
//     };
//   }

//   return filters;
// };

// const getGraphQlTime = ({ startDate, endDate }) => {
//   if (!startDate && !endDate) {
//     return undefined;
//   }

//   if (startDate && endDate) {
//     return {
//       between: [startDate.toISOString(), endDate.toISOString()]
//     };
//   }

//   if (startDate) {
//     return {
//       ge: startDate.toISOString()
//     };
//   }
//   return {
//     le: endDate.toISOString()
//   };
// };

// const getHasAnyFilter = ({ application, severity, startDate, endDate }) =>
//   application || severity || startDate || endDate;

// const shouldUserGroupedQuery = ({
//   theGroupBySeverity,
//   application,
//   severity,
//   startDate,
//   endDate
// }) => {
//   return (
//     theGroupBySeverity &&
//     !getHasAnyFilter({
//       application,
//       severity,
//       startDate,
//       endDate
//     })
//   );
// };

// const LandingPageNotificationsInner = ({
//   data,
//   fetchMore,
//   groupBySeverity,
//   isReverseOrder,
//   application,
//   severity,
//   startDate,
//   endDate,
//   id,
//   pendingDelete,
//   setPendingDelete,
//   userId,
//   nextToken,
//   nextData,
//   fetching,
//   dispatchFiltersAction
// }) => {
// const isMobile = useMobile();
//   const unsorted = mapNotifications(
//     get(data, "listDigitalLabLandingPageNotifications.items") ?? []
//   );
//   const notifications = [...unsorted].slice(0, NOTIFICATIONS_LIMIT);
//   const props = {
//     id,
//     notifications,
//     pendingDelete,
//     setPendingDelete,
//     unsorted
//   };
//   const token = get(data, "listDigitalLabLandingPageNotifications.nextToken");
//   useEffect(() => {
//     dispatchFiltersAction({
//       type: "nextToken",
//       payload: token
//     });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const fetchMoreCallback = async (
//     theGroupBySeverity,
//     isTheReverseOrder,
//     theApplication,
//     theSeverity,
//     theStartDate,
//     theEndDate
//   ) => {
//     if (fetching) {
//       return;
//     }
//     try {
//       dispatchFiltersAction({ type: "fetching", payload: true });
//       await fetchMore({
//         query: shouldUserGroupedQuery({
//           theGroupBySeverity,
//           application: theApplication,
//           severity: theSeverity,
//           startDate: theStartDate,
//           endDate: theEndDate
//         })
//           ? NOTIFICATIONS_BY_USER_SEVERITY_AND_TIME
//           : NOTIFICATIONS_BY_USER_AND_TIME,
//         variables: {
//           user: userId,
//           limit: NOTIFICATIONS_LIMIT,
//           sortDirection: isTheReverseOrder ? "ASC" : "DESC",
//           nextToken,
//           time: getGraphQlTime({
//             startDate: theStartDate,
//             endDate: theEndDate
//           }),
//           filter: getFilter({
//             application: theApplication,
//             severity: theSeverity
//           })
//         }
//       });
//     } catch (err) {
//       dispatchFiltersAction({ type: "fetching", payload: false });
//       console.error(err);
//     }
//   };
//   useEffect(() => {
//     fetchMoreCallback(
//       groupBySeverity,
//       isReverseOrder,
//       application,
//       severity,
//       startDate,
//       endDate
//     );
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [
//     groupBySeverity,
//     isReverseOrder,
//     application,
//     severity,
//     startDate,
//     endDate,
//     nextData
//   ]);

//   return isMobile ? (
//     <MobileViewOfNotificationsPage {...props} />
//   ) : (
//     <FullViewOfNotificationPage {...props} />
//   );
// };

const LandingPageNotifications = ({ id, client }) => {
  // const {
  //   groupBySeverity,
  //   orderBy,
  //   isReverseOrder,
  //   application,
  //   severity,
  //   startDate,
  //   endDate,
  //   nextToken,
  //   nextData,
  //   fetching,
  //   dispatchFiltersAction
  // } = useContext(FiltersContext);
  // const [pendingDelete, setPendingDelete] = useState(false);
  const isMobile = useMobile();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.user);
  useEffect(() => {
    const getApplications = async () => {
      const { items } = await getAllData({
        query: LIST_LANDING_PAGE_NOTIFICATIONS_SOURCES,
        fetchPolicy: "no-cache",
        client,
        dataPath: ["data", "listLandingPageNotificationsSources"],
        drillData: true,
        variables: {
          limit: 200
        }
      });

      dispatch(loadSources(items));
    };

    const getNotifications = async () => {
      setLoading(true);
      const { items } = await getAllData({
        query: NOTIFICATIONS_BY_USER_SEVERITY_AND_TIME,
        fetchPolicy: "no-cache",
        client,
        dataPath: ["data", "listDigitalLabLandingPageNotifications"],
        variables: {
          user: userId,
          limit: 1000,
          sortDirection: "DESC",
          nextToken: null
        }
      });
      setLoading(false);
      setData(mapNotifications(items ?? []));
    };

    getApplications();
    getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // return (
  //   <>
  //     <FullViewOfNotificationPage client={client} notifications={data} />
  //   </>
  // );
  return isMobile ? (
    <MobileViewOfNotificationsPage
      id={id}
      notifications={data && data?.length > 0 ? data : []}
    />
  ) : (
    <FullViewOfNotificationPage
      client={client}
      notifications={data && data?.length > 0 ? data : []}
      loading={loading}
    />
  );
  // return (
  //   <LandingPageNotificationsInner
  //     data={data}
  //     fetchMore={async (query) => {
  //       const { data: queryData } = await client.query({
  //         ...query,
  //         fetchPolicy: "no-cache"
  //       });
  //       dispatchFiltersAction({
  //         type: "nextToken",
  //         payload: queryData?.listDigitalLabLandingPageNotifications?.nextToken
  //       });

  //       setData(queryData);
  //     }}
  //     groupBySeverity={groupBySeverity}
  //     orderBy={orderBy}
  //     isReverseOrder={isReverseOrder}
  //     id={id}
  //     pendingDelete={pendingDelete}
  //     setPendingDelete={setPendingDelete}
  //     userId={userId}
  //     nextToken={nextToken}
  //     nextData={nextData}
  //     dispatchFiltersAction={dispatchFiltersAction}
  //     fetching={fetching}
  //     application={application}
  //     severity={severity}
  //     startDate={startDate}
  //     endDate={endDate}
  //   />
  // );
};

export default withApollo(LandingPageNotifications);
