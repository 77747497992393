import styled from "styled-components";
import { OwcIconButton } from "@one/react";

const HeaderTop = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 13px;
`;
const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-left: 13px;
  line-height: 24px;
  color: var(--one-color-gray-800);
`;

const WidgetsBarTopHeader = ({ onClick }) => {
  return (
    <HeaderTop data-testid="widgets-bar-header-top">
      <OwcIconButton
        flat
        type="outlined"
        icon="clear"
        data-testid="widgets-bar-header-close-button"
        aria-label="close"
        onClick={onClick}
      />
      <HeaderTitle data-testid="widgets-bar-header-title">
        All widgets
      </HeaderTitle>
    </HeaderTop>
  );
};
export default WidgetsBarTopHeader;
