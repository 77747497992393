const ExperimentManagementIcon = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      color: "var(--one-color-interaction-disabled-neutral-3)"
    }}
  >
    <path
      d="M6.66748 5.32004V7.29004M8.41373 2.88879H20.71C20.9362 2.88863 21.1602 2.93302 21.3692 3.01943C21.5783 3.10585 21.7683 3.23259 21.9283 3.39242C22.0884 3.55225 22.2154 3.74205 22.3021 3.95097C22.3889 4.15989 22.4336 4.38384 22.4337 4.61004V7.29004H6.68998V4.61004C6.69014 4.38384 6.73486 4.15989 6.82158 3.95097C6.90829 3.74205 7.03531 3.55225 7.19537 3.39242C7.35544 3.23259 7.54542 3.10585 7.75446 3.01943C7.96351 2.93302 8.18753 2.88863 8.41373 2.88879Z"
      stroke="#544F4F"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.4449 9.06254V7.44379C22.4449 7.35879 22.3949 7.29004 22.3324 7.29004H1.66743C1.60493 7.29004 1.55493 7.35879 1.55493 7.44379V16.9575C1.55493 17.0425 1.60493 17.1113 1.66743 17.1113H8.37493"
      stroke="#544F4F"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <g clip-path="url(#clip0_869_2611)">
      <path
        d="M11 12L12 12M11 15H12"
        stroke="#544F4F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 15L12 15M11 18H12"
        stroke="#544F4F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 12L20 12M15 15H20"
        stroke="#544F4F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 15L20 15M15 18H20"
        stroke="#544F4F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_869_2611">
        <rect
          width="13.5"
          height="13.5"
          fill="white"
          transform="translate(10 9.25)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default ExperimentManagementIcon;
