import {
  CREATE_DIGITAL_LAB_LANDING_PAGE_APPLICATION,
  UPDATE_DIGITAL_LAB_LANDING_PAGE_APPLICATION
} from "../../../../gql/landingapi/mutations";
import { v4 } from "uuid";
import { pick, get } from "lodash";
import Snackbar from "../../../snackbar/Snackbar";
import { uploadIconFlow } from "../../../uploadIcon/helpers";
import { Auth } from "aws-amplify";

export async function getAccessGroups() {
  return (
    get(
      (await Auth.currentSession()).getAccessToken(),
      "payload.cognito:groups"
    ) || []
  );
}

export async function uploadIcon(client, app, file) {
  await uploadIconFlow({
    client,
    file,
    applicationId: app.id,
    onSuccess: (iconPath) => {
      Snackbar({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: `File was successfully uploaded!`
      });
      app.iconPath = iconPath;
    },
    onError: (error) =>
      Snackbar({
        type: "warning",
        icon: "caution",
        appName: "",
        text: error
      })
  });
}

export function loadUserInfoForSavingApp({
  mutation,
  loadUserInfoFn,
  data,
  app
}) {
  if (mutation === CREATE_DIGITAL_LAB_LANDING_PAGE_APPLICATION) {
    loadUserInfoFn({ allAdminApps: [...data, app] });
  } else {
    loadUserInfoFn({
      allAdminApps: data.map((theApp) => {
        if (theApp.id === app.id) {
          return app;
        }
        return theApp;
      })
    });
  }
}

function checkIsFileToUpload(file, mutation) {
  return file && mutation === UPDATE_DIGITAL_LAB_LANDING_PAGE_APPLICATION;
}

export function saveApplicationFactory({
  setApplication,
  client,
  loadUserInfo,
  data,
  refetch
}) {
  return async (applicationForm, file) => {
    setApplication(null);
    let mutation = UPDATE_DIGITAL_LAB_LANDING_PAGE_APPLICATION;
    if (applicationForm.id === null) {
      applicationForm.id = v4();
      mutation = CREATE_DIGITAL_LAB_LANDING_PAGE_APPLICATION;
    }
    const {
      data: { application: app }
    } = await client.mutate({
      mutation,
      variables: {
        application: pick(
          {
            ...applicationForm,
            entryPoints: applicationForm.entryPoints.map((entryPoint) =>
              pick(entryPoint, ["id", "name", "url"])
            )
          },
          [
            "id",
            "name",
            "description",
            "icon",
            "type",
            "applicationGroupIDs",
            "entryPoints",
            "allowedRoles"
          ]
        )
      }
    });
    const access_groups = await getAccessGroups();
    if (checkIsFileToUpload(file, mutation)) {
      await uploadIcon(client, app, file);
    }
    if (
      access_groups.some((accessGroup) =>
        app.allowedRoles.includes(accessGroup)
      )
    ) {
      loadUserInfoForSavingApp({
        loadUserInfoFn: loadUserInfo,
        mutation,
        data,
        app
      });
    }
    Snackbar({
      type: "success",
      icon: "circle_confirm",
      appName: app.name,
      text: `was successfully ${
        mutation === CREATE_DIGITAL_LAB_LANDING_PAGE_APPLICATION
          ? "added"
          : "updated"
      }!`
    });
    refetch();
  };
}
