export function searchAppsFactory(setSearchText, setFilteredApp, applications) {
  return (searchText) => {
    setSearchText(searchText);
    setFilteredApp(
      applications.filter((app) =>
        app.name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };
}
