const ToolsIcon = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      cursor: "unset",
      color: "var(--one-color-interaction-disabled-neutral-3)"
    }}
  >
    <g clip-path="url(#clip0_869_2629)">
      <path
        d="M8.16621 2.82251H6.39371C6.3457 2.82248 6.29771 2.82499 6.24996 2.83001C5.90537 2.86496 5.58597 3.02639 5.35348 3.28313C5.12099 3.53986 4.99192 3.87366 4.99121 4.22001V4.22001V20.9813C4.99071 21.3283 5.11927 21.6632 5.3519 21.9208C5.58453 22.1784 5.90462 22.3403 6.24996 22.375C6.29771 22.38 6.3457 22.3825 6.39371 22.3825H17.6062C17.79 22.383 17.9721 22.3473 18.1421 22.2774C18.312 22.2074 18.4666 22.1047 18.5968 21.975C18.7271 21.8454 18.8305 21.6913 18.9012 21.5216C18.9718 21.352 19.0084 21.1701 19.0087 20.9863V4.22001C19.0084 4.03616 18.9718 3.85418 18.9012 3.68445C18.8305 3.51471 18.7271 3.36056 18.5969 3.23079C18.4667 3.10102 18.3121 2.99818 18.1422 2.92812C17.9722 2.85807 17.7901 2.82218 17.6062 2.82251H15.8337"
        stroke="#544F4F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0163 1.625H8.90627C8.72018 1.64307 8.54754 1.73 8.42221 1.86874C8.29688 2.00748 8.22789 2.18803 8.22877 2.375V2.375V3.27375C8.22789 3.46072 8.29688 3.64127 8.42221 3.78001C8.54754 3.91875 8.72018 4.00568 8.90627 4.02375H15.0163C15.2152 4.02375 15.4059 3.94473 15.5466 3.80408C15.6872 3.66343 15.7663 3.47266 15.7663 3.27375V2.375C15.7663 2.17609 15.6872 1.98532 15.5466 1.84467C15.4059 1.70402 15.2152 1.625 15.0163 1.625Z"
        stroke="#544F4F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.84863 6.98749H17.1511M6.84863 9.92749H17.1511M6.84863 12.8687H17.1511M6.84863 15.8087H11.9999"
        stroke="#544F4F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_869_2629">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default ToolsIcon;
