import React from "react";
import { connect } from "react-redux";
import { selectAllApplicationSets } from "../selectors";
import CustomGroupedAppsCard from "../features/applications/CustomGroupedAppsCard";
import CustomGroupedAppsGroup from "../features/applications/CustomGroupedAppsGroup";

const AllCustomGroups = ({ customGroups ,groupedType}) => {
  return (
    
      <CustomGroupedAppsGroup
        name="Grouped tools"
        Component={CustomGroupedAppsCard}
        customGroups={customGroups}
        groupedType={groupedType}
      />
    
  );
};

const mapStateToProps = (store) => ({
  customGroups: selectAllApplicationSets(
    store.user.allApps,
    store.user.applicationSets
  )
});

export default connect(mapStateToProps)(AllCustomGroups);
