import React, { useContext } from "react";
import ConfirmDialog from "../../components/shared/ConfirmDialog";
import styled from "styled-components";
import { DeleteContext } from "./contexts";
import { handleDelete } from "./helpers";
import { withApollo } from "react-apollo";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../constants";

const {appAndCategories} = Module.PAGE.ADMIN;

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0 16px 0;
  & .question {
    min-width: 360px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    margin: 0;
    & > b {
      font-weight: 700;
    }
  }
`;

const DialogDelete = ({ client }) => {
  const { isOpen, item, close } = useContext(DeleteContext);
  return (
    <ConfirmDialog
      open={isOpen}
      approveText="Delete category"
      testid={"deleteDialog"}
      approveColor="error"
      approveVariant="contained"
      cancelText="Cancel"
      cancelVariant="outlined"
      cancelColor="primary"
      close={close}
      onApprove={() => {
        handleDelete({
          client,
          item
        });
      }}
      title="Delete category"
      content={`Are you sure you want to remove "${item?.name}"?`}
    >
      <StyledContent 
       id={generateID.UUID(appAndCategories, `dialogDelete`, "styleContent")}
      >
        <p className="question" 
          id={generateID.UUID(appAndCategories, `dialogDelete`, "p_question")}
        >
          Are you sure you want to remove <b>"{item?.name}"</b>?
        </p>
      </StyledContent>
    </ConfirmDialog>
  );
};

export default withApollo(DialogDelete);
